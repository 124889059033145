import { navigation } from '../../styles/colors';
import { baseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		display: 'flex',
	},
	input: {
		...baseStyles.textField,
		'-webkit-appearance': 'none',
		'::placeholder': {
			color: navigation,
		},
		':focus': {
			outline: 'none',
		},
		flexGrow: 1,
		resize: 'none',
	},
});
