import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useHwcResourceSelectorMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: () => void;
	onError?: (err: Api.IOperationResultNoValue) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({
			resourceSelectorId,
			card,
			selectorRequest,
		}: {
			resourceSelectorId: Api.ResourceSelectorId;
			card: Partial<Api.IHandwrittenCard>;
			selectorRequest: Api.IResourceSelectorContactsRequest;
		}) =>
			userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `handwrittencard/resourceSelector/${encodeURIComponent(resourceSelectorId)}`,
				}),
				'POST',
				{
					card,
					selectorRequest,
				}
			),
		onError,
		onSuccess,
	});
}
