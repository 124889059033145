import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export function useDeleteNoteMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ id }: { id: string }) => {
			return userSession.webServiceHelper.callAsync<void>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `note/${encodeURIComponent(id)}`,
				}),
				'DELETE'
			);
		},
		onError,
		onSuccess,
	});
}
