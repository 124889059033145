import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useImageAttachmentMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (result: Api.IFileAttachment[]) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({
			files,
			resizeForSocialMedia = false,
			impersonationContext,
		}: { files: File[]; resizeForSocialMedia?: boolean; impersonationContext?: Api.IImpersonationContext }) => {
			const formData = new FormData();
			files.forEach(file => formData.append('files', file));
			return new Promise<Api.IFileAttachment[]>((resolve, reject) => {
				const onFinish = (opResult: Api.IBulkOperationResult<Api.IFileAttachment>) => {
					if (opResult.success) {
						// @ts-ignore
						return resolve(opResult.succeeded);
					}
					reject(opResult);
				};
				userSession.webServiceHelper.callWebServiceWithBulkOperationResult<Api.IFileAttachment>(
					Api.ImpersonationBroker.composeApiUrl({
						queryParams: { resizeForSocialMedia },
						urlPath: `attachment/image`,
						impersonationContext,
					}),
					'POST',
					formData,
					onFinish,
					onFinish
				);
			});
		},
		onError,
		onSuccess,
	});
}
