import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export function useCheckDomainAvailability({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: boolean) => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		// @ts-ignore
		mutationFn: ({ domainName }: { domainName: string }) => {
			return userSession.webServiceHelper.callAsync<boolean>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `domainName/checkAvailability`,
					queryParams: {
						domainName,
					},
				}),
				'POST',
				null
			);
		},
		onError,
		onSuccess,
	});
}
