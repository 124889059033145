import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_API_KEY = '/donation';

export const useDonationFilterQuery = ({
	pageSize = 25,
	filterRequest,
	enabled,
	refetchOnWindowFocus = false,
	expand = 'contact',
}: {
	pageSize?: number;
	filterRequest: Api.IDonationFilterRequest;
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
	expand?: string;
}) => {
	const userSession = useUserSession();
	return useInfiniteQuery({
		enabled,
		queryFn: async ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IDonation>>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { pageSize, pageToken: pageParam, expand },
					urlPath: `donation/filter`,
				}),
				'POST',
				filterRequest
			);
		},
		getNextPageParam: lastPage => lastPage.pageToken,
		queryKey: [BASE_API_KEY, filterRequest, expand, pageSize],
		refetchOnWindowFocus,
	});
};

export const invalidateAllDonationFilters = () => {
	reactQueryClient.invalidateQueries([BASE_API_KEY]);
};

export const invalidateDonationFilter = ({
	filterRequest,
	expand = 'contact',
	pageSize = 25,
}: {
	filterRequest: Api.IDonationFilterRequest;
	expand?: string;
	pageSize?: number;
}) => {
	reactQueryClient.invalidateQueries([BASE_API_KEY, filterRequest, expand, pageSize]);
};
