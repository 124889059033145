import { useInfiniteQuery } from '@tanstack/react-query';
import { stringify as toQueryString } from 'query-string';
import { IPixabayImageSearchOptions, IPixabayImageSearchResult } from '../../models/Pixabay';
import { DefaultPixabayApiKey } from './constants';

export const PIXABAY_IMAGE_SEARCH_QUERY_KEY = 'pixabayImageSearchQuery';

export function usePixabayImageSearchQuery({
	searchTerm,
	options = { per_page: 25 },
}: { searchTerm: string; options?: IPixabayImageSearchOptions }) {
	const pageSize = options?.per_page || 25;
	return useInfiniteQuery<IPixabayImageSearchResult>({
		enabled: Boolean(searchTerm),
		getNextPageParam: (lastPage: IPixabayImageSearchResult, allPages) => {
			return lastPage.total > allPages.length * pageSize ? allPages.length + 1 : null;
		},
		queryFn: async ({ pageParam }) => {
			const query = toQueryString({
				safesearch: true,
				key: DefaultPixabayApiKey,
				q: searchTerm || '',
				type: 'photo',
				image_type: 'photo',
				...(options || {}),
				per_page: pageSize,
				page: pageParam,
			});
			const result = await fetch(`https://pixabay.com/api/?${query}`, { method: 'GET' });
			return result.json();
		},
		queryKey: [PIXABAY_IMAGE_SEARCH_QUERY_KEY, searchTerm, pageSize, options],
	});
}
