import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const QUERY_BASE_KEY = 'handwrittenCardAllCategoryTemplates';

export const useHandwrittenCardAllCategoryTemplates = ({
	enabled = true,
	industry,
}: {
	enabled?: boolean;
	industry: Api.Industry;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () =>
			userSession.webServiceHelper.callAsync<Record<string, Api.IHandwrittenCardTemplate[]>>(
				`template/${industry}/browse/cards`,
				'GET'
			),
		queryKey: [
			QUERY_BASE_KEY,
			{
				industry,
			},
		],
	});
};

export const invalidateHandwrittenCardAllCategoryTemplates = () => reactQueryClient.invalidateQueries([QUERY_BASE_KEY]);
