import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const PUBLIC_AUTH_KEYS_QUERY_KEY = 'publicAuthKeys';

export function useGetPublicAuthKeys({
	enabled,
	refetchOnWindowFocus = false,
	impersonationContext,
	onError,
}: {
	enabled: boolean;
	refetchOnWindowFocus?: boolean;
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		refetchOnWindowFocus,
		onError,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IAPIKeyReference[]>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `public/auth/key`,
				}),
				'GET'
			);
		},
		queryKey: [PUBLIC_AUTH_KEYS_QUERY_KEY, impersonationContext],
	});
}

export const invalidateGetPublicAuthKeys = ({
	impersonationContext,
}: {
	impersonationContext?: Api.IImpersonationContext;
}) => reactQueryClient.invalidateQueries([PUBLIC_AUTH_KEYS_QUERY_KEY, impersonationContext]);
