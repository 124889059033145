import { success } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	ringColor?: string;
	size?: number;
}

export const SuccessCheckIcon: React.FC<IProps> = props => {
	const { className, fillColor, size, ringColor } = props;
	const finalSize = size ?? 20;
	return (
		<SvgIcon
			className={`success-check-icon ${className || ''}`}
			height={finalSize}
			width={finalSize}
			viewBox={`0 0 ${20} ${20}`}
		>
			<g fill='none' fillRule='evenodd'>
				<circle cx='10' cy='10' r='10' fill={fillColor || success} />
				<circle cx='10' cy='10' r='9' stroke={ringColor || '#DBF2C3'} strokeWidth='2' />
				<polygon fill='#FFF' points='9.16 14.927 5.116 10.884 6.884 9.116 8.841 11.073 12.983 5.274 15.017 6.726' />
			</g>
		</SvgIcon>
	);
};
