import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useUserStatsQuery({
	enabled = true,
	impersonationContext,
}: { enabled?: boolean; impersonationContext?: Api.IImpersonationContext } = {}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () =>
			userSession.webServiceHelper.callAsync<Api.IUserStats>(
				Api.ImpersonationBroker.composeApiUrl({ urlPath: `user/stats`, impersonationContext }),
				'GET'
			),
		queryKey: [`user/stats`, impersonationContext],
	});
}
