import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = 'contact/classifiedPolicies/filter';

export function useAllClassifiedContactPolicies({
	contactsRequest,
	enabled,
	refetchOnWindowFocus,
	impersonationContext,
}: {
	contactsRequest: Api.IContactsFilterRequest | Api.IResourceSelectorContactsRequest | undefined;
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
	impersonationContext?: Api.IImpersonationContext;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IClassifiedPolicies>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: 'contact/classifiedPolicies/filter',
					impersonationContext,
				}),
				'POST',
				contactsRequest
			);
		},
		queryKey: [BASE_QUERY_KEY, contactsRequest],
		refetchOnWindowFocus,
	});
}
