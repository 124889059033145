import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useHwcBulkCreateMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: () => void;
	onError?: (err: Api.IOperationResultNoValue) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ card, filter }: { card: Partial<Api.IHandwrittenCard>; filter: Api.IBulkContactsRequest }) =>
			userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `handwrittencard/bulkCreate`,
				}),
				'POST',
				{
					card,
					filter,
				}
			),
		onError,
		onSuccess,
	});
}
