import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

interface Params {
	post: Api.IBlogPost;
}
export function useBlogUpdateMutation({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (val: Api.IBlogPost) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ post }: Params) => {
			const value = await userSession.webServiceHelper.callAsync<Api.IBlogPost>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `blog`,
					queryParams: {
						forUserId: post.sendFromUserId,
					},
				}),
				'PUT',
				post
			);
			return value;
		},
		onError,
		onSuccess,
	});
}
