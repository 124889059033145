import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { ImpersonationBroker } from '../../viewmodels/AppViewModels';
import { reactQueryClient } from '../../web/ReactQueryProvider';

export const SOCIAL_MY_DRAFTS_QUERY_KEY = 'social/post/drafts';

export const useSocialPostDraftsInfiniteQuery = ({
	pageSize = 25,
	enabled = true,
	userId,
}: {
	enabled?: boolean;
	pageSize?: number;
	userId: string;
}) => {
	const userSession = useUserSession();
	return useInfiniteQuery({
		enabled,
		queryFn: ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.ISocialMediaPost>>(
				ImpersonationBroker.composeApiUrl({
					queryParams: {
						pageSize,
						userId,
						pageToken: pageParam,
					},
					urlPath: `social/post/drafts`,
				}),
				'GET'
			);
		},
		queryKey: [SOCIAL_MY_DRAFTS_QUERY_KEY, userId, pageSize],
		getNextPageParam: lastPage => lastPage.pageToken,
	});
};

export const invalidateSocialDrafts = () => {
	reactQueryClient.invalidateQueries([SOCIAL_MY_DRAFTS_QUERY_KEY]);
};
