import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import * as AdminModels from '../../../models/AdminModels';
import { getCategoryPathByCampaignType as getAdminCategoryPathByCampaignType } from '../../../models/UiUtils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { ITemplateCard } from '../../../viewmodels/AppViewModels';

interface IParams {
	template: Api.IBlogTemplate;
	statuses: Api.ITemplateStatus[];
	id: string;
}
export function useAdminBlogTemplateUpdate({
	campaignType,
	impersonationContext,
	onError,
	onSuccess,
}: {
	campaignType: AdminModels.CampaignType;
	impersonationContext?: Api.IImpersonationContext;
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: ({
		template,
		industries,
	}: {
		template: ITemplateCard;
		industries: { [key in Api.Industry]: Api.TemplateStatus };
	}) => void;
}) {
	const userSession = useUserSession();

	const catType = getAdminCategoryPathByCampaignType(campaignType);
	return useMutation({
		mutationFn: async ({ template, statuses, id }: IParams) => {
			const value = await userSession.webServiceHelper.callAsync<{
				template: ITemplateCard;
				industries: { [key in Api.Industry]: Api.TemplateStatus };
			}>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `admin/template/industry/${catType}/templates/${id}`,
				}),
				'PATCH',
				{ template, statuses, templateId: id }
			);
			return value;
		},
		onError,
		onSuccess,
	});
}
