import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';

export const SYSTEM_TEMPLATE_TRANSLATIONS_QUERY_KEY = 'systemTemplateTranslations';

export const useSystemTemplateTranslations = ({
	enabled = true,
	id,
	onError,
}: {
	enabled?: boolean;
	id: string;
	onError?: (error: Api.IOperationResultNoValue) => void;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		onError,
		queryFn: async () => {
			// Must use /template route otherwise calls for customers will fail.
			const value = await userSession.webServiceHelper.callAsync<Api.ITemplate[]>(`template/${id}/translations`, 'GET');
			return value;
		},
		queryKey: [SYSTEM_TEMPLATE_TRANSLATIONS_QUERY_KEY, id],
	});
};
