import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const usePutGoogleBusinessSyncPreferences = ({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext: Api.IImpersonationContext;
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: (connection: Api.IGoogleBusinessConnection) => {
			return userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: 'googleBusiness/syncPreference',
				}),
				'PUT',
				connection
			);
		},
		onError,
		onSuccess,
	});
};
