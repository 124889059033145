import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'insurancePolicy/';

export function useInsurancePolicyFilter({
	filterRequest,
	pageSize = 25,
	sort = 'asc',
	enabled = true,
	refetchOnWindowFocus = false,
	expand = 'contact,owner',
}: {
	filterRequest: Api.IInsurancePolicyFilterRequest;
	pageSize?: number;
	sort?: 'asc' | 'desc';
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
	expand?: string;
}) {
	const userSession = useUserSession();

	return useInfiniteQuery<Api.IPagedCollection<Api.IInsurancePolicy>>({
		enabled,
		getNextPageParam: lastPage => lastPage.pageToken,
		queryFn: async ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IInsurancePolicy>>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { pageSize, pageToken: pageParam, sort, expand },
					urlPath: 'insurancePolicy/filter',
				}),
				'POST',
				filterRequest
			);
		},
		refetchOnWindowFocus,
		queryKey: [BASE_QUERY_KEY, filterRequest, pageSize, sort, expand],
	});
}

export const invalidateInsurancePolicyFilter = ({
	filterRequest,
	sort = 'asc',
	expand = 'contact,owner',
	pageSize = 25,
}: {
	filterRequest: Api.IInsurancePolicyFilterRequest;
	sort?: 'asc' | 'desc';
	expand?: string;
	pageSize?: number;
}) => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY, filterRequest, pageSize, sort, expand]);
};

export const invalidateAllInsurancePolicyFilters = () => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
};
