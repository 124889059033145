import * as Api from '@ViewModels';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const CONTACT_QUERY_KEY = 'contact';

const getQueryFn =
	({
		contactId,
		allowArchived = false,
		expandHouseholdMembers = false,
		userSession,
	}: {
		contactId: string;
		allowArchived?: boolean;
		expandHouseholdMembers?: boolean;
		userSession: Api.UserSessionContext;
	}) =>
	async () => {
		return userSession.webServiceHelper.callAsync<Api.IContact>(
			Api.ImpersonationBroker.composeApiUrl({
				queryParams: {
					expand: expandHouseholdMembers ? 'householdMembers' : undefined,
					includeArchived: allowArchived,
				},
				urlPath: `contact/${contactId}`,
			}),
			'GET'
		);
	};

export const useContact = ({
	contactId,
	allowArchived = false,
	expandHouseholdMembers = false,
	refetchOnWindowFocus,
	enabled = true,
}: {
	contactId: string;
	allowArchived?: boolean;
	expandHouseholdMembers?: boolean;
	refetchOnWindowFocus?: boolean;
	enabled?: boolean;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: getQueryFn({ allowArchived, contactId, expandHouseholdMembers, userSession }),
		queryKey: [CONTACT_QUERY_KEY, { allowArchived, contactId, expandHouseholdMembers }],
		refetchOnWindowFocus,
	});
};

export const useContactsQuery = ({
	contactIds,
	allowArchived = false,
	expandHouseholdMembers = false,
}: {
	contactIds: string[];
	allowArchived?: boolean;
	expandHouseholdMembers?: boolean;
}) => {
	const userSession = useUserSession();
	return useQueries({
		queries: contactIds.map(contactId => {
			return {
				queryFn: getQueryFn({ allowArchived, contactId, expandHouseholdMembers, userSession }),
				queryKey: [CONTACT_QUERY_KEY, { allowArchived, contactId, expandHouseholdMembers }],
			};
		}),
	});
};
