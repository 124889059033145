import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useUpdateEventSurveyMutation = ({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data?: Api.IEventRegistrationSurvey) => void;
}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ eventRegistration, id }: { eventRegistration: Api.IEventRegistrationSurvey; id: string }) => {
			return userSession.webServiceHelper.callAsync<Api.IEventRegistrationSurvey>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `survey/eventregistration/${id}`,
				}),
				'PUT',
				eventRegistration
			);
		},
		onError,
		onSuccess,
	});
};
