import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

interface IVariables {
	campaignId: string;
	schedule: Api.IScheduledSend;
	timeZone?: string;
	impersonationContext?: Api.IImpersonationContext;
}

export function useEmailUpdateSendScheduleMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (campaign: Api.ICampaign, variables: IVariables) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		// @ts-ignore
		mutationFn: ({ campaignId, timeZone, schedule, impersonationContext }: IVariables) =>
			userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `email/${encodeURIComponent(campaignId)}/scheduledsend`,
					queryParams: {
						timeZone,
					},
					impersonationContext,
				}),
				'PUT',
				schedule
			),
		onError,
		onSuccess,
	});
}
