import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

interface Params {
	templateId: string;
	stepId: string;
	attachmentFiles: File[];
	impersonationContext?: Api.IImpersonationContext;
}

export function useAutomationTemplateStepEmailAttachmentCreateMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: (data: Api.IEmailAutomationStep, variables: Params) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ templateId, stepId, attachmentFiles, impersonationContext }: Params) => {
			const formData = new FormData();
			for (const file of attachmentFiles) {
				formData.append('files', file);
			}
			return userSession.webServiceHelper.callAsync<Api.IEmailAutomationStep>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `AutomationTemplate/${encodeURIComponent(templateId)}/Step/Email/${encodeURIComponent(
						stepId
					)}/Attachment`,
					impersonationContext,
				}),
				'POST',
				formData
			);
		},
		onError,
		onSuccess,
	});
}
