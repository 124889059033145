import { useMutation } from '@tanstack/react-query';
import * as Api from '@ViewModels';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useDeleteSocialPostMutation({
	onSuccess,
	onError,
}: { onSuccess?: () => void; onError?: (error: Api.IOperationResultNoValue) => void } = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: (params: { id: string }) => {
			return userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `social/post/${encodeURIComponent(params.id)}`,
				}),
				'DELETE'
			);
		},
		onError,
		onSuccess,
	});
}
