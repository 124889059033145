import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { ImpersonationBroker } from '../../viewmodels/AppViewModels';

export function useTemplateScopeMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async (params: { scope: Api.TemplateScope; id: string }) => {
			return userSession.webServiceHelper.callAsync(
				ImpersonationBroker.composeApiUrl({
					queryParams: {
						scope: params.scope === Api.TemplateScope.Account ? 1 : 0,
					},
					urlPath: `template/${encodeURIComponent(params.id)}/scope`,
				}),
				'PATCH'
			);
		},
		onError,
		onSuccess,
	});
}
