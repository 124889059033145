import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { stringify as toQueryStringParams } from 'query-string';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const dashboardFeedKey = ({ pageSize, typeOf }: { pageSize: number; typeOf: string }) => [
	'dashboardFeed',
	pageSize,
	typeOf,
];

export const useDashboardFeed = ({
	enabled = true,
	pageSize,
	typeOf,
	handleError,
}: {
	enabled?: boolean;
	pageSize: number;
	typeOf: string;
	handleError?: (err: Api.IOperationResultNoValue) => void;
}) => {
	const userSession = useUserSession();
	const filters = toQueryStringParams({ pageSize, typeOf });
	return useQuery({
		enabled,
		onError: handleError,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IDashboardFeedItem>>(
				`dashboard/feed?${filters}`,
				'GET'
			);
		},
		queryKey: dashboardFeedKey({ pageSize, typeOf }),
	});
};

export const invalidateDashboardFeed = ({ pageSize, typeOf }: { pageSize: number; typeOf: string }) =>
	reactQueryClient.invalidateQueries(dashboardFeedKey({ pageSize, typeOf }));

export const getFeedItemViewModel = ({
	feedItem,
	userSession,
}: {
	feedItem: Api.IDashboardFeedItem;
	userSession: Api.UserSessionContext;
}) => {
	switch (feedItem.model._type) {
		case 'Meeting': {
			return new Api.RecentMeetingViewModel(userSession, feedItem.model as Api.IMeeting);
		}
		case 'ActionItem': {
			return new Api.ActionItemViewModel(userSession, feedItem.model as Api.IActionItem);
		}
		case 'Note': {
			return new Api.NoteViewModel(userSession, feedItem.model as Api.INote);
		}
		case 'ClassifyContact': {
			return new Api.ClassifyContactViewModel(userSession, feedItem.model as Api.IClassifyContact);
		}
		case 'EmailActivity': {
			return new Api.EmailActivityViewModel(userSession, feedItem.model as Api.IEmailActivity);
		}
		case 'UpcomingKeyFact': {
			return new Api.UpcomingKeyFactViewModel(userSession, feedItem.model as Api.IUpcomingKeyFact);
		}
		case 'Campaign': {
			return new Api.CampaignViewModel(userSession, feedItem.model as Api.ICampaign);
		}
		default: {
			return null;
		}
	}
};
