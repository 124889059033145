import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';

const QUERY_BASE_KEY = 'handwrittenCardCategoryTemplates';

export const useHandwrittenCardCategoryTemplates = ({
	enabled = true,
	industry,
	category,
}: {
	enabled?: boolean;
	industry: Api.Industry;
	category: string;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () =>
			userSession.webServiceHelper.callAsync<Api.IHandwrittenCardTemplate[]>(
				`template/${industry}/${category}/cards`,
				'GET'
			),
		queryKey: [
			QUERY_BASE_KEY,
			{
				category,
				industry,
			},
		],
	});
};
