import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { ImpersonationBroker, TagViewModel } from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export const useCreateTag = ({
	impersonationContext,
	onSuccess,
	onError,
}: {
	impersonationContext: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.TagViewModel) => void;
}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ accountTag }: { accountTag: Api.IAccountTag }) => {
			const result = await userSession.webServiceHelper.callAsync<Api.IAccountTag>(
				ImpersonationBroker.composeApiUrl({ impersonationContext, urlPath: 'tag' }),
				'POST',
				accountTag
			);
			return new TagViewModel(userSession, result);
		},
		onError,
		onSuccess,
	});
};
