import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';

export const ATTACHMENT_VIDEO_QUERY_KEY = 'attachmentVideoQuery';

export function useAttachmentQuery({ id, enabled }: { id: string; enabled: boolean }) {
	const userSession = useUserSession();

	return useQuery({
		enabled,
		queryFn: async () => {
			return userSession.webServiceHelper.callAsync<Api.IFileAttachment>(
				Api.ImpersonationBroker.composeApiUrl({ urlPath: `attachment/${id}` }),
				'GET'
			);
		},
		queryKey: [ATTACHMENT_VIDEO_QUERY_KEY, id],
	});
}
