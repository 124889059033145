import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export function useHwcOrderCancellationMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: (id: string) => {
			return userSession.webServiceHelper.callAsync(`handwrittencard/${id}/cancel`, 'POST');
		},
		onError,
		onSuccess,
	});
}
