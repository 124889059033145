import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useExportCampaignMutation = ({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onSuccess?: (data: Api.ISystemJob) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
} = {}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ ids = [], groupIds = [] }: { ids?: string[]; groupIds?: string[] }) => {
			const systemJob = await userSession.webServiceHelper.callAsync<Api.ISystemJob>(
				Api.ImpersonationBroker.composeApiUrl({ impersonationContext, urlPath: 'email/export' }),
				'POST',
				{ groupIds, ids }
			);
			return systemJob as Api.ISystemJob;
		},
		onError,
		onSuccess,
	});
};
