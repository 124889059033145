import { useUserSession } from '../../models/hooks/appStateHooks';
import { ITemplateCard, ImpersonationBroker } from '../../viewmodels/AppViewModels';
import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';

export const EMAIL_MY_TEMPLATES_FILTER_QUERY_KEY = 'emailMyTemplatesFilter';

export const useEmailMyTemplatesFilter = ({
	enabled = true,
	industry,
	onError,
	term,
}: {
	enabled?: boolean;
	industry: Api.Industry;
	onError?: (err: Api.IOperationResultNoValue) => void;
	term?: string;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		onError,
		queryFn: async () => {
			const value = await userSession.webServiceHelper.callAsync<ITemplateCard[]>(
				ImpersonationBroker.composeApiUrl({
					queryParams: { search: term },
					urlPath: `template/${encodeURIComponent(industry)}/me/filter`,
				}),
				'GET'
			);
			return new Api.ObservableCollection(value, 'id');
		},
		queryKey: [EMAIL_MY_TEMPLATES_FILTER_QUERY_KEY, term],
	});
};
