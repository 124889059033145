import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';

const QUERY_BASE_KEY = 'handwrittenCardSearchTemplates';

export const useHandwrittenCardSearchTemplates = ({
	enabled = true,
	industry,
	search,
}: {
	enabled?: boolean;
	industry: Api.Industry;
	search: string;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () =>
			userSession.webServiceHelper.callAsync<Api.IHandwrittenCardTemplate[]>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: {
						search,
					},
					urlPath: `template/${encodeURIComponent(industry)}/filter/cards`,
				}),
				'GET'
			),
		queryKey: [
			QUERY_BASE_KEY,
			{
				industry,
				search,
			},
		],
	});
};
