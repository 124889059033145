import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = 'automationTemplate/<id>';

export function useAutomationTemplateIdQuery({ id }: { id: string }) {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IAutomationTemplate>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `automationTemplate/${encodeURIComponent(id)}`,
				}),
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY, id],
	});
}
