import { useMutation } from '@tanstack/react-query';
import { useApiClientContext } from '../../surveys/context';
import { IEventSurveyResponse, IOperationResultNoValue } from '../../surveys/models/Api';

export const useClientUpdateEventRegistrationResponseMutation = ({
	onError,
	onSuccess,
}: {
	onError?: (error: IOperationResultNoValue) => void;
	onSuccess?: (data?: IEventSurveyResponse) => void;
}) => {
	const { apiClient } = useApiClientContext();

	return useMutation({
		mutationFn: ({ body, accessToken }: { accessToken: string; body: IEventSurveyResponse }) => {
			return apiClient.put<IEventSurveyResponse>(`survey/eventregistration/response?token=${accessToken}`, body);
		},
		onError,
		onSuccess,
	});
};
