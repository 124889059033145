import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_API_KEY = 'opportunityBoard';

export function useInfiniteBoardsQuery({
	pageSize = 25,
	sort = 'asc',
	type,
	onError,
	refetchOnWindowFocus = false,
}: {
	pageSize?: number;
	sort?: 'asc' | 'desc';
	type: string;
	onError?: (error: Api.IOperationResultNoValue) => void;
	refetchOnWindowFocus?: boolean;
}) {
	const userSession = useUserSession();
	return useInfiniteQuery<Api.IPagedCollection<Api.IBoard>>({
		getNextPageParam: lastPage => lastPage.pageToken,
		queryFn: ({ pageParam }) =>
			userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IBoard>>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: {
						pageSize,
						pageToken: pageParam,
						sort,
						type: encodeURIComponent(type),
					},
					urlPath: `board`,
				}),
				'GET'
			),
		queryKey: [BASE_API_KEY, type, sort, pageSize],
		onError,
		refetchOnWindowFocus,
	});
}

export const invalidateBoardsQuery = () => {
	reactQueryClient.invalidateQueries([BASE_API_KEY]);
};
