import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'googleBusiness/reviewsNoReply';

export const useGetGoogleBusinessReviewsWithoutReplies = ({
	enabled = true,
	pageSize = 25,
	sort = 'desc',
	refetchOnWindowFocus = false,
}: {
	enabled?: boolean;
	pageSize?: number;
	refetchOnWindowFocus?: boolean;
	sort?: 'desc' | 'asc';
}) => {
	const userSession = useUserSession();

	return useInfiniteQuery<Api.IPagedCollection<Api.IGoogleBusinessReview>>({
		enabled,
		getNextPageParam: lastPage => lastPage.pageToken,
		queryFn: ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IGoogleBusinessReview>>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: 'googleBusiness/reviewsNoReply',
					queryParams: {
						pageSize,
						pageToken: pageParam,
						sort,
						sortBy: 'updateTime',
					},
				}),
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY, 'reviewsNoReply'],
		refetchOnWindowFocus,
	});
};

export const invalidateGetGoogleBusinessReviewsWithoutReplies = () => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY, 'reviewsNoReply']);
};
