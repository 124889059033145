import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const TELEPHONY_BUSINESS_TYPES_QUERY_KEY = 'telephonyBusinessTypesQueryKey';

export function useTelephonyConfigBusinessType() {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () =>
			userSession.webServiceHelper.callAsync<{ value: string; description: string }[]>(
				'telephony/configuration/clientProfile/businessTypes',
				'GET'
			),
		queryKey: [TELEPHONY_BUSINESS_TYPES_QUERY_KEY],
	});
}
