import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

export const CONTACT_CUSTOM_FIELDS_QUERY_KEY = 'contact/customFields';
export const useContactCustomFields = ({
	impersonationContext,
	refetchOnWindowFocus,
	enabled = true,
}: {
	impersonationContext?: Api.IImpersonationContext;
	refetchOnWindowFocus?: boolean;
	enabled?: boolean;
} = {}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: async () => {
			return userSession.webServiceHelper.callAsync<Api.IFormResponse<string>>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: CONTACT_CUSTOM_FIELDS_QUERY_KEY,
				}),
				'GET'
			);
		},
		queryKey: [CONTACT_CUSTOM_FIELDS_QUERY_KEY],
		refetchOnWindowFocus,
	});
};

export const invalidateContactCustomFields = () => {
	reactQueryClient.invalidateQueries([CONTACT_CUSTOM_FIELDS_QUERY_KEY]);
};
