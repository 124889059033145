import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useUserProfilePicMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (response: Api.IUser) => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		// @ts-ignore
		mutationFn: ({ file }: { file: File }) => {
			const formData = new FormData();
			formData.append('file', file);
			return userSession.webServiceHelper.callAsync<Api.IUser>('user/profilePic', 'PUT', formData);
		},
		onError,
		onSuccess,
	});
}
