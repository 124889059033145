import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { CreateTextCampaignAutomationRequest } from '../../extViewmodels/sdk/models/requests/CreateAutomationRequest';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useAutomationTemplateTextingCampaignMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: (data: Api.IAutomationTemplate) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ request, files }: { request: CreateTextCampaignAutomationRequest; files?: File[] }) => {
			const formData = new FormData();
			formData.append('request', JSON.stringify(request));
			if (files) {
				for (const file of files) {
					formData.append('files', file);
				}
			}
			const value = await userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `automationTemplate/textingCampaign`,
				}),
				'POST',
				formData
			);
			return value as Api.IAutomationTemplate; // TODO: nice to have yup AutomationTemplateSchema
		},
		onSuccess,
		onError,
	});
}
