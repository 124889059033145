import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export function useUserMapMutation({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IIntegrationUserMapping[]) => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		// @ts-ignore
		mutationFn: ({
			type,
			userMap,
		}: {
			type: Api.ConfigurableIntegrationType;
			userMap: Api.IIntegrationUserMapping[];
		}) => {
			return userSession.webServiceHelper.callAsync<Api.IIntegrationUserMapping[]>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `account/integrations/${type}/userMap`,
				}),
				'PUT',
				userMap
			);
		},
		onError,
		onSuccess,
	});
}
