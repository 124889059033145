import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useCancelCriteriaContactFilterMutation = ({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onSuccess?: (data: Api.ISystemJob) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
} = {}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ id, cancelCriteria }: { id: string; cancelCriteria: Api.IContactFilterCriteria[] }) => {
			const body: { criteria?: Api.IContactFilterCriteria[] } = {};
			if (cancelCriteria.length) {
				body['criteria'] = cancelCriteria;
			}
			const systemJob = await userSession.webServiceHelper.callAsync<Api.ISystemJob>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `automationTemplate/${id}/ContactFilter`,
				}),
				'PUT',
				body
			);
			return systemJob as Api.ISystemJob;
		},
		onError,
		onSuccess,
	});
};
