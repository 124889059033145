import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useUpdateDonationMutation = ({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IDonation) => void;
} = {}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({
			donation,
			impersonationContext,
		}: {
			donation: Api.IDonation;
			impersonationContext?: Api.IImpersonationContext;
		}) => {
			return userSession.webServiceHelper.callAsync<Api.IDonation>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `donation/${donation.id}`,
				}),
				'PUT',
				donation
			);
		},
		onError,
		onSuccess,
	});
};
