import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useCreateInsurancePolicyCarrierMutation = ({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IInsurancePolicyCarrier) => void;
} = {}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({
			carrier,
			impersonationContext,
		}: {
			carrier: Api.IInsurancePolicyCarrier;
			impersonationContext?: Api.IImpersonationContext;
		}) => {
			return userSession.webServiceHelper.callAsync<Api.IInsurancePolicyCarrier>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `insurancePolicy/carrier`,
				}),
				'POST',
				carrier
			);
		},
		onError,
		onSuccess,
	});
};
