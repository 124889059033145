import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { reactQueryClient } from '../../web/ReactQueryProvider';

export const GENERIC_KEY_DATES_QUERY_KEY = ['genericKeyDates'];

export const useGenericKeyDates = ({
	userSession,
	handleError,
}: {
	userSession: Api.UserSessionContext;
	handleError?: (err: Api.IOperationResultNoValue) => void;
}) => {
	return useQuery({
		onError: handleError,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IUpcomingKeyDates>('dashboard/genericKeyDates', 'GET');
		},
		queryKey: GENERIC_KEY_DATES_QUERY_KEY,
	});
};

export const invalidateGenericKeyDates = () => reactQueryClient.invalidateQueries(GENERIC_KEY_DATES_QUERY_KEY);
