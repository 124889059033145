import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'getGoogleBusinessConnection';

export const useGetGoogleBusinessConnection = ({
	enabled,
	impersonationContext,
	onSuccess,
	refetchOnWindowFocus = false,
}: {
	enabled: boolean;
	impersonationContext?: Api.IImpersonationContext;
	onSuccess?: (connection: Api.IGoogleBusinessConnection) => void;
	refetchOnWindowFocus?: boolean;
}) => {
	const userSession = useUserSession();

	return useQuery({
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IGoogleBusinessConnection>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: 'googleBusiness/connection',
				}),
				'GET'
			);
		},
		onSuccess,
		enabled,
		queryKey: [BASE_QUERY_KEY, impersonationContext],
		refetchOnWindowFocus,
	});
};

export const invalidateGoogleBusinessConnection = ({
	impersonationContext,
}: {
	impersonationContext?: Api.IImpersonationContext;
} = {}) => {
	return reactQueryClient.invalidateQueries([BASE_QUERY_KEY, impersonationContext]);
};
