import { useQuery } from '@tanstack/react-query';
import * as AdminModels from '../../../models/AdminModels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'admin/template/blogCategoryNames';

export function useBlogTemplateCategoryNames() {
	const userSession = useUserSession();
	return useQuery({
		queryFn: async () => {
			return userSession.webServiceHelper.callAsync<AdminModels.ITemplatesIndustryWithCategories[]>(
				`admin/template/blogCategoryNames`,
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY],
	});
}

export const invalidateBlogTemplateCategoryNames = () => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
};
