import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useAccountIntegrationDonorPerfectApiKey = ({
	impersonationContext,
	onSuccess,
	onError,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IConfigurableIntegration) => void;
} = {}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ apiKey }: { apiKey: Api.ISaveApiKeyRequest }) => {
			const result = await userSession.webServiceHelper.callAsync<Api.IConfigurableIntegration>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `account/integrations/donorperfect/apiKey`,
				}),
				'PUT',
				apiKey
			);
			return result;
		},
		onError,
		onSuccess,
	});
};
