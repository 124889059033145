import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

interface Params {
	url: string;
	impersonationContext?: Api.IImpersonationContext;
}
export function useBlogUrlImportMutation({
	onError,
	onSuccess,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (val: boolean) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ url, impersonationContext }: Params) => {
			const value = await userSession.webServiceHelper.callAsync<boolean>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `blog/importFromUrl`,
				}),
				'POST',
				{ rssUrl: url }
			);
			return value;
		},
		onError,
		onSuccess,
	});
}
