import * as AdminModels from '../../models/AdminModels';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { useQuery } from '@tanstack/react-query';

const BASE_QUERY_KEY = 'templateHwcCategoryNames';

export function useTemplateHwcCategoryNames() {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<AdminModels.ITemplatesIndustryWithCategories[]>(
				`admin/template/cardCategoryNames`,
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY],
	});
}
