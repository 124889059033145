import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const QUERY_KEY = 'connectionTypes';

export interface IConnectionTypeResults {
	availableConnectionTypes: Api.IConnectionType[];
}

export function useConnectionTypes({
	enabled = true,
	impersonationContext,
	onError,
	refetchOnWindowFocus,
}: {
	enabled?: boolean;
	impersonationContext?: Api.IImpersonationContext;
	onError?: (err: Api.IOperationResultNoValue) => void;
	refetchOnWindowFocus?: boolean;
}) {
	const userSession = useUserSession();

	return useQuery({
		enabled,
		onError,
		queryFn: async () => {
			return userSession.webServiceHelper.callAsync<IConnectionTypeResults>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: 'connectionTypes',
				}),
				'GET',
				null
			);
		},
		queryKey: [QUERY_KEY],
		refetchOnWindowFocus,
	});
}

export const invalidateConnectionTypes = () => {
	reactQueryClient.invalidateQueries([QUERY_KEY]);
};
