import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';

const BASE_QUERY_KEY = 'boardStageItemsTotals';

export function useBoardStageItemsTotals({
	searchQuery,
	stageId,
}: {
	searchQuery?: Api.IOpportunitySearchRequest;
	stageId: string;
}) {
	const userSession = useUserSession();
	return useQuery({
		queryFn: async () => {
			const result = await userSession.webServiceHelper.callAsync<{
				average: number;
				total: number;
			}>(`board/stage/${stageId}/items/search/stageInfo`, 'POST', searchQuery || {});
			return result;
		},
		queryKey: [BASE_QUERY_KEY, stageId, searchQuery],
	});
}
