import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

interface Params {
	request: Api.IBlogContentGenerationRequest;
	sendFromUserId?: string;
	impersonationContext?: Api.IImpersonationContext;
}
export function useBlogGenerateFromUserMutation({
	onError,
	onSuccess,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (val: Api.IAIGeneratedContent<Api.IBlogPost>) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ request, sendFromUserId, impersonationContext }: Params) => {
			if (!('contentLength' in request)) {
				request.contentLength = Api.AIContentLength.Medium;
			}
			const value = await userSession.webServiceHelper.callAsync<Api.IAIGeneratedContent<Api.IBlogPost>>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `blog/generateFromUser`,
					queryParams: {
						forUserId: sendFromUserId,
					},
				}),
				'POST',
				request
			);
			return value;
		},
		onError,
		onSuccess,
	});
}
