import { useUserSession } from '../../models/hooks/appStateHooks';
import { ITemplateCard } from '../../viewmodels/AppViewModels';
import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export function useEmailMyTemplatesUpdateKeywords({
	onError,
	onSuccess,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ keywords, templateId }: { keywords: string[]; templateId: string }) => {
			const value = await userSession.webServiceHelper.callAsync<ITemplateCard>(
				`template/${templateId}/keywords`,
				'PUT',
				keywords
			);
			return value;
		},
		onError,
		onSuccess,
	});
}
