import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useCreateInsurancePolicyLineOfBusinessMutation = ({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IInsurancePolicyLineOfBusiness) => void;
} = {}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({
			policyLineOfBusiness,
			impersonationContext,
		}: {
			policyLineOfBusiness: Api.IInsurancePolicyLineOfBusiness;
			impersonationContext?: Api.IImpersonationContext;
		}) => {
			return userSession.webServiceHelper.callAsync<Api.IInsurancePolicyLineOfBusiness>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `insurancePolicy/lineOfBusiness`,
				}),
				'POST',
				policyLineOfBusiness
			);
		},
		onError,
		onSuccess,
	});
};
