import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'phonenumber/filter';

export function useGetPhoneNumbers({
	filterRequest,
	impersonationContext,
	onError,
	pageSize = 25,
	refetchOnWindowFocus = false,
}: {
	filterRequest: Api.TelephonyConfigurationFilter;
	impersonationContext: Api.IImpersonationContext;
	onError?: (err: Api.IOperationResultNoValue) => void;
	pageSize?: number;
	refetchOnWindowFocus?: boolean;
}) {
	const userSession = useUserSession();
	return useInfiniteQuery<Api.IPagedCollection<Api.ITelephonyConfiguration>>({
		getNextPageParam: lastPage => lastPage.pageToken,
		onError,
		queryFn: async ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.ITelephonyConfiguration>>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: { pageSize, pageToken: pageParam },
					urlPath: BASE_QUERY_KEY,
				}),
				'POST',
				filterRequest
			);
		},
		queryKey: [BASE_QUERY_KEY, impersonationContext, filterRequest, pageSize],
		refetchOnWindowFocus,
	});
}

export const invalidateGetPhoneNumbers = (
	impersonationContext: Api.IImpersonationContext,
	filterRequest?: Api.TelephonyConfigurationFilter
) => {
	return reactQueryClient.invalidateQueries([BASE_QUERY_KEY, impersonationContext, filterRequest].filter(Boolean));
};
