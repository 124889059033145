import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { stringify as toQueryStringParams } from 'query-string';

export function useUpdateUserMilestone<T>({
	userSession,
	onError,
	onSuccess,
}: {
	userSession: Api.UserSessionContext;
	onError?: (error: unknown) => void;
	onSuccess?: (userMilestones: Api.IUserMilestones) => void;
}) {
	return useMutation({
		mutationFn: async ({ userId, name, value }: { userId: string; name: keyof Api.IUserMilestones; value: T }) => {
			if (!userSession.isAuthenticated || !name || value == null) {
				return;
			}

			const query = toQueryStringParams({ name, value });
			const resultValue = await userSession.webServiceHelper.callAsync<Api.IUserMilestones>(
				`user/${userId}/updateUserMilestone?${query}`,
				'PATCH',
				null
			);

			return resultValue;
		},
		onError,
		onSuccess,
	});
}
