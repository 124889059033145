import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { ITemplateCard, ImpersonationBroker } from '../../viewmodels/AppViewModels';
import { reactQueryClient } from '../../web/ReactQueryProvider';

export const SOCIAL_ALL_CATEGORY_TEMPLATES_QUERY_KEY = 'socialAllCategoryTemplates';

export const useSocialAllCategoryTemplates = ({
	impersonationContext,
	industry,
	excludeExpired = true,
	enabled = true,
	itemListLimit,
}: {
	impersonationContext?: Api.IImpersonationContext;
	industry: Api.Industry;
	enabled?: boolean;
	excludeExpired?: boolean;
	itemListLimit?: number;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IDictionary<ITemplateCard[]>>(
				ImpersonationBroker.composeApiUrl({
					queryParams: {
						excludeExpired,
						itemListLimit,
					},
					urlPath: `template/${encodeURIComponent(industry)}/browse/social`,
					impersonationContext,
				}),
				'GET'
			);
		},
		queryKey: [SOCIAL_ALL_CATEGORY_TEMPLATES_QUERY_KEY, industry, excludeExpired, impersonationContext],
	});
};

export const invalidateSocialAllCategoryTemplates = () => {
	reactQueryClient.invalidateQueries([SOCIAL_ALL_CATEGORY_TEMPLATES_QUERY_KEY]);
};
