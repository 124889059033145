import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'getGoogleBusinessReview';

export const useGetGoogleBusinessReview = ({
	enabled = true,
	reviewId,
	refetchOnWindowFocus = false,
}: {
	enabled?: boolean;
	reviewId: string;
	refetchOnWindowFocus?: boolean;
}) => {
	const userSession = useUserSession();

	return useQuery({
		enabled,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IGoogleBusinessReview>(
				`googleBusiness/review/${encodeURIComponent(reviewId)}`,
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY, reviewId],
		refetchOnWindowFocus,
	});
};

export const invalidateGetGoogleBusinessReview = (reviewId: string) => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY, reviewId]);
};
