import { useQuery } from '@tanstack/react-query';
import { Industry } from '../../../extViewmodels';
import * as AdminModels from '../../../models/AdminModels';
import { getCategoryPathByCampaignType } from '../../../models/UiUtils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'admin/template/blogCategory';

export function useAdminBlogTemplateCategories({
	industry,
	campaign,
}: {
	industry: Industry;
	campaign: AdminModels.CampaignType;
}) {
	const userSession = useUserSession();
	const categoryType = getCategoryPathByCampaignType(campaign);
	return useQuery({
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<AdminModels.ITemplatesIndustryWithCategories[]>(
				`admin/template/${encodeURIComponent(industry)}/${categoryType}`,
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY],
	});
}

export const invalidateBlogTemplateCategories = () => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
};
