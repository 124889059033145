import { useMutation } from '@tanstack/react-query';
import { useApiClientContext } from '../../surveys/context';
import { IEventSurveyResponse, IOperationResultNoValue } from '../../surveys/models/Api';

export const useClientCreateEventRegistrationResponseMutation = ({
	onError,
	onSuccess,
}: {
	onError?: (error: IOperationResultNoValue) => void;
	onSuccess?: (data?: IEventSurveyResponse) => void;
}) => {
	const { apiClient } = useApiClientContext();

	return useMutation({
		mutationFn: ({ body, token }: { body: IEventSurveyResponse; token: string }) => {
			return apiClient.post<IEventSurveyResponse>(`survey/eventRegistration/response/?token=${token}`, body);
		},
		onError,
		onSuccess,
	});
};
