import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { IBlogTemplateCard } from '../../viewmodels/AppViewModels';

const QUERY_BASE_KEY = 'template/industry/filter/blogs';

export const useBlogsCardSearchTemplates = ({
	enabled = true,
	industry,
	search,
	impersonationContext,
}: {
	enabled?: boolean;
	industry: Api.Industry;
	search: string;
	impersonationContext?: Api.IImpersonationContext;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () =>
			userSession.webServiceHelper.callAsync<IBlogTemplateCard[]>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: {
						search,
					},
					urlPath: `template/${encodeURIComponent(industry)}/filter/blogs`,
					impersonationContext,
				}),
				'GET'
			),
		queryKey: [
			QUERY_BASE_KEY,
			{
				industry,
				search,
			},
		],
	});
};
