import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { ISystemEmailTemplate } from '../../../models/AdminModels';
import { useUserSession } from '../../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = 'admin/template/search';

export function useAdminTemplateSearchQuery({
	request,
	enabled,
}: {
	request: Api.TemplateSearchRequest;
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () =>
			userSession.webServiceHelper.callAsync<ISystemEmailTemplate[]>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `admin/template/search`,
				}),
				'POST',
				request
			),
		queryKey: [BASE_QUERY_KEY, request],
	});
}
