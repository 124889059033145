import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const TELEPHONY_CONFIGURATION_FEEDBACK_QUERY_KEY = 'telephonyConfigurationFeedbackQueryKey';

export function useTelephonyGetConfigurationFeedback({
	configurationId,
	enabled = false,
}: {
	configurationId?: string;
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled: enabled && configurationId != null,
		queryFn: async () => {
			const response = await userSession.webServiceHelper.callAsync<string[]>(
				`telephony/configuration/brand/feedback`,
				'GET'
			);
			return response;
		},
		queryKey: [TELEPHONY_CONFIGURATION_FEEDBACK_QUERY_KEY, configurationId],
	});
}
