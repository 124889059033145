import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_API_KEY = '/donation/donor/summary';

const emptySummary = {
	totalDonations: 0,
	totalAmount: 0,
	totalDonors: 0,
};

export const useDonorSummaryQuery = ({
	filterRequest,
	enabled,
	refetchOnWindowFocus = false,
}: {
	filterRequest: Api.IDonorFilterRequest;
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: async () => {
			const value = await userSession.webServiceHelper.callAsync<Api.IDonationSummary>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `donation/donor/summary`,
				}),
				'POST',
				filterRequest
			);
			return value ?? emptySummary;
		},
		queryKey: [BASE_API_KEY, filterRequest],
		refetchOnWindowFocus,
	});
};
