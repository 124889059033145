import { useQuery } from '@tanstack/react-query';
import { useApiClientContext } from '../../surveys/context';
import { IEventSurveyResponse } from '../../surveys/models/Api';

export const GET_RESPONSE_BY_TOKEN_QUERY_KEY = 'getByTokenQueryKey';

export const useClientGetEventRegistrationResponseByToken = ({
	accessToken,
	enabled = true,
	refetchOnWindowFocus = false,
}: {
	accessToken: string;
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
}) => {
	const { apiClient } = useApiClientContext();

	return useQuery({
		enabled,
		queryFn: () => {
			return apiClient.get<IEventSurveyResponse>(`survey/eventregistration/response/byToken?token=${accessToken}`);
		},
		queryKey: [GET_RESPONSE_BY_TOKEN_QUERY_KEY, accessToken],
		refetchOnWindowFocus,
	});
};
