import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useAccountIntegrationDonorPerfectDeleteApiKey = ({
	impersonationContext,
	onSuccess,
	onError,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: boolean) => void;
} = {}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async () => {
			const result = await userSession.webServiceHelper.callAsync<boolean>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `account/integrations/donorperfect/apiKey/`,
				}),
				'DELETE'
			);
			return result;
		},
		onError,
		onSuccess,
	});
};
