import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const TELEPHONY_BUSINESS_INDUSTRIES_QUERY_KEY = 'telephonyBusinessIndustriesQueryKey';

export function useTelephonyConfigBusinessIndustry() {
	const userSession = useUserSession();
	return useQuery({
		queryFn: async () => {
			const response = await userSession.webServiceHelper.callAsync<{ value: string; description: string }[]>(
				'telephony/configuration/clientProfile/businessIndustries',
				'GET'
			);
			return response;
		},
		queryKey: [TELEPHONY_BUSINESS_INDUSTRIES_QUERY_KEY],
	});
}
