import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { TelephonyServiceProviderConfiguration } from '../../extViewmodels/sdk/models/Telephony';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useTelephonySaveConfiguration({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async (body: TelephonyServiceProviderConfiguration) => {
			const response = await userSession.webServiceHelper.callAsync<TelephonyServiceProviderConfiguration>(
				'telephony/configuration',
				'POST',
				body
			);
			return response;
		},
		onError,
		onSuccess,
	});
}
