import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useUpdateBoardMutation = ({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IBoard) => void;
} = {}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: (board: Api.IBoard) => {
			return userSession.webServiceHelper.callAsync<Api.IBoard>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `board/${board.id}`,
				}),
				'PUT',
				board
			);
		},
		onError,
		onSuccess,
	});
};
