import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { TelephonyServiceProviderConfiguration } from '../../extViewmodels/sdk/models/Telephony';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useTelephonyCampaignResubmitMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: (params: { impersonationContext?: Api.IImpersonationContext; campaignId: string }) => {
			return userSession.webServiceHelper.callAsync<TelephonyServiceProviderConfiguration>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext: params.impersonationContext,
					urlPath: `telephony/config/campaign/${encodeURIComponent(params.campaignId)}/resubmit`,
				}),
				'POST'
			);
		},
		onError,
		onSuccess,
	});
}
