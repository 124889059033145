import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_API_KEY = '/donor';

export const useDonorFilterQuery = ({
	pageSize = 25,
	filterRequest,
	enabled,
	refetchOnWindowFocus = false,
	expand = 'contact',
}: {
	pageSize?: number;
	filterRequest: Api.IDonorFilterRequest;
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
	expand?: string;
}) => {
	const userSession = useUserSession();
	return useInfiniteQuery({
		enabled,
		queryFn: async ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IDonor>>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { pageSize, pageToken: pageParam, expand },
					urlPath: `donation/donor/filter`,
				}),
				'POST',
				filterRequest
			);
		},
		getNextPageParam: lastPage => lastPage.pageToken,
		queryKey: [BASE_API_KEY, filterRequest, pageSize],
		refetchOnWindowFocus,
	});
};

export const invalidateAllDonorFilters = () => {
	reactQueryClient.invalidateQueries([BASE_API_KEY]);
};

export const invalidateDonorFilter = ({
	filterRequest,
	pageSize = 25,
}: {
	filterRequest: Api.IDonorFilterRequest;
	pageSize?: number;
}) => {
	reactQueryClient.invalidateQueries([BASE_API_KEY, filterRequest, pageSize]);
};
