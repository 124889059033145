import { ImpersonationBroker } from '../../extViewmodels';
import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export const useDeactivateTextingPhoneNumber = ({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ id }: { id: string }) => {
			return userSession.webServiceHelper.callAsync(
				ImpersonationBroker.composeApiUrl({ impersonationContext, urlPath: `phoneNumber/${id}` }),
				'DELETE',
				null
			);
		},
		onError,
		onSuccess,
	});
};
