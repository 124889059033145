import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useSuppressDashboardItem({
	onError,
	onSuccess,
}: {
	onError?: (error: unknown) => void;
	onSuccess?: () => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ id, context }: { id: string; context: Api.DashboardSuppressContext }) => {
			return userSession.webServiceHelper.callAsync<Api.IOperationResultNoValue>('dashboard/suppress', 'POST', {
				context,
				id,
			});
		},
		onError,
		onSuccess,
	});
}
