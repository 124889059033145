import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useRescheduleTextCampaignMutation = ({
	onError,
	onSuccess,
}: {
	onSuccess?: (data: Api.ISystemJob) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
} = {}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ bulkAutomationId, dueDate }: { bulkAutomationId: string; dueDate: Date }) => {
			return userSession.webServiceHelper.callAsync<Api.ISystemJob>(
				Api.ImpersonationBroker.composeApiUrl({ urlPath: 'reports/texting/campaign/reschedule' }),
				'POST',
				{
					bulkAutomationId,
					dueDate,
				}
			);
		},
		onError,
		onSuccess,
	});
};
