import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { ITemplateCard, ImpersonationBroker } from '../../viewmodels/AppViewModels';
import { reactQueryClient } from '../../web/ReactQueryProvider';

export const EMAIL_ALL_CATEGORY_TEMPLATES_QUERY_KEY = 'emailAllCategoryTemplates';

export const useEmailAllCategoryTemplates = ({
	industry,
	excludeExpired = true,
	enabled = true,
	onError,
	impersonationContext,
}: {
	industry: Api.Industry;
	enabled?: boolean;
	excludeExpired?: boolean;
	onError?: (err: Api.IOperationResultNoValue) => void;
	impersonationContext?: Api.IImpersonationContext;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		onError,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IDictionary<ITemplateCard[]>>(
				ImpersonationBroker.composeApiUrl({
					queryParams: {
						excludeExpired,
					},
					urlPath: `template/${encodeURIComponent(industry)}/browse`,
					impersonationContext,
				}),
				'GET'
			);
		},
		queryKey: [EMAIL_ALL_CATEGORY_TEMPLATES_QUERY_KEY, industry, excludeExpired, impersonationContext],
	});
};

export const invalidateEmailAllCategoryTemplates = () => {
	reactQueryClient.invalidateQueries([EMAIL_ALL_CATEGORY_TEMPLATES_QUERY_KEY]);
};
