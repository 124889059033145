import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = '/template/byResourceSelector';

export function useByResourceSelectorQuery({
	resourceSelector,
	templateType,
	excludeExpired = true,
}: {
	resourceSelector: Api.ResourceSelectorId;
	templateType?: Api.TemplateType;
	excludeExpired?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () =>
			userSession.webServiceHelper.callAsync<Api.ITemplate[]>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: {
						excludeExpired,
						resourceSelector,
						templateType,
					},
					urlPath: 'template/byResourceSelector',
				}),
				'GET'
			),
		queryKey: [BASE_QUERY_KEY, excludeExpired, resourceSelector, templateType],
	});
}
