import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useCreateCustomPageStyleMutation({
	onSuccess,
	onError,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (val: Api.ICustomPageStyle) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({
			style,
			impersonationContext,
		}: { style: Api.ICustomPageStyle; impersonationContext?: Api.IImpersonationContext }) => {
			return userSession.webServiceHelper.callAsync<Api.ICustomPageStyle>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `CustomPageStyle`,
				}),
				'POST',
				style
			);
		},
		onError,
		onSuccess,
	});
}
