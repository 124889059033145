import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { ImpersonationBroker } from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export const useRemoveAssignedUsersFromPhoneNumberMutation = ({
	impersonationContext,
	onSuccess,
	onError,
}: {
	impersonationContext: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({ phoneNumber, userIds }: { phoneNumber: Api.ITelephonyConfiguration; userIds: string[] }) => {
			return userSession.webServiceHelper.callAsync(
				ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `phoneNumber/${phoneNumber.id}/assignedUsers`,
				}),
				'DELETE',
				userIds
			);
		},
		onError,
		onSuccess,
	});
};
