import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useConvertSocialVideoMutation() {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: async ({ jobId, postId, forUserId }: { jobId: string; postId: string; forUserId: string }) => {
			return userSession.webServiceHelper.callAsync<Api.IMediaConvertSystemJob>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { forUserId },
					urlPath: `social/post/${postId}/convertVideo/${jobId}`,
				}),
				'POST'
			);
		},
	});
}
