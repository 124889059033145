import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'note/byAccount';

export function useInfiniteNotesByAccount({
	pageSize = 25,
	sort = 'desc',
	onError,
	enabled = true,
}: {
	pageSize?: number;
	sort?: 'asc' | 'desc';
	onError?: (err: Api.IOperationResultNoValue) => void;
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	return useInfiniteQuery<Api.IPagedCollection<Api.INote>>({
		// @ts-ignore
		enabled,
		getNextPageParam: lastPage => lastPage.pageToken,
		onError,
		queryFn: async ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.INote>>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { pageSize, pageToken: pageParam, sort },
					urlPath: `note/byAccount`,
				}),
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY, pageSize, sort],
	});
}

export const invalidateNotesByAccount = () => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
};
