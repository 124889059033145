import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import * as AdminModels from '../../../../models/AdminModels';
import { useUserSession } from '../../../../models/hooks/appStateHooks';

export function useAdminTemplateIndustrySocialCategoriesStatusesMutation({
	onError,
	onSuccess,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (value: AdminModels.ISystemEmailTemplateWithStatuses) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ templateId, statuses }: { templateId: string; statuses: Api.SystemEmailTemplateStatus[] }) => {
			return userSession.webServiceHelper.callAsync<AdminModels.ISystemEmailTemplateWithStatuses>(
				`admin/template/industry/socialCategories/${encodeURIComponent(templateId)}/statuses`,
				'POST',
				statuses
			);
		},
		onError,
		onSuccess,
	});
}
