import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export function useHWCOrderMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: () => void;
	onError?: (err: Api.IOperationResultNoValue) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({
			content,
			recipients,
			returnContact,
			scheduledSendDate,
			signature,
			style,
			templateId,
		}: {
			content: Api.IRawRichTextContentState;
			signature: string;
			templateId: string;
			scheduledSendDate: Date;
			style: Api.HandwritingStyle;
			returnContact: Api.IHandwrittenCardReturnContact;
			recipients: Api.IHandwrittenCardRecipient[];
		}) => {
			return userSession.webServiceHelper.callAsync<any>(
				Api.ImpersonationBroker.composeApiUrl({ urlPath: `handwrittencard/order` }),
				'POST',
				{
					content,
					recipients,
					returnContact,
					scheduledSendDate,
					signature,
					style,
					templateId,
				}
			);
		},
		onError,
		onSuccess,
	});
}
