import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const usePostAttachmentCardImage = ({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IBulkOperationResult<Api.IFileAttachment>) => void;
}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: async ({ file, scale }: { file: Blob; scale: number }) => {
			const formData = new FormData();
			formData.append('files', file);

			return userSession.webServiceHelper.callWebServiceAsync<Api.IBulkOperationResult<Api.IFileAttachment>>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `attachment/cardImage?scale=${scale}`,
				}),
				'POST',
				formData
			);
		},
		onError,
		onSuccess,
	});
};
