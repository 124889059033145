import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useDeleteBlogPostMutation({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (post: Api.IBlogPost) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ id }: { id: string }) => {
			return userSession.webServiceHelper.callAsync<Api.IBlogPost>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `blog/${id}`,
				}),
				'DELETE',
				null
			);
		},
		onError,
		onSuccess,
	});
}
