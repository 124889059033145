import * as AdminModels from '../../../models/AdminModels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';

const BASE_API_KEY = `admin/template/industry/cardCategories/category/template`;

// ** WARNING **
// Exporting such fetch function should not be a general pattern. Always use queries instead of direct fetches.
// This is necessary as a result of sub-optimal API where a fetch needs to happen in the process of a mutation.
// Intentionally not using this function for the body of the queryFn below.
export function fetchHwcTemplate({
	userSession,
	templateId,
}: {
	userSession: Api.UserSessionContext;
	templateId: string;
}) {
	return userSession.webServiceHelper.callAsync<AdminModels.ISystemEmailTemplateWithStatuses>(
		`admin/template/industry/cardCategories/templates/${encodeURIComponent(templateId)}`,
		'GET'
	);
}

export function useHwcIndustryCategoryTemplate({
	enabled = true,
	templateId,
	refetchOnWindowFocus = true,
}: {
	enabled?: boolean;
	templateId: string;
	refetchOnWindowFocus?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<AdminModels.IHandwrittenCardTemplateWithStatuses>(
				`admin/template/industry/cardCategories/templates/${encodeURIComponent(templateId)}`,
				'GET'
			);
		},
		queryKey: [BASE_API_KEY, { templateId }],
		refetchOnWindowFocus,
	});
}
