import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

interface IParams {
	template: Api.IBlogTemplate;
}

export function getBlogPostTemplateAsync({
	id,
	userSession,
}: {
	id: string;
	impersonationContext?: Api.IImpersonationContext;
	userSession: Api.UserSessionContext;
}) {
	return userSession.webServiceHelper.callAsync<Api.IBlogTemplate>(
		Api.ImpersonationBroker.composeApiUrl({ urlPath: `template/${encodeURIComponent(id)}` }),
		'GET'
	);
}

export function useBlogGenerationMutation({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (val: Api.IBlogGenerationSystemJob) => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ template }: IParams) => {
			const value = await userSession.webServiceHelper.callAsync<Api.IBlogGenerationSystemJob>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `blog/generateFromTemplate`,
				}),
				'POST',
				template
			);
			return value;
		},
		onError,
		onSuccess,
	});
}
