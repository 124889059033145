import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'reports/texting/campaign/filter';

export function useInfiniteReportsTextingQuery({
	pageSize = 25,
	filter,
}: {
	pageSize?: number;
	filter?: Api.TextCampaignFilter | null;
}) {
	const userSession = useUserSession();
	return useInfiniteQuery({
		queryFn: async ({ pageParam }) => {
			const responseValue = await userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { pageSize, pageToken: pageParam },
					urlPath: `reports/texting/campaign/filter`,
				}),
				'POST',
				filter ?? {}
			);
			return responseValue as Api.IPagedCollection<Api.TextCampaignReportView>;
		},
		queryKey: [BASE_QUERY_KEY, filter, pageSize],
		getNextPageParam: lastPage => lastPage.pageToken,
	});
}

export function invalidateInfiniteReportsTextingQuery() {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
}
