import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useEmailCampaignCancelScheduleMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (campaign: Api.ICampaign) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		// @ts-ignore
		mutationFn: ({
			campaignId,
			impersonationContext,
		}: {
			campaignId: string;
			impersonationContext?: Api.IImpersonationContext;
		}) =>
			userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `email/${encodeURIComponent(campaignId)}/scheduledsend`,
					impersonationContext,
				}),
				'DELETE'
			),
		onError,
		onSuccess,
	});
}
