import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'getGoogleBusinessMetadata';

export const useGetGoogleBusinessMetadata = ({
	impersonationContext,
	refetchOnWindowFocus = false,
}: {
	impersonationContext?: Api.IImpersonationContext;
	refetchOnWindowFocus?: boolean;
}) => {
	const userSession = useUserSession();

	return useQuery({
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IGoogleBusinessMetadata>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: 'googleBusiness/metadata',
				}),
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY, impersonationContext],
		refetchOnWindowFocus,
	});
};

export const invalidateGoogleBusinessMetadata = ({
	impersonationContext,
}: {
	impersonationContext?: Api.IImpersonationContext;
} = {}) => {
	return reactQueryClient.invalidateQueries([BASE_QUERY_KEY, impersonationContext]);
};
