import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import * as AdminModels from '../../../models/AdminModels';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export function useBulkEmailAccountHistoricalStatsQuery({
	accountId,
	start,
	end,
}: {
	accountId: string;
	start: Date;
	end: Date;
}) {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () =>
			userSession.webServiceHelper.callAsync<AdminModels.IBulkEmailHistoricalReport>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `admin/bulkEmailHistorical/stats/${AdminModels.AggregationIdType.Account}/${encodeURIComponent(
						accountId
					)}`,
					queryParams: {
						start: start.toISOString(),
						end: end.toISOString(),
					},
				}),
				'GET'
			),
		queryKey: [`admin/bulkEmailHistorical/stats/0/<id>`, accountId, start, end],
	});
}
