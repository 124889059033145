import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'dashboard/ResourceSelector';

export function useGetResourceSelector({ resourceName, enabled = true }: { resourceName: string; enabled?: boolean }) {
	const userSession = useUserSession();

	return useQuery({
		enabled,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IGoogleBusinessReviewsResourceSelector[]>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `dashboard/ResourceSelector?typeOf=${encodeURIComponent(resourceName)}`,
				}),
				'GET',
				null
			);
		},
		queryKey: [BASE_QUERY_KEY, resourceName],
		refetchOnWindowFocus: false,
	});
}

export const invalidateResourceSelector = ({ resourceName }: { resourceName: string }) => {
	return reactQueryClient.invalidateQueries([BASE_QUERY_KEY, resourceName]);
};
