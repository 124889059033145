import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_API_KEY = 'contact/suggestedTags/v2';
export function useContactSuggestedTagsQuery({
	addKeepInTouchTag,
	pageSize = 5,
	impersonationContext,
}: {
	addKeepInTouchTag: boolean;
	pageSize: number;
	impersonationContext?: Api.IImpersonationContext;
}) {
	const userSession = useUserSession();
	return useQuery({
		queryFn: async () => {
			const data = await userSession.webServiceHelper.callAsync<Api.AccountTag[]>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { addKeepInTouchTag, pageSize },
					urlPath: `contact/suggestedTags/v2`,
					impersonationContext,
				}),
				'GET'
			);
			return data;
		},
		queryKey: [BASE_API_KEY, addKeepInTouchTag, pageSize],
	});
}
