import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	backgroundColor?: string;
	borderColor?: string;
	className?: string;
	foregroundColor?: string;
	strokeWidth?: string;
}

export const DangerIcon: React.FC<IProps> = props => {
	const { backgroundColor, borderColor, className, foregroundColor, strokeWidth } = props;
	return (
		<SvgIcon className={`danger-icon ${className || ''}`} height={17} width={20}>
			<path
				d='M10.015 1.675l8.426 13.957H1.561l8.454-13.957z'
				fill={backgroundColor || '#D0021B'}
				stroke={borderColor || '#fff'}
				strokeWidth={strokeWidth || '2'}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9 7h2l-.5 3.846h-1L9 7zm1 6.923a1 1 0 100-2 1 1 0 000 2z'
				fill={foregroundColor || '#fff'}
			/>
		</SvgIcon>
	);
};
