import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useAutomationTemplateStepEmailUpdateMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: (data: Api.IEmailAutomationStep) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({
			templateId,
			stepId,
			step,
			impersonationContext,
		}: {
			templateId: string;
			stepId: string;
			step: Api.IEmailAutomationStep;
			impersonationContext?: Api.IImpersonationContext;
		}) => {
			return userSession.webServiceHelper.callAsync<Api.IEmailAutomationStep>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `AutomationTemplate/${encodeURIComponent(templateId)}/Step/Email/${encodeURIComponent(stepId)}`,
					impersonationContext,
				}),
				'PUT',
				step
			);
		},
		onError,
		onSuccess,
	});
}
