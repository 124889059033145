import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const ACCOUNT_LOAD_QUERY_KEY = 'account-load-key';

export function useAccountLoad({ accountId, onError }: { accountId: string; onError: () => void }) {
	const userSession = useUserSession();
	return useQuery({
		onError,
		queryFn: () =>
			new Promise<Api.IAccount>((resolve, reject) =>
				userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAccount>(
					`account/${accountId}`,
					'GET',
					null,
					opResult => {
						// @ts-ignore
						resolve(opResult.value);
					},
					opResult => {
						onError();
						reject(opResult);
					}
				)
			),
		queryKey: [ACCOUNT_LOAD_QUERY_KEY],
	});
}

export const invalidateAccountLoad = () => {
	reactQueryClient.invalidateQueries([ACCOUNT_LOAD_QUERY_KEY]);
};
