import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { ImpersonationBroker } from '../../viewmodels/AppViewModels';
import { reactQueryClient } from '../../web/ReactQueryProvider';

export const BLOG_POST_MY_DRAFTS_QUERY_KEY = 'myBlogPostDrafts';

export const useBlogPostDraftsInfiniteQuery = ({
	pageSize = 25,
	enabled = false,
	userId,
}: {
	enabled?: boolean;
	pageSize?: number;
	userId: string;
}) => {
	const userSession = useUserSession();
	return useInfiniteQuery({
		enabled,
		queryFn: ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IBlogPost>>(
				ImpersonationBroker.composeApiUrl({
					queryParams: {
						pageSize,
						userId,
						pageToken: pageParam,
					},
					urlPath: `blog/drafts`,
				}),
				'GET'
			);
		},
		queryKey: [BLOG_POST_MY_DRAFTS_QUERY_KEY, userId, pageSize],
		getNextPageParam: lastPage => lastPage.pageToken,
	});
};

export const invalidateBlogPostDrafts = ({ pageSize = 25, userId }: { pageSize?: number; userId: string }) => {
	reactQueryClient.invalidateQueries([BLOG_POST_MY_DRAFTS_QUERY_KEY, userId, pageSize]);
};
