import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useEmailQuery({
	id,
	impersonationContext,
}: {
	id: string;
	impersonationContext?: Api.IImpersonationContext;
}) {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () =>
			userSession.webServiceHelper.callAsync<Api.ICampaign>(
				Api.ImpersonationBroker.composeApiUrl({ urlPath: `email/${encodeURIComponent(id)}`, impersonationContext }),
				'GET'
			),
		queryKey: [`email/<id>`, id, impersonationContext],
	});
}
