import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { KnownCategories } from '../../viewmodels/AppViewModels';

const QUERY_BASE_KEY = 'handwrittenCardCategories';

export const useHandwrittenCardCategories = ({
	enabled = true,
	industry,
	withAll = false,
	withMyTemplates = false,
}: {
	enabled?: boolean;
	industry: Api.Industry;
	withAll?: boolean;
	withMyTemplates?: boolean;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: async () => {
			let categories = await userSession.webServiceHelper.callAsync<string[]>(
				`template/${encodeURIComponent(industry)}/cardCategories`,
				'GET'
			);
			// @ts-ignore
			categories = categories.filter(c => c !== 'Archived' && c !== 'Uncategorized');
			if (withMyTemplates) {
				categories.unshift(KnownCategories.MyTemplates);
			}
			if (withAll) {
				categories.unshift(KnownCategories.All);
			}
			return categories;
		},
		queryKey: [QUERY_BASE_KEY, { industry }],
	});
};
