import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const INFINITE_SURVEYS_QUERY_KEY = 'infiniteSurveys';

export function useInfiniteSurveys<T = Api.ISurvey>({
	shouldIncludeArchived = false,
	pageSize = 100,
	impersonationContext,
	transformer,
}: {
	shouldIncludeArchived?: boolean;
	pageSize?: number;
	impersonationContext?: Api.IImpersonationContext;
	transformer?: (page: Api.IPagedCollection<Api.ISurvey>) => Api.IPagedCollection<T>;
}) {
	const userSession = useUserSession();
	return useInfiniteQuery<Api.IPagedCollection<T>>({
		getNextPageParam: lastPage => lastPage.pageToken,
		queryFn: async ({ pageParam }) => {
			const page = await userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.ISurvey>>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: { includeArchived: shouldIncludeArchived, pageSize, pageToken: pageParam },
					urlPath: `survey`,
				}),
				'GET',
				null
			);
			// @ts-ignore
			return transformer ? transformer(page) : (page as Api.IPagedCollection<T>);
		},
		queryKey: [INFINITE_SURVEYS_QUERY_KEY, pageSize, shouldIncludeArchived],
	});
}

export const invalidateInfiniteSurveys = () => {
	reactQueryClient.invalidateQueries([INFINITE_SURVEYS_QUERY_KEY]);
};
