import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useImageAttachmentByUrlMutation = ({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (result: Api.IFileAttachmentWithURL) => void;
}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({
			url,
			fileName,
			impersonationContext,
		}: { url: string; fileName?: string; impersonationContext?: Api.IImpersonationContext }) => {
			return userSession.webServiceHelper.callAsync<Api.IFileAttachmentWithURL>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: 'attachment/externalImage',
					impersonationContext,
				}),
				'POST',
				{
					url,
					fileName,
				}
			);
		},
		onError,
		onSuccess,
	});
};
