import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = [`telephony/text/settings`];

export function useTelephonyTextSettingsQuery({ cacheTime }: { cacheTime?: number } = {}) {
	const userSession = useUserSession();
	return useQuery({
		cacheTime,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.TextSettings>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `telephony/text/settings`,
				}),
				'GET'
			);
		},
		queryKey: BASE_QUERY_KEY,
	});
}
