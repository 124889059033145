import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { ITemplateCard, ImpersonationBroker } from '../../viewmodels/AppViewModels';

export function useEmailDeleteTemplate({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ template }: { template: ITemplateCard }) => {
			return userSession.webServiceHelper.callAsync(
				ImpersonationBroker.composeApiUrl({ urlPath: `template/${encodeURIComponent(template.id)}` }),
				'DELETE'
			);
		},
		onError,
		onSuccess,
	});
}
