import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useAddDonationCampaignMutation = ({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IDonationCampaign) => void;
} = {}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({
			donationCampaign,
			impersonationContext,
		}: {
			donationCampaign: Api.IDonationCampaign;
			impersonationContext?: Api.IImpersonationContext;
		}) => {
			return userSession.webServiceHelper.callAsync<Api.IDonationCampaign>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `donation/campaign`,
				}),
				'POST',
				donationCampaign
			);
		},
		onError,
		onSuccess,
	});
};
