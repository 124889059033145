import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { ITemplateCard } from '../../../viewmodels/AppViewModels';

interface IParams {
	statuses: Api.ITemplateStatus[];
	templateId: string;
}
export function useAdminBlogTemplateStatusesUpdate({
	onError,
	onSuccess,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ statuses, templateId }: IParams) => {
			const value = await userSession.webServiceHelper.callAsync<ITemplateCard>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `admin/template/industry/blogCategories/${templateId}/statuses`,
				}),
				'PUT',
				[...statuses]
			);
			return value;
		},
		onError,
		onSuccess,
	});
}
