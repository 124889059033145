import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_API_KEY = '/policy/summary';

const emptySummary = {
	totalPolicies: 0,
	totalAmount: 0,
	totalDonors: 0,
};

export const useInsurancePolicySummaryQuery = ({
	filterRequest,
	enabled,
	refetchOnWindowFocus = false,
}: {
	filterRequest: Api.IInsurancePolicyFilterRequest;
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: async () => {
			const value = await userSession.webServiceHelper.callAsync<Api.IInsurancePolicySummary>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `insurancePolicy/summary`,
				}),
				'POST',
				filterRequest
			);
			return value ?? emptySummary;
		},
		queryKey: [BASE_API_KEY, filterRequest],
		refetchOnWindowFocus,
	});
};

export const invalidateInsurancePolicySummary = ({
	filterRequest,
}: { filterRequest: Api.IInsurancePolicyFilterRequest }) => {
	reactQueryClient.invalidateQueries([BASE_API_KEY, filterRequest]);
};

export const invalidateAllInsurancePolicySummary = () => {
	reactQueryClient.invalidateQueries([BASE_API_KEY]);
};
