import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { ImpersonationBroker } from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export const useAddProviderPhoneNumberMutation = ({
	impersonationContext,
	onSuccess,
	onError,
}: {
	impersonationContext: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ phonenumber, scope }: { phonenumber: string; scope: string }) => {
			return userSession.webServiceHelper.callAsync(
				ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: {
						phoneNumber: phonenumber,
						scope,
					},
					urlPath: 'phoneNumber/importNumber',
				}),
				'POST'
			);
		},
		onError,
		onSuccess,
	});
};
