import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY = '/reports/campaign/<id>/content';

export function useCampaignReportContentQuery({ campaignId }: { campaignId: string }) {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () =>
			userSession.webServiceHelper.callAsync<Api.IRawRichTextContentState>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `reports/campaign/${encodeURIComponent(campaignId)}/content`,
				}),
				'GET'
			),
		queryKey: [BASE_QUERY, campaignId],
	});
}
