import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

const BASE_API_KEY = 'domainName/suggestedDomainName/newsletter';

export function useRequestNewHtmlNewsletterDomain({
	enabled = true,
	impersonationContext,
}: {
	impersonationContext: Api.IImpersonationContext;
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		queryKey: [BASE_API_KEY, impersonationContext],
		refetchOnWindowFocus: false,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<string>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `domainName/suggestedDomainName/newsletter`,
					impersonationContext,
				}),
				'GET'
			);
		},
		enabled,
	});
}
