import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_API_KEY = 'donation/advancedReportingUrl';

export function useDonationAdvancedReportingUrlQuery({
	enabled = true,
}: {
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () => userSession.webServiceHelper.callAsync<string>(`donation/advancedReportingUrl`, 'GET'),
		queryKey: [BASE_API_KEY],
	});
}
