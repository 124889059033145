import { useQuery } from '@tanstack/react-query';
import * as Api from '@ViewModels';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'hostedblog/preview/{page}';

export enum HostedBlogPreviewPage {
	Main = 'main',
	Individual = 'individual',
}

export const useHostedBlogPagePreviewQuery = ({
	page,
	impersonationContext,
	enabled,
	refetchOnWindowFocus,
}: {
	page: HostedBlogPreviewPage;
	impersonationContext?: Api.IImpersonationContext;
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		refetchOnWindowFocus,
		queryFn: async () => {
			return new Promise<string>((resolve, reject) => {
				userSession.webServiceHelper.callWebServiceRawResponse(
					Api.ImpersonationBroker.composeApiUrl({
						urlPath: `hostedblog/preview/${page}`,
						impersonationContext,
					}),
					'GET',
					null,
					async response => {
						resolve(await response.text());
					},
					error => {
						reject(error);
					}
				);
			});
		},
		queryKey: [BASE_QUERY_KEY, page, impersonationContext],
	});
};

export const invalidateHostedBlogPagePreview = (page: Api.CustomPageType) => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY, page]);
};
