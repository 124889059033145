import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useUpdateCustomPageStyleMutation({
	onError,
	onSuccess,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (val: string) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({
			id,
			style,
			impersonationContext,
		}: {
			id: string;
			style: string;
			impersonationContext?: Api.IImpersonationContext;
		}) => {
			return userSession.webServiceHelper.callAsync<string>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `CustomPageStyle/${id}`,
				}),
				'PUT',
				style
			);
		},
		onError,
		onSuccess,
	});
}
