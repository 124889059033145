import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

interface IVariables {
	campaignId: string;
	name?: string;
}

export function useEmailNameMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (campaign: Api.ICampaign, variables: IVariables) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		// @ts-ignore
		mutationFn: ({ campaignId, name = '' }: IVariables) => {
			return userSession.webServiceHelper.callAsync<Api.ICampaign>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `email/${encodeURIComponent(campaignId)}/name`,
					queryParams: {
						name,
					},
				}),
				'PUT'
			);
		},
		onError,
		onSuccess,
	});
}
