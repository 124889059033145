import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = '/automationTemplate/filter';

export const useAutomationTemplateFilterInfiniteQuery = ({
	filter,
	pageSize = 25,
}: {
	filter: Api.AutomationTemplateFilter;
	pageSize?: number;
}) => {
	const userSession = useUserSession();
	return useInfiniteQuery({
		queryFn: ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IAutomationTemplateReference>>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { pageSize, pageToken: pageParam },
					urlPath: `automationTemplate/filter`,
				}),
				'POST',
				filter
			);
		},
		getNextPageParam: lastPage => lastPage.pageToken,
		queryKey: [BASE_QUERY_KEY, filter, pageSize],
	});
};

export const invalidateAutomationTemplateFilter = () => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
};
