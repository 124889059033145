import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useUpdateSatisfactionSurveyCustomFormMutation = ({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data?: Api.SatisfactionSurveyViewModel) => void;
}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ request, id }: { request: Api.SatisfactionSurveyCustomFormRequest; id: string }) => {
			return userSession.webServiceHelper.callAsync<Api.SatisfactionSurveyViewModel>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `survey/satisfaction/${id}/customForm`,
				}),
				'PUT',
				request
			);
		},
		onError,
		onSuccess,
	});
};
