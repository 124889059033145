import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export function useUserPreferencesMutation({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IUserPreferences) => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		// @ts-ignore
		mutationFn: ({ preferences }: { preferences: Api.IUserPreferences }) => {
			return userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({ impersonationContext, urlPath: 'user/preferences' }),
				'PUT',
				preferences
			);
		},
		onError,
		onSuccess,
	});
}
