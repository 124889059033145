import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../../web/ReactQueryProvider';

export const CONVERSATIONS_QUERY_KEY = 'conversation/phoneNumber/{id}';

export const useConversationsInfiniteQuery = ({
	enabled = true,
	phoneNumberId,
	onError,
	pageSize = 25,
	refetchOnWindowFocus,
}: {
	enabled?: boolean;
	phoneNumberId: string;
	onError?: (error: Api.IOperationResultNoValue) => void;
	pageSize?: number;
	refetchOnWindowFocus?: boolean;
}) => {
	const userSession = useUserSession();
	return useInfiniteQuery<Api.IPagedCollection<Api.IConversation>>({
		// @ts-ignore
		enabled,
		getNextPageParam: lastPage => lastPage.pageToken,
		onError,
		queryFn: ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { pageToken: pageParam, pageSize },
					urlPath: `conversation/phoneNumber/${phoneNumberId}`,
				}),
				'GET'
			);
		},
		queryKey: [CONVERSATIONS_QUERY_KEY, phoneNumberId, pageSize],
		refetchOnWindowFocus,
	});
};

export function invalidateConversations(phoneNumberId: string) {
	reactQueryClient.invalidateQueries([CONVERSATIONS_QUERY_KEY, phoneNumberId]);
}
