import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { ContactSortKey } from '../../models';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_API_KEY = '/contact/resourceSelector/filter';

export const useContactResourceSelectorFilterQuery = ({
	expand,
	pageSize = 25,
	sort = 'asc',
	sortBy,
	filterRequest,
	resourceSelectorId,
}: {
	expand?: string;
	pageSize?: number;
	sort?: 'asc' | 'desc';
	sortBy: ContactSortKey;
	filterRequest: Api.IResourceSelectorContactsRequest;
	resourceSelectorId: Api.ResourceSelectorId;
}) => {
	const userSession = useUserSession();
	// @ts-ignore
	return useInfiniteQuery<Api.IPagedCollection<Api.IContact>>({
		getNextPageParam: lastPage => lastPage.pageToken,
		queryFn: ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { expand, pageSize, pageToken: pageParam, sort, sortBy },
					urlPath: `contact/resourceSelector/${resourceSelectorId}/filter`,
				}),
				'POST',
				filterRequest
			);
		},
		queryKey: [BASE_API_KEY, resourceSelectorId, filterRequest, sortBy, sort, pageSize].concat(expand ? expand : []),
	});
};
