import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { KnownCategories } from '../../viewmodels/AppViewModels';

const QUERY_BASE_KEY = 'template/industry/blogCategories';

export const useBlogCardsCategories = ({
	enabled = true,
	industry,
	withAll = false,
	withMyTemplates = false,
	impersonationContext,
	onSuccess,
}: {
	enabled?: boolean;
	industry: Api.Industry;
	withAll?: boolean;
	withMyTemplates?: boolean;
	impersonationContext?: Api.IImpersonationContext;
	onSuccess?: (categories: string[]) => void;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		onSuccess,
		queryFn: async () => {
			let categories = await userSession.webServiceHelper.callAsync<string[]>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `template/${encodeURIComponent(industry)}/blogCategories`,
					impersonationContext,
				}),
				'GET'
			);

			categories = categories.filter(c => c !== 'Archived' && c !== 'Uncategorized');
			if (withMyTemplates) {
				categories.unshift(KnownCategories.MyTemplates);
			}

			if (withAll) {
				categories.unshift(KnownCategories.All);
			}
			const account = impersonationContext?.account || userSession.account;
			if (account?.features?.blogFeature.enabled) {
				categories.splice(2, 0, KnownCategories.BlogPostDrafts);
			}
			return categories;
		},
		queryKey: [QUERY_BASE_KEY, industry, impersonationContext],
	});
};
