import { useQueries, useQuery } from '@tanstack/react-query';
import * as Api from '@ViewModels';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

interface Params {
	month: string;
	request: Api.IContentCalendarSuggestionRequest;
	impersonationContext?: Api.IImpersonationContext;
	enabled?: boolean;
}

const BASE_QUERY_KEY = 'ContentCalendarSuggestion/filter';

export const useContentCalendarSuggestionFilterQuery = (params: Params) => {
	const userSession = useUserSession();
	return useQuery({
		enabled: params.enabled,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IContentCalendarSuggestion[]>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `ContentCalendarSuggestion/filter`,
					queryParams: {
						month: params.month,
					},
					impersonationContext: params.impersonationContext,
				}),
				'POST',
				params.request
			);
		},
		queryKey: [BASE_QUERY_KEY, params.month, params.request, params.impersonationContext],
	});
};

export const useContentCalendarSuggestionFilterQueries = (params: Params[]) => {
	const userSession = useUserSession();
	return useQueries({
		queries: params.map(p => {
			return {
				enabled: p.enabled,
				queryFn: () => {
					return userSession.webServiceHelper.callAsync<Api.IContentCalendarSuggestion[]>(
						Api.ImpersonationBroker.composeApiUrl({
							urlPath: `ContentCalendarSuggestion/filter`,
							queryParams: {
								month: p.month,
							},
							impersonationContext: p.impersonationContext,
						}),
						'POST',
						p.request
					);
				},
				queryKey: [BASE_QUERY_KEY, p.month, p.request, p.impersonationContext],
			};
		}),
	});
};

export const invalidateAllContentCalendarSuggestions = () => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
};
