import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export function useRegisterUserMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IUser) => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		// @ts-ignore
		mutationFn: ({
			accountId,
			user,
			sendWelcomeEmail = false,
		}: {
			accountId: string;
			user: Partial<Api.IUser>;
			sendWelcomeEmail?: boolean;
		}) => {
			return userSession.webServiceHelper.callAsync<Api.IUser>(
				`user/register?sendWelcomeEmail=${sendWelcomeEmail}`,
				'POST',
				{
					...user,
					accountId,
					// @ts-ignore
					email: user.primaryEmail.value,
				}
			);
		},
		onError,
		onSuccess,
	});
}
