import { css } from 'aphrodite';
import { useState } from 'react';
import { white } from '../../../../styles/colors';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	height?: number;
	width?: number;
	textColor?: string;
}

export const LogoIcon2023 = ({ className, height = 52, width = 260, textColor = white }: IProps) => {
	const [isHovering, setIsHovering] = useState<boolean>(false);
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={`${className ?? ''}`}
			onMouseOver={() => setIsHovering(true)}
			onMouseOut={() => setIsHovering(false)}
		>
			<mask
				id='mask0_0_211'
				style={{ maskType: 'luminance' }}
				maskUnits='userSpaceOnUse'
				x='0'
				y='14'
				width='52'
				height='38'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M20.6743 14.7657L0 29.5334L31.0096 51.682L51.682 36.9162L20.6743 14.7657V14.7657Z'
					fill='#fff'
				/>
			</mask>
			<g mask='url(#mask0_0_211)'>
				<path d='M20.6743 14.7657L0 29.5334L31.0096 51.682L51.682 36.9162L20.6743 14.7657V14.7657Z' fill='#0066FF' />
				<path
					d='M31.0096 48.9075C28.5007 48.9075 26.0936 48.1375 24.0489 46.6797L-6.96457 24.5253C-10.1119 22.272 -11.9863 18.6256 -11.9863 14.7657C-11.9863 10.9058 -10.1119 7.25941 -6.97436 5.01399L13.7078 -9.75956C15.7545 -11.2212 18.1615 -11.9913 20.6724 -11.9913C23.1832 -11.9913 25.5883 -11.2212 27.633 -9.76348L58.6465 12.391C61.7939 14.6423 63.6682 18.2886 63.6682 22.1505C63.6682 26.0124 61.7939 29.6568 58.6563 31.9022L37.9741 46.6758C35.9275 48.1375 33.5204 48.9075 31.0096 48.9075Z'
					fill='#0066FF'
				/>
				<path
					d='M31.0096 48.7253C28.5379 48.7253 26.1681 47.967 24.1547 46.5308L-6.85879 24.3783C-9.95916 22.1583 -11.8041 18.5688 -11.8041 14.7676C-11.8041 10.9665 -9.95916 7.37498 -6.86859 5.16287L13.8136 -9.61068C15.8289 -11.0489 18.2007 -11.8091 20.6724 -11.8091C23.1441 -11.8091 25.5139 -11.0508 27.5273 -9.6146L58.5408 12.5398C61.6392 14.7559 63.4861 18.3474 63.4861 22.1505C63.4861 25.9536 61.6411 29.5431 58.5506 31.7553L37.8684 46.5288C35.8531 47.967 33.4813 48.7272 31.0096 48.7272V48.7253Z'
					fill='#0065FD'
				/>
				<path
					d='M31.0096 48.5431C28.5771 48.5431 26.2425 47.7965 24.2604 46.3819L-6.75305 24.2294C-9.80445 22.0447 -11.622 18.51 -11.622 14.7657C-11.622 11.0214 -9.80445 7.48866 -6.76284 5.30985L13.9193 -9.46174C15.9033 -10.8784 18.2379 -11.6268 20.6724 -11.6268C23.1069 -11.6268 25.4395 -10.8803 27.4215 -9.46566L58.435 12.6888C61.4864 14.8715 63.3039 18.4062 63.3039 22.1525C63.3039 25.8988 61.4864 29.4295 58.4448 31.6083L37.7626 46.3819C35.7786 47.7985 33.444 48.547 31.0096 48.547V48.5431Z'
					fill='#0065FB'
				/>
				<path
					d='M31.0096 48.3608C28.6143 48.3608 26.3169 47.6261 24.3662 46.2329L-6.64727 24.0805C-9.65167 21.9291 -11.4398 18.4512 -11.4398 14.7657C-11.4398 11.0801 -9.65167 7.60227 -6.65706 5.45873L14.0251 -9.31482C15.9778 -10.7099 18.2752 -11.4446 20.6724 -11.4446C23.0697 -11.4446 25.3651 -10.7099 27.3158 -9.31874L58.3293 12.8357C61.3317 14.9832 63.1218 18.4649 63.1218 22.1505C63.1218 25.836 61.3337 29.3139 58.3391 31.4574L37.6569 46.231C35.7042 47.626 33.4068 48.3608 31.0096 48.3608Z'
					fill='#0064F9'
				/>
				<path
					d='M31.0096 48.1786C28.6515 48.1786 26.3913 47.4556 24.472 46.086L-6.54153 23.9335C-9.49892 21.8174 -11.2577 18.3925 -11.2577 14.7677C-11.2577 11.1429 -9.49892 7.71791 -6.55132 5.60768L14.1309 -9.16587C16.0542 -10.5374 18.3143 -11.2643 20.6743 -11.2643C23.0344 -11.2643 25.2926 -10.5413 27.212 -9.17175L58.2254 12.9807C61.1809 15.0949 62.9416 18.5198 62.9416 22.1466C62.9416 25.7734 61.1828 29.1964 58.2352 31.3066L37.553 46.0802C35.6317 47.4517 33.3696 48.1767 31.0115 48.1767L31.0096 48.1786Z'
					fill='#0063F7'
				/>
				<path
					d='M31.0096 47.9964C28.6907 47.9964 26.4677 47.2851 24.5777 45.9371L-6.43575 23.7846C-9.34418 21.7018 -11.0755 18.3337 -11.0755 14.7676C-11.0755 11.2016 -9.34418 7.83151 -6.44554 5.75655L14.2386 -9.017C16.1286 -10.367 18.3535 -11.0802 20.6744 -11.0802C22.9952 -11.0802 25.2182 -10.369 27.1062 -9.02092L58.1197 13.1316C61.0281 15.2124 62.7595 18.5805 62.7595 22.1485C62.7595 25.7165 61.0281 29.0847 58.1295 31.1596L37.4473 45.9332C35.5573 47.2832 33.3324 47.9964 31.0115 47.9964H31.0096Z'
					fill='#0063F6'
				/>
				<path
					d='M31.0096 47.8142C28.7279 47.8142 26.5421 47.1147 24.6835 45.7882L-6.33 23.6357C-9.19143 21.5882 -10.8934 18.2749 -10.8934 14.7657C-10.8934 11.2565 -9.19143 7.94323 -6.33784 5.90158L14.3443 -8.87001C16.203 -10.1985 18.3926 -10.8979 20.6743 -10.8979C22.956 -10.8979 25.1418 -10.1985 27.0004 -8.87197L58.0139 13.2805C60.8734 15.3261 62.5773 18.6394 62.5773 22.1505C62.5773 25.6617 60.8753 28.973 58.0218 31.0147L37.3396 45.7882C35.479 47.1166 33.2913 47.8161 31.0096 47.8161V47.8142Z'
					fill='#0062F4'
				/>
				<path
					d='M31.0096 47.6319C28.7671 47.6319 26.6166 46.9442 24.7893 45.6412L-6.22423 23.4868C-9.03669 21.4726 -10.7112 18.2161 -10.7112 14.7657C-10.7112 11.3152 -9.03669 8.0588 -6.23206 6.05046L14.4501 -8.72113C16.2794 -10.0261 18.4299 -10.7158 20.6744 -10.7158C22.9188 -10.7158 25.0674 -10.028 26.8947 -8.72505L57.9082 13.4274C60.7206 15.4397 62.3952 18.6981 62.3952 22.1485C62.3952 25.599 60.7206 28.8554 57.916 30.8638L37.2338 45.6373C35.4045 46.9422 33.2541 47.6319 31.0096 47.6319Z'
					fill='#0061F2'
				/>
				<path
					d='M31.0096 47.4497C28.8042 47.4497 26.691 46.7738 24.895 45.4923L-6.11848 23.3399C-8.88394 21.3609 -10.5291 18.1574 -10.5291 14.7677C-10.5291 11.378 -8.88394 8.17443 -6.12632 6.20136L14.5559 -8.57219C16.3538 -9.85557 18.469 -10.5335 20.6743 -10.5335C22.8797 -10.5335 24.9929 -9.85753 26.7889 -8.57611L57.8024 13.5764C60.5659 15.5534 62.213 18.7569 62.213 22.1486C62.213 25.5402 60.5679 28.7418 57.8102 30.7149L37.128 45.4865C35.3301 46.7698 33.2149 47.4478 31.0096 47.4478V47.4497Z'
					fill='#0061F0'
				/>
				<path
					d='M31.0096 47.2675C28.8434 47.2675 26.7654 46.6033 25.0008 45.3434L-6.0127 23.1909C-8.7292 21.2453 -10.347 18.0985 -10.347 14.7657C-10.347 11.4328 -8.7292 8.28608 -6.02054 6.34632L14.6616 -8.42527C16.4282 -9.6871 18.5063 -10.3513 20.6744 -10.3513C22.8425 -10.3513 24.9185 -9.6871 26.6832 -8.42723L57.6966 13.7253C60.4131 15.6689 62.0309 18.8157 62.0309 22.1505C62.0309 25.4853 60.4131 28.6301 57.7045 30.5699L37.0243 45.3414C35.2577 46.6033 33.1796 47.2695 31.0115 47.2695L31.0096 47.2675Z'
					fill='#0060EE'
				/>
				<path
					d='M31.0096 47.0853C28.8806 47.0853 26.8398 46.4309 25.1065 45.1945L-5.90501 23.042C-8.5745 21.1317 -10.1629 18.0398 -10.1629 14.7657C-10.1629 11.4916 -8.5745 8.39976 -5.91284 6.49526L14.7674 -8.27633C16.5027 -9.51464 18.5454 -10.1691 20.6743 -10.1691C22.8033 -10.1691 24.8441 -9.51464 26.5774 -8.28025L57.5909 13.8722C60.2584 15.7807 61.8487 18.8745 61.8487 22.1486C61.8487 25.4227 60.2604 28.5145 57.5987 30.419L36.9185 45.1906C35.1832 46.4289 33.1404 47.0833 31.0115 47.0833L31.0096 47.0853Z'
					fill='#0060EC'
				/>
				<path
					d='M31.0096 46.903C28.9198 46.903 26.9143 46.2604 25.2123 45.0475L-5.79923 22.8951C-8.42171 21.018 -9.98071 17.9829 -9.98071 14.7676C-9.98071 11.5523 -8.41976 8.51533 -5.80706 6.6461L14.8732 -8.12745C16.5771 -9.34421 18.5826 -9.98688 20.6744 -9.98688C22.7661 -9.98688 24.7697 -9.34421 26.4716 -8.13137L57.4851 14.0211C60.1056 15.8962 61.6666 18.9332 61.6666 22.1485C61.6666 25.3638 60.1056 28.4008 57.493 30.2701L36.8127 45.0417C35.1088 46.2584 33.1033 46.9011 31.0115 46.9011L31.0096 46.903Z'
					fill='#005FEA'
				/>
				<path
					d='M31.0096 46.7209C28.957 46.7209 26.9906 46.09 25.318 44.8987L-5.69348 22.7462C-8.26701 20.9044 -9.79858 17.9222 -9.79858 14.7657C-9.79858 11.6092 -8.26701 8.62901 -5.70132 6.79113L14.9789 -7.98046C16.6515 -9.17567 18.6198 -9.80658 20.6743 -9.80658C22.7288 -9.80658 24.6933 -9.17567 26.3659 -7.98438L57.3793 14.1681C59.9529 16.0079 61.4844 18.9901 61.4844 22.1486C61.4844 25.3071 59.9529 28.2853 57.3872 30.1232L36.707 44.8947C35.0344 46.09 33.0641 46.7209 31.0115 46.7209H31.0096Z'
					fill='#005EE8'
				/>
				<path
					d='M31.0096 46.5386C28.9962 46.5386 27.0651 45.9195 25.4238 44.7497L-5.58771 22.5972C-8.11422 20.7887 -9.61642 17.8634 -9.61642 14.7657C-9.61642 11.6679 -8.11227 8.74262 -5.59554 6.94001L15.0847 -7.83158C16.7259 -9.00328 18.659 -9.62244 20.6744 -9.62244C22.6897 -9.62244 24.6189 -9.00328 26.2601 -7.83354L57.2736 14.3189C59.7982 16.1255 61.3023 19.0508 61.3023 22.1505C61.3023 25.2502 59.7982 28.1736 57.2814 29.9762L36.6012 44.7478C34.9599 45.9195 33.0269 46.5386 31.0115 46.5386H31.0096Z'
					fill='#005EE6'
				/>
				<path
					d='M31.0096 46.3564C29.0334 46.3564 27.1395 45.749 25.5296 44.6028L-5.48391 22.4503C-7.96147 20.6771 -9.43625 17.8066 -9.43625 14.7677C-9.43625 11.7287 -7.96147 8.85825 -5.48979 7.09091L15.1904 -7.68264C16.8004 -8.83278 18.6962 -9.44019 20.6743 -9.44019C22.6525 -9.44019 24.5444 -8.83278 26.1543 -7.68656L57.1678 14.4659C59.6454 16.2372 61.1202 19.1096 61.1202 22.1486C61.1202 25.1875 59.6454 28.058 57.1737 29.8253L36.4935 44.5969C34.8816 45.7471 32.9877 46.3545 31.0096 46.3545V46.3564Z'
					fill='#005DE5'
				/>
				<path
					d='M31.0096 46.1742C29.0726 46.1742 27.2139 45.5785 25.6353 44.4539L-5.37814 22.3014C-7.80869 20.5615 -9.25409 17.7478 -9.25409 14.7676C-9.25409 11.7875 -7.80673 8.97186 -5.38401 7.23783L15.2962 -7.53376C16.8768 -8.66235 18.7354 -9.258 20.6744 -9.258C22.6133 -9.258 24.47 -8.66235 26.0486 -7.53768L57.0621 14.6148C59.4907 16.3528 60.938 19.1684 60.938 22.1485C60.938 25.1287 59.4907 27.9443 57.068 29.6783L36.3877 44.4499C34.8072 45.5785 32.9485 46.1742 31.0096 46.1742Z'
					fill='#005CE3'
				/>
				<path
					d='M31.0096 45.992C29.1098 45.992 27.2883 45.4081 25.7411 44.305L-5.27239 22.1525C-7.65398 20.4478 -9.07196 17.6871 -9.07196 14.7657C-9.07196 11.8443 -7.65398 9.08554 -5.27827 7.38481L15.402 -7.38678C16.9512 -8.49186 18.7726 -9.07574 20.6743 -9.07574C22.5761 -9.07574 24.3956 -8.49186 25.9428 -7.38874L56.9563 14.7638C59.3379 16.4664 60.7559 19.2272 60.7559 22.1505C60.7559 25.0739 59.3379 27.8307 56.9622 29.5314L36.282 44.303C34.7327 45.4081 32.9113 45.992 31.0096 45.992V45.992Z'
					fill='#005CE1'
				/>
				<path
					d='M31.0096 45.8097C29.1489 45.8097 27.3627 45.2376 25.8468 44.156L-5.16664 22.0035C-7.50122 20.3322 -8.89178 17.6283 -8.89178 14.7657C-8.89178 11.9031 -7.50318 9.19915 -5.17448 7.53369L15.5077 -7.2379C17.0256 -8.32142 18.8118 -8.89355 20.6743 -8.89355C22.5369 -8.89355 24.3211 -8.32142 25.837 -7.23986L56.8505 14.9126C59.1851 16.582 60.5757 19.2859 60.5757 22.1505C60.5757 25.0151 59.1871 27.717 56.8584 29.3825L36.1781 44.1541C34.6603 45.2376 32.8741 45.8097 31.0115 45.8097H31.0096Z'
					fill='#005BDF'
				/>
				<path
					d='M31.0096 45.6275C29.1862 45.6275 27.4372 45.0672 25.9526 44.0091L-5.06086 21.8566C-7.34844 20.2206 -8.70963 17.5715 -8.70963 14.7677C-8.70963 11.9638 -7.34844 9.31478 -5.0687 7.68264L15.6135 -7.08896C17.1 -8.15093 18.849 -8.7113 20.6724 -8.7113C22.4958 -8.7113 24.2448 -8.15093 25.7293 -7.09287L56.7428 15.0596C59.0284 16.6957 60.3916 19.3447 60.3916 22.1486C60.3916 24.9524 59.0304 27.6015 56.7507 29.2336L36.0704 44.0052C34.5839 45.0672 32.8349 45.6275 31.0115 45.6275H31.0096Z'
					fill='#005ADD'
				/>
				<path
					d='M31.0096 45.4453C29.2253 45.4453 27.5136 44.8967 26.0584 43.8602L-4.95512 21.7077C-7.19373 20.1049 -8.5275 17.5127 -8.5275 14.7657C-8.5275 12.0187 -7.19569 9.42643 -4.96295 7.82956L15.7192 -6.94203C17.1744 -7.98049 18.8881 -8.52911 20.6724 -8.52911C22.4566 -8.52911 24.1684 -7.98049 25.6236 -6.94399L56.6371 15.2085C58.8757 16.8093 60.2094 19.4035 60.2094 22.1505C60.2094 24.8975 58.8776 27.4897 56.6449 29.0866L35.9647 43.8582C34.5095 44.8967 32.7957 45.4453 31.0115 45.4453H31.0096Z'
					fill='#005ADB'
				/>
				<path
					d='M31.0096 45.2631C29.2625 45.2631 27.588 44.7262 26.1641 43.7113L-4.84934 21.5588C-7.04095 19.9913 -8.34534 17.452 -8.34534 14.7657C-8.34534 12.0794 -7.04095 9.54011 -4.85718 7.97654L15.825 -6.79309C17.2489 -7.81 18.9254 -8.34686 20.6724 -8.34686C22.4194 -8.34686 24.094 -7.81 25.5178 -6.79505L56.5313 15.3574C58.721 16.9249 60.0273 19.4623 60.0273 22.1505C60.0273 24.8388 58.7229 27.3761 56.5391 28.9397L35.8589 43.7113C34.4351 44.7282 32.7585 45.2651 31.0115 45.2651L31.0096 45.2631Z'
					fill='#0059D9'
				/>
				<path
					d='M31.0096 45.0809C29.3017 45.0809 27.6624 44.5557 26.2699 43.5643L-4.7436 21.4118C-6.88624 19.8776 -8.16321 17.3951 -8.16321 14.7676C-8.16321 12.1401 -6.8882 9.65764 -4.75143 8.12738L15.9308 -6.64421C17.3233 -7.63957 18.9626 -8.16467 20.6724 -8.16467C22.3822 -8.16467 24.0195 -7.63957 25.412 -6.64813L56.4255 15.5044C58.5682 17.0366 59.8451 19.5191 59.8451 22.1485C59.8451 24.778 58.5701 27.2585 56.4334 28.7888L35.7531 43.5604C34.3606 44.5557 32.7194 45.0809 31.0115 45.0809H31.0096Z'
					fill='#0058D7'
				/>
				<path
					d='M31.0096 44.8987C29.3389 44.8987 27.7368 44.3853 26.3757 43.4154L-4.63782 21.2629C-6.73346 19.7621 -7.98105 17.3364 -7.98105 14.7657C-7.98105 12.195 -6.73346 9.76935 -4.64565 8.27436L16.0365 -6.49723C17.3977 -7.46907 19.0018 -7.98242 20.6724 -7.98242C22.343 -7.98242 23.9451 -7.46907 25.3063 -6.49919L56.3198 15.6533C58.4135 17.1522 59.663 19.5799 59.663 22.1505C59.663 24.7212 58.4154 27.1469 56.3276 28.6419L35.6493 43.4135C34.2862 44.3853 32.6841 44.8987 31.0135 44.8987H31.0096Z'
					fill='#0058D5'
				/>
				<path
					d='M31.0096 44.7164C29.3781 44.7164 27.8113 44.2148 26.4814 43.2665L-4.53207 21.114C-6.57875 19.6484 -7.79892 17.2776 -7.79892 14.7657C-7.79892 12.2538 -6.58071 9.88296 -4.53795 8.42128L16.1423 -6.34835C17.4741 -7.29864 19.039 -7.80023 20.6724 -7.80023C22.3058 -7.80023 23.8707 -7.29864 25.2005 -6.35031L56.214 15.8022C58.2607 17.2658 59.4809 19.6386 59.4809 22.1505C59.4809 24.6624 58.2626 27.0332 56.2199 28.4949L35.5416 43.2665C34.2098 44.2168 32.6449 44.7184 31.0115 44.7184L31.0096 44.7164Z'
					fill='#0057D3'
				/>
				<path
					d='M31.0096 44.5342C29.4153 44.5342 27.8857 44.0444 26.5872 43.1176L-4.4263 20.9651C-6.42597 19.5328 -7.61676 17.2169 -7.61676 14.7657C-7.61676 12.3146 -6.42597 9.99664 -4.43217 8.57023L16.2481 -6.19941C17.5485 -7.12814 19.0781 -7.61798 20.6724 -7.61798C22.2666 -7.61798 23.7963 -7.12814 25.0948 -6.20137L56.1063 15.9511C58.106 17.3815 59.2968 19.6974 59.2968 22.1505C59.2968 24.6036 58.106 26.9196 56.1122 28.346L35.4339 43.1176C34.1334 44.0463 32.6038 44.5362 31.0096 44.5362V44.5342Z'
					fill='#0056D2'
				/>
				<path
					d='M31.0096 44.352C29.4545 44.352 27.9621 43.8739 26.6929 42.9706L-4.32055 20.8181C-6.27322 19.4211 -7.43463 17.16 -7.43463 14.7657C-7.43463 12.3713 -6.27322 10.1122 -4.32643 8.7191L16.3538 -6.05053C17.6229 -6.95575 19.1153 -7.43384 20.6724 -7.43384C22.2294 -7.43384 23.7199 -6.95575 24.989 -6.05249L56.0005 16.1C57.9512 17.4951 59.1146 19.7562 59.1146 22.1525C59.1146 24.5487 57.9532 26.8059 56.0064 28.199L35.3281 42.9706C34.059 43.8758 32.5666 44.3559 31.0096 44.3559V44.352Z'
					fill='#0056D0'
				/>
				<path
					d='M31.0096 44.1698C29.4917 44.1698 28.0365 43.7035 26.7987 42.8217L-4.21282 20.6693C-6.11652 19.3055 -7.25052 17.1013 -7.25052 14.7657C-7.25052 12.4302 -6.11652 10.2259 -4.2187 8.86609L16.4596 -5.90354C17.6974 -6.78721 19.1545 -7.25354 20.6724 -7.25354C22.1903 -7.25354 23.6454 -6.78721 24.8832 -5.9055L55.8948 16.247C57.7985 17.6087 58.9325 19.815 58.9325 22.1505C58.9325 24.4861 57.7985 26.6904 55.9007 28.0502L35.2224 42.8198C33.9846 43.7035 32.5274 44.1698 31.0096 44.1698V44.1698Z'
					fill='#0055CE'
				/>
				<path
					d='M31.0096 43.9875C29.5309 43.9875 28.1109 43.533 26.9045 42.6728L-4.10705 20.5203C-5.96374 19.1919 -7.06836 17.0425 -7.06836 14.7657C-7.06836 12.4889 -5.96374 10.3395 -4.11292 9.01497L16.5673 -5.75466C17.7738 -6.61678 19.1937 -7.07135 20.6744 -7.07135C22.155 -7.07135 23.573 -6.61678 24.7795 -5.75662L55.791 16.3959C57.6457 17.7224 58.7523 19.8737 58.7523 22.1505C58.7523 24.4273 57.6477 26.5767 55.7969 27.9012L35.1186 42.6708C33.9121 43.533 32.4922 43.9875 31.0115 43.9875H31.0096Z'
					fill='#0055CC'
				/>
				<path
					d='M31.0096 43.8053C29.5681 43.8053 28.1853 43.3625 27.0102 42.5259L-4.0013 20.3734C-5.80903 19.0783 -6.88623 16.9837 -6.88623 14.7677C-6.88623 12.5516 -5.80903 10.4551 -4.00718 9.16587L16.673 -5.60572C17.8482 -6.44433 19.2329 -6.8891 20.6743 -6.8891C22.1158 -6.8891 23.4986 -6.44628 24.6737 -5.60964L55.6852 16.5429C57.4929 17.836 58.5701 19.9306 58.5701 22.1486C58.5701 24.3666 57.4929 26.4611 55.6911 27.7504L35.0128 42.52C33.8377 43.3586 32.453 43.8034 31.0115 43.8034L31.0096 43.8053Z'
					fill='#0054CA'
				/>
				<path
					d='M31.0096 43.6231C29.6053 43.6231 28.2598 43.192 27.116 42.3769L-3.89552 20.2245C-5.65625 18.9646 -6.70407 16.9249 -6.70407 14.7657C-6.70407 12.6065 -5.65625 10.5668 -3.9014 9.31083L16.7788 -5.4588C17.9226 -6.27585 19.2701 -6.70691 20.6744 -6.70691C22.0786 -6.70691 23.4242 -6.27585 24.5679 -5.46076L55.5795 16.6917C57.3402 17.9516 58.388 19.9893 58.388 22.1505C58.388 24.3117 57.3402 26.3494 55.5853 27.6054L34.9071 42.375C33.7633 43.192 32.4158 43.6231 31.0115 43.6231H31.0096Z'
					fill='#0053C8'
				/>
				<path
					d='M31.0096 43.4409C29.6445 43.4409 28.3342 43.0216 27.2217 42.2281L-3.78978 20.0756C-5.50154 18.849 -6.52194 16.8661 -6.52194 14.7657C-6.52194 12.6653 -5.5035 10.6824 -3.79565 9.45978L16.8846 -5.30986C17.999 -6.10536 19.3073 -6.52466 20.6743 -6.52466C22.0414 -6.52466 23.3497 -6.10536 24.4622 -5.31182L55.4737 16.8407C57.1855 18.0653 58.2058 20.0501 58.2058 22.1505C58.2058 24.251 57.1874 26.2338 55.4796 27.4565L34.8013 42.2261C33.6869 43.0216 32.3766 43.4409 31.0115 43.4409H31.0096Z'
					fill='#0053C6'
				/>
				<path
					d='M31.0096 43.2587C29.6817 43.2587 28.4086 42.8511 27.3295 42.0791L-3.68205 19.9266C-5.34681 18.7353 -6.33783 16.8073 -6.33783 14.7657C-6.33783 12.724 -5.34681 10.796 -3.68792 9.6067L16.9903 -5.16098C18.0734 -5.93296 19.3465 -6.34247 20.6744 -6.34247C22.0022 -6.34247 23.2753 -5.93492 24.3545 -5.16294L55.366 16.9896C57.0307 18.1808 58.0218 20.1088 58.0218 22.1505C58.0218 24.1921 57.0307 26.1202 55.3719 27.3095L34.6936 42.0791C33.6105 42.8511 32.3375 43.2606 31.0096 43.2606V43.2587Z'
					fill='#0052C4'
				/>
				<path
					d='M31.0096 43.0765C29.7208 43.0765 28.485 42.6807 27.4352 41.9322L-3.5763 19.7797C-5.19406 18.6217 -6.1557 16.7486 -6.1557 14.7657C-6.1557 12.7828 -5.19406 10.9097 -3.58022 9.75564L17.0961 -5.01399C18.1478 -5.76443 19.3837 -6.16022 20.6743 -6.16022C21.965 -6.16022 23.1989 -5.76443 24.2487 -5.01595L55.2602 17.1365C56.876 18.2926 57.8396 20.1657 57.8396 22.1505C57.8396 24.1354 56.878 26.0065 55.2641 27.1606L34.5858 41.9302C33.5341 42.6807 32.2983 43.0765 31.0076 43.0765H31.0096Z'
					fill='#0051C2'
				/>
				<path
					d='M31.0096 42.8942C29.7581 42.8942 28.5594 42.5102 27.541 41.7833L-3.47053 19.6308C-5.03932 18.5081 -5.97354 16.6898 -5.97354 14.7657C-5.97354 12.8416 -5.03932 11.0233 -3.47444 9.90452L17.2038 -4.86511C18.2242 -5.59399 19.4248 -5.97803 20.6763 -5.97803C21.9278 -5.97803 23.1264 -5.59399 24.1449 -4.86707L55.1564 17.2854C56.7252 18.4081 57.6594 20.2245 57.6594 22.1505C57.6594 24.0765 56.7252 25.8929 55.1603 27.0117L34.4821 41.7813C33.4617 42.5102 32.2611 42.8942 31.0096 42.8942Z'
					fill='#0051C1'
				/>
				<path
					d='M31.0096 42.712C29.7972 42.712 28.6338 42.3398 27.6467 41.6344L-3.36478 19.4819C-4.88657 18.3925 -5.79141 16.631 -5.79141 14.7657C-5.79141 12.9004 -4.88657 11.139 -3.3687 10.0515L17.3096 -4.71812C18.2986 -5.42349 19.462 -5.79773 20.6763 -5.79773C21.8906 -5.79773 23.052 -5.42545 24.0391 -4.72008L55.0506 17.4324C56.5724 18.5198 57.4773 20.2833 57.4773 22.1486C57.4773 24.0139 56.5724 25.7753 55.0546 26.8628L34.3763 41.6324C33.3872 42.3378 32.2239 42.712 31.0096 42.712Z'
					fill='#0050BF'
				/>
				<path
					d='M31.0096 42.5298C29.8344 42.5298 28.7083 42.1692 27.7525 41.4874L-3.259 19.3369C-4.73183 18.2827 -5.60925 16.5761 -5.60925 14.7676C-5.60925 12.9592 -4.73183 11.2545 -3.26292 10.2023L17.4153 -4.5673C18.3731 -5.25111 19.5012 -5.61163 20.6763 -5.61163C21.8514 -5.61163 22.9776 -5.25111 23.9334 -4.56926L54.9449 17.5832C56.4177 18.6374 57.2951 20.344 57.2951 22.1525C57.2951 23.9609 56.4177 25.6656 54.9488 26.7178L34.2725 41.4874C33.3148 42.1712 32.1867 42.5317 31.0115 42.5317L31.0096 42.5298Z'
					fill='#004FBD'
				/>
				<path
					d='M31.0096 42.3476C29.8736 42.3476 28.7827 41.9988 27.8583 41.3385L-3.15326 19.188C-4.57908 18.1672 -5.42712 16.5154 -5.42712 14.7677C-5.42712 13.0199 -4.57908 11.3682 -3.15718 10.3513L17.5191 -4.41835C18.4455 -5.08062 19.5364 -5.42938 20.6743 -5.42938C21.8123 -5.42938 22.9012 -5.08062 23.8256 -4.42031L54.8372 17.7322C56.263 18.751 57.111 20.4028 57.111 22.1525C57.111 23.9022 56.263 25.552 54.8411 26.5689L34.1648 41.3385C33.2384 42.0008 32.1475 42.3495 31.0096 42.3495V42.3476Z'
					fill='#004FBB'
				/>
				<path
					d='M31.0096 42.1653C29.9108 42.1653 28.8571 41.8283 27.964 41.1896L-3.04748 19.039C-4.42629 18.0535 -5.24496 16.4566 -5.24496 14.7676C-5.24496 13.0787 -4.42434 11.4818 -3.0514 10.4982L17.6249 -4.27143C18.52 -4.91018 19.5756 -5.24915 20.6744 -5.24915C21.7731 -5.24915 22.8268 -4.91214 23.7199 -4.27339L54.7314 17.8771C56.1083 18.8627 56.9289 20.4576 56.9289 22.1485C56.9289 23.8395 56.1083 25.4344 54.7353 26.418L34.059 41.1876C33.164 41.8264 32.1083 42.1653 31.0096 42.1653V42.1653Z'
					fill='#004EB9'
				/>
				<path
					d='M31.0096 41.9831C29.95 41.9831 28.9335 41.6579 28.0698 41.0407L-2.94174 18.8902C-4.27159 17.9379 -5.06284 16.3979 -5.06284 14.7657C-5.06284 13.1336 -4.27159 11.5935 -2.94565 10.6452L17.7307 -4.12444C18.5963 -4.74164 19.6128 -5.06689 20.6743 -5.06689C21.7359 -5.06689 22.7524 -4.74164 23.6141 -4.12444L54.6256 18.0261C55.9555 18.9764 56.7467 20.5184 56.7467 22.1505C56.7467 23.7827 55.9555 25.3227 54.6296 26.2711L33.9532 41.0407C33.0876 41.6579 32.0711 41.9831 31.0096 41.9831V41.9831Z'
					fill='#004DB7'
				/>
				<path
					d='M31.0096 41.8009C29.9872 41.8009 29.0079 41.4874 28.1756 40.8937L-2.83596 18.7432C-4.1188 17.8262 -4.88068 16.341 -4.88068 14.7676C-4.88068 13.1943 -4.11685 11.711 -2.83988 10.796L17.8364 -3.97361C18.6708 -4.56925 19.652 -4.88275 20.6744 -4.88275C21.6967 -4.88275 22.676 -4.56925 23.5084 -3.97557L54.5199 18.175C55.8008 19.0919 56.5646 20.5771 56.5646 22.1505C56.5646 23.7239 55.8008 25.2071 54.5238 26.1221L33.8475 40.8917C33.0132 41.4874 32.0319 41.8009 31.0096 41.8009Z'
					fill='#004DB5'
				/>
				<path
					d='M31.0096 41.6187C30.0264 41.6187 29.0824 41.317 28.2813 40.7448L-2.73021 18.5943C-3.96409 17.7106 -4.69855 16.2823 -4.69855 14.7677C-4.69855 13.2531 -3.96409 11.8247 -2.73413 10.943L17.9422 -3.82662C18.7452 -4.39875 19.6892 -4.70245 20.6743 -4.70245C21.6595 -4.70245 22.6015 -4.40071 23.4026 -3.82858L54.4141 18.3219C55.648 19.2037 56.3824 20.634 56.3824 22.1486C56.3824 23.6632 55.648 25.0915 54.418 25.9732L33.7417 40.7429C32.9387 41.315 31.9947 41.6187 31.0096 41.6187V41.6187Z'
					fill='#004CB3'
				/>
				<path
					d='M31.0096 41.4364C30.0636 41.4364 29.1568 41.1465 28.3871 40.5959L-2.62444 18.4454C-3.81131 17.597 -4.51639 16.2215 -4.51639 14.7676C-4.51639 13.3138 -3.80935 11.9383 -2.6264 11.0919L18.0499 -3.67775C18.8216 -4.22833 19.7284 -4.51831 20.6744 -4.51831C21.6203 -4.51831 22.5271 -4.22833 23.2968 -3.67775L54.3084 18.4728C55.4952 19.3212 56.2003 20.6947 56.2003 22.1505C56.2003 23.6063 55.4933 24.9798 54.3103 25.8263L33.634 40.5959C32.8623 41.1465 31.9555 41.4364 31.0096 41.4364V41.4364Z'
					fill='#004CB1'
				/>
				<path
					d='M31.0096 41.2543C30.1028 41.2543 29.2312 40.976 28.4928 40.449L-2.51869 18.2984C-3.6566 17.4833 -4.33426 16.1647 -4.33426 14.7677C-4.33426 13.3706 -3.6566 12.052 -2.52065 11.2408L18.1557 -3.5288C18.896 -4.05783 19.7675 -4.33606 20.6743 -4.33606C21.5811 -4.33606 22.4527 -4.05783 23.1911 -3.53076L54.2026 18.6198C55.3405 19.4349 56.0182 20.7535 56.0182 22.1505C56.0182 23.5475 55.3405 24.8662 54.2045 25.6774L33.5282 40.447C32.7879 40.976 31.9164 41.2543 31.0096 41.2543V41.2543Z'
					fill='#004BB0'
				/>
				<path
					d='M31.0096 41.072C30.14 41.072 29.3056 40.8055 28.5986 40.3L-2.41292 18.1495C-3.50382 17.3677 -4.1521 16.1059 -4.1521 14.7676C-4.1521 13.4294 -3.50186 12.1656 -2.41487 11.3878L18.2614 -3.38188C18.9704 -3.88739 19.8048 -4.15582 20.6744 -4.15582C21.5439 -4.15582 22.3783 -3.88935 23.0853 -3.38384L54.0968 18.7667C55.1878 19.5465 55.836 20.8103 55.836 22.1485C55.836 23.4868 55.1858 24.7506 54.0988 25.5284L33.4225 40.2981C32.7135 40.8036 31.8792 41.072 31.0096 41.072V41.072Z'
					fill='#004AAE'
				/>
				<path
					d='M31.0096 40.8898C30.1791 40.8898 29.38 40.6351 28.7044 40.1511L-2.30717 18.0006C-3.35107 17.2541 -3.96997 16.0471 -3.96997 14.7677C-3.96997 13.4882 -3.34911 12.2812 -2.30913 11.5367L18.3672 -3.23294C19.0448 -3.7169 19.8439 -3.97162 20.6743 -3.97162C21.5048 -3.97162 22.3038 -3.7169 22.9795 -3.23294L53.9911 18.9176C55.033 19.6641 55.6539 20.8711 55.6539 22.1505C55.6539 23.43 55.033 24.637 53.993 25.3815L33.3167 40.1511C32.6391 40.6351 31.84 40.8918 31.0096 40.8918V40.8898Z'
					fill='#004AAC'
				/>
				<path
					d='M31.0096 40.7076C30.2164 40.7076 29.4565 40.4646 28.8101 40.0022L-2.20139 17.8517C-3.19633 17.1385 -3.78976 15.9864 -3.78976 14.7657C-3.78976 13.545 -3.19829 12.3929 -2.20531 11.6817L18.471 -3.08797C19.1173 -3.55037 19.8792 -3.79333 20.6724 -3.79333C21.4656 -3.79333 22.2255 -3.55037 22.8718 -3.08797L53.8834 19.0626C54.8783 19.7738 55.4717 20.9259 55.4717 22.1486C55.4717 23.3712 54.8803 24.5213 53.8873 25.2326L33.211 40.0003C32.5647 40.4627 31.8028 40.7056 31.0096 40.7056V40.7076Z'
					fill='#0049AA'
				/>
				<path
					d='M31.0096 40.5254C30.2555 40.5254 29.5309 40.2942 28.9159 39.8553L-2.09564 17.7047C-3.04358 17.0268 -3.60764 15.9296 -3.60764 14.7677C-3.60764 13.6058 -3.04358 12.5085 -2.09956 11.8326L18.5767 -2.93512C19.1937 -3.37401 19.9183 -3.60718 20.6724 -3.60718C21.4264 -3.60718 22.1511 -3.37597 22.7661 -2.93708L53.7776 19.2135C54.7255 19.8914 55.2896 20.9886 55.2896 22.1505C55.2896 23.3124 54.7255 24.4097 53.7815 25.0856L33.1052 39.8533C32.4883 40.2942 31.7636 40.5254 31.0096 40.5254Z'
					fill='#0048A8'
				/>
				<path
					d='M31.0096 40.3431C30.2927 40.3431 29.6053 40.1237 29.0217 39.7063L-1.98987 17.5558C-2.88884 16.9112 -3.42548 15.8708 -3.42548 14.7676C-3.42548 13.6645 -2.89079 12.6221 -1.99378 11.9814L18.6825 -2.78624C19.2681 -3.20358 19.9556 -3.42499 20.6724 -3.42499C21.3892 -3.42499 22.0767 -3.20554 22.6603 -2.7882L53.6718 19.3623C54.5708 20.005 55.1075 21.0474 55.1075 22.1505C55.1075 23.2536 54.5728 24.296 53.6758 24.9367L32.9995 39.7044C32.4139 40.1217 31.7264 40.3431 31.0096 40.3431Z'
					fill='#0048A6'
				/>
				<path
					d='M31.0096 40.1609C30.3319 40.1609 29.6797 39.9533 29.1274 39.5575L-1.88412 17.4069C-2.73609 16.7976 -3.24335 15.812 -3.24335 14.7657C-3.24335 13.7194 -2.73609 12.7358 -1.88804 12.1265L18.7883 -2.64121C19.3425 -3.037 19.9947 -3.24469 20.6724 -3.24469C21.35 -3.24469 22.0022 -3.037 22.5545 -2.64121L53.5641 19.5093C54.4161 20.1187 54.9233 21.1042 54.9233 22.1505C54.9233 23.1968 54.4161 24.1804 53.568 24.7898L32.8937 39.5575C32.3394 39.9533 31.6872 40.1609 31.0096 40.1609Z'
					fill='#0047A4'
				/>
				<path
					d='M31.0096 39.9787C30.3691 39.9787 29.7542 39.7828 29.2332 39.4105L-1.77834 17.2599C-2.58135 16.6839 -3.06119 15.7532 -3.06119 14.7676C-3.06119 13.7821 -2.5833 12.8514 -1.78226 12.2773L18.8921 -2.49037C19.415 -2.86461 20.03 -3.06055 20.6704 -3.06055C21.3109 -3.06055 21.9259 -2.86461 22.4468 -2.49233L53.4564 19.6582C54.2594 20.2323 54.7393 21.1649 54.7393 22.1505C54.7393 23.1361 54.2614 24.0667 53.4603 24.6408L32.786 39.4085C32.263 39.7828 31.6481 39.9787 31.0076 39.9787H31.0096Z'
					fill='#0046A2'
				/>
				<path
					d='M31.0096 39.7965C30.4083 39.7965 29.8286 39.6123 29.3389 39.2616L-1.67064 17.1111C-2.42664 16.5703 -2.87711 15.6944 -2.87711 14.7677C-2.87711 13.8409 -2.42664 12.9651 -1.6726 12.4262L19.0017 -2.34143C19.4933 -2.69216 20.0711 -2.8783 20.6743 -2.8783C21.2776 -2.8783 21.8553 -2.69412 22.345 -2.34339L53.3545 19.8071C54.1105 20.3479 54.561 21.2238 54.561 22.1505C54.561 23.0773 54.1105 23.9531 53.3565 24.492L32.6821 39.2596C32.1906 39.6104 31.6128 39.7965 31.0096 39.7965V39.7965Z'
					fill='#0046A0'
				/>
				<path
					d='M31.0096 39.6142C30.4455 39.6142 29.903 39.4418 29.4447 39.1127L-1.56487 16.9621C-2.27386 16.4546 -2.69495 15.6356 -2.69495 14.7676C-2.69495 13.8996 -2.27386 13.0787 -1.56683 12.5732L19.1075 -2.19451C19.5678 -2.52368 20.1103 -2.69611 20.6744 -2.69611C21.2384 -2.69611 21.7809 -2.52368 22.2392 -2.19451L53.2488 19.956C53.9558 20.4615 54.3789 21.2825 54.3789 22.1505C54.3789 23.0185 53.9578 23.8395 53.2508 24.345L32.5764 39.1127C32.1161 39.4418 31.5736 39.6142 31.0096 39.6142Z'
					fill='#00459E'
				/>
				<path
					d='M31.0096 39.4321C30.4847 39.4321 29.9794 39.2714 29.5504 38.9638L-1.45912 16.8132C-2.11915 16.341 -2.51282 15.5769 -2.51282 14.7657C-2.51282 13.9545 -2.11915 13.1904 -1.46108 12.7202L19.2133 -2.04752C19.6422 -2.35514 20.1475 -2.51581 20.6743 -2.51581C21.2012 -2.51581 21.7045 -2.35514 22.1335 -2.04752L53.143 20.103C53.803 20.5752 54.1967 21.3394 54.1967 22.1505C54.1967 22.9617 53.803 23.7259 53.145 24.1961L32.4706 38.9638C32.0417 39.2714 31.5364 39.4321 31.0096 39.4321Z'
					fill='#00449D'
				/>
				<path
					d='M31.0096 39.2498C30.5219 39.2498 30.0538 39.1009 29.6562 38.8168L-1.35335 16.6663C-1.96637 16.2274 -2.33066 15.5181 -2.33066 14.7676C-2.33066 14.0172 -1.96637 13.3079 -1.3553 12.871L19.319 -1.89669C19.7166 -2.18079 20.1867 -2.33167 20.6744 -2.33167C21.162 -2.33167 21.6301 -2.18275 22.0277 -1.89865L53.0373 20.2519C53.6503 20.6908 54.0146 21.4001 54.0146 22.1505C54.0146 22.9009 53.6503 23.6102 53.0392 24.0472L32.3649 38.8148C31.9673 39.0989 31.4972 39.2498 31.0096 39.2498Z'
					fill='#00449B'
				/>
				<path
					d='M31.0096 39.0676C30.5591 39.0676 30.1282 38.9305 29.762 38.6679L-1.2476 16.5174C-1.81166 16.1138 -2.14853 15.4593 -2.14853 14.7677C-2.14853 14.076 -1.81166 13.4216 -1.24956 13.018L19.4248 -1.74971C19.793 -2.01226 20.2239 -2.14941 20.6743 -2.14941C21.1248 -2.14941 21.5557 -2.01226 21.9219 -1.74971L52.9315 20.4008C53.4956 20.8045 53.8324 21.4589 53.8324 22.1505C53.8324 22.8422 53.4956 23.4966 52.9335 23.9002L32.2591 38.6679C31.8909 38.9305 31.46 39.0676 31.0096 39.0676V39.0676Z'
					fill='#004399'
				/>
				<path
					d='M31.0096 38.8854C30.5983 38.8854 30.2027 38.76 29.8677 38.519L-1.14182 16.3684C-1.65888 15.9981 -1.96637 15.4005 -1.96637 14.7657C-1.96637 14.1309 -1.65888 13.5333 -1.14378 13.1649L19.5306 -1.60278C19.8674 -1.84378 20.2631 -1.96918 20.6744 -1.96918C21.0856 -1.96918 21.4813 -1.84378 21.8162 -1.60278L52.8257 20.5478C53.3428 20.9181 53.6503 21.5157 53.6503 22.1505C53.6503 22.7853 53.3428 23.3829 52.8277 23.7513L32.1534 38.519C31.8165 38.76 31.4209 38.8854 31.0096 38.8854Z'
					fill='#004297'
				/>
				<path
					d='M31.0096 38.7032C30.6355 38.7032 30.2771 38.5895 29.9735 38.3721L-1.03608 16.2215C-1.50417 15.8865 -1.78424 15.3437 -1.78424 14.7677C-1.78424 14.1916 -1.50417 13.6489 -1.03804 13.3138L19.6363 -1.45384C19.9418 -1.67132 20.3003 -1.78693 20.6743 -1.78693C21.0484 -1.78693 21.4068 -1.67328 21.7104 -1.45579L52.72 20.6947C53.1881 21.0298 53.4681 21.5725 53.4681 22.1486C53.4681 22.7246 53.1881 23.2674 52.7219 23.6024L32.0476 38.3701C31.742 38.5876 31.3836 38.7032 31.0096 38.7032Z'
					fill='#004295'
				/>
				<path
					d='M31.0096 38.5209C30.6747 38.5209 30.3515 38.419 30.0793 38.2231L-0.930302 16.0726C-1.35139 15.7708 -1.60208 15.283 -1.60208 14.7676C-1.60208 14.2523 -1.35139 13.7645 -0.932261 13.4627L19.7421 -1.30496C20.0163 -1.5009 20.3394 -1.60278 20.6744 -1.60278C21.0093 -1.60278 21.3324 -1.5009 21.6047 -1.30496L52.6142 20.8456C53.0353 21.1473 53.286 21.6352 53.286 22.1505C53.286 22.6658 53.0353 23.1537 52.6162 23.4554L31.9418 38.2231C31.6676 38.419 31.3445 38.5209 31.0096 38.5209Z'
					fill='#004193'
				/>
				<path
					d='M31.0096 38.3387C30.7119 38.3387 30.4279 38.2467 30.185 38.0742L-0.824556 15.9237C-1.19864 15.6572 -1.41995 15.2242 -1.41995 14.7657C-1.41995 14.3072 -1.19864 13.8762 -0.826515 13.6097L19.8478 -1.15797C20.0907 -1.33235 20.3766 -1.42249 20.6743 -1.42249C20.972 -1.42249 21.256 -1.33235 21.4989 -1.15797L52.5084 20.9926C52.8825 21.259 53.1038 21.692 53.1038 22.1505C53.1038 22.609 52.8825 23.0401 52.5104 23.3066L31.8361 38.0742C31.5932 38.2486 31.3073 38.3387 31.0096 38.3387Z'
					fill='#004191'
				/>
				<path
					d='M31.0096 38.1565C30.751 38.1565 30.5023 38.0762 30.2908 37.9253L-0.71878 15.7748C-1.0439 15.5416 -1.23779 15.1654 -1.23779 14.7657C-1.23779 14.366 -1.0439 13.9898 -0.71878 13.7566L19.9556 -1.01104C20.1671 -1.16191 20.4158 -1.24225 20.6763 -1.24225C20.9368 -1.24225 21.1836 -1.16191 21.3951 -1.01104L52.4047 21.1395C52.7298 21.3726 52.9237 21.7488 52.9237 22.1486C52.9237 22.5483 52.7298 22.9245 52.4047 23.1576L31.7303 37.9253C31.5188 38.0762 31.2701 38.1565 31.0096 38.1565Z'
					fill='#00408F'
				/>
				<path
					d='M31.0096 37.9743C30.7882 37.9743 30.5767 37.9057 30.3965 37.7784L-0.613034 15.6278C-0.891147 15.4299 -1.05566 15.1086 -1.05566 14.7677C-1.05566 14.4267 -0.891147 14.1054 -0.613034 13.9075L20.0613 -0.860156C20.2415 -0.989473 20.455 -1.05609 20.6763 -1.05609C20.8976 -1.05609 21.1091 -0.987514 21.2893 -0.860156L52.2989 21.2904C52.577 21.4883 52.7415 21.8096 52.7415 22.1505C52.7415 22.4915 52.577 22.8128 52.2989 23.0107L31.6245 37.7784C31.4444 37.9077 31.2309 37.9743 31.0096 37.9743Z'
					fill='#003F8D'
				/>
				<path
					d='M31.0096 37.7921C30.8274 37.7921 30.6512 37.7352 30.5023 37.6294L-0.507258 15.4789C-0.736407 15.3143 -0.873505 15.0478 -0.873505 14.7657C-0.873505 14.4835 -0.736407 14.2171 -0.507258 14.0544L20.1671 -0.713228C20.3159 -0.820992 20.4922 -0.875854 20.6763 -0.875854C20.8604 -0.875854 21.0347 -0.819033 21.1836 -0.713228L52.1931 21.4373C52.4223 21.6019 52.5594 21.8684 52.5594 22.1505C52.5594 22.4327 52.4223 22.6991 52.1931 22.8618L31.5207 37.6294C31.3719 37.7372 31.1956 37.7921 31.0115 37.7921H31.0096Z'
					fill='#003F8C'
				/>
				<path
					d='M31.0096 37.6099C30.8646 37.6099 30.7256 37.5648 30.6081 37.4806L-0.401512 15.33C-0.583656 15.2007 -0.691376 14.9891 -0.691376 14.7657C-0.691376 14.5424 -0.583656 14.3327 -0.401512 14.2014L20.2709 -0.566239C20.3904 -0.650491 20.5294 -0.695557 20.6743 -0.695557C20.8193 -0.695557 20.9583 -0.650491 21.0758 -0.566239L52.0854 21.5843C52.2676 21.7136 52.3753 21.9252 52.3753 22.1486C52.3753 22.372 52.2676 22.5816 52.0854 22.7129L31.413 37.4806C31.2935 37.5648 31.1545 37.6099 31.0096 37.6099Z'
					fill='#003E8A'
				/>
				<path
					d='M31.0096 37.4276C30.9038 37.4276 30.8 37.3943 30.7138 37.3336L-0.295735 15.183C-0.428916 15.087 -0.509216 14.9322 -0.509216 14.7677C-0.509216 14.6031 -0.428916 14.4483 -0.295735 14.3523L20.3767 -0.415405C20.4648 -0.478105 20.5666 -0.511414 20.6724 -0.511414C20.7782 -0.511414 20.882 -0.478105 20.9681 -0.417364L51.9777 21.7332C52.1109 21.8292 52.1912 21.984 52.1912 22.1485C52.1912 22.3131 52.1109 22.4679 51.9777 22.5639L31.3053 37.3316C31.2172 37.3943 31.1153 37.4276 31.0096 37.4276Z'
					fill='#003D88'
				/>
				<path
					d='M31.0096 37.2454C30.941 37.2454 30.8744 37.2239 30.8196 37.1847L-0.18999 15.0341C-0.276165 14.9714 -0.327087 14.8735 -0.327087 14.7677C-0.327087 14.6619 -0.276165 14.5619 -0.18999 14.5012L20.4824 -0.264516C20.5392 -0.305663 20.6058 -0.325256 20.6724 -0.325256C20.739 -0.325256 20.8075 -0.303703 20.8624 -0.264516L51.8719 21.886C51.9581 21.9468 52.009 22.0467 52.009 22.1525C52.009 22.2583 51.9581 22.3582 51.8719 22.419L31.1995 37.1847C31.1427 37.2258 31.0761 37.2454 31.0096 37.2454V37.2454Z'
					fill='#003D86'
				/>
				<path
					d='M31.0096 37.0632C30.9802 37.0632 30.9508 37.0534 30.9254 37.0357L-0.0842132 14.8852C-0.123384 14.8578 -0.144928 14.8127 -0.144928 14.7657C-0.144928 14.7187 -0.121425 14.6736 -0.0842132 14.6462L20.5882 -0.119542C20.6136 -0.137176 20.643 -0.146973 20.6724 -0.146973C20.7018 -0.146973 20.7312 -0.137176 20.7566 -0.119542L51.7662 22.031C51.8053 22.0584 51.8269 22.1035 51.8269 22.1505C51.8269 22.1975 51.8034 22.2426 51.7662 22.27L31.0938 37.0357C31.0683 37.0534 31.0389 37.0632 31.0096 37.0632Z'
					fill='#003C84'
				/>
			</g>
			<path
				d='M20.6743 0L0 14.7657L31.0096 36.9162L51.682 22.1505L20.6743 0V0Z'
				fill='#00CCFF'
				className={css(isHovering ? styleSheet.hovering : undefined)}
			/>
			<path d='M64.4056 7.15594H73.0104V35.8782H88.8965V43.8523H64.4037V7.15594H64.4056Z' fill={textColor} />
			<path
				d='M119.332 16.4069H128.197L133.743 32.9251L139.292 16.4069H147.691L137.686 43.8523H129.442L119.334 16.4069L119.332 16.4069Z'
				fill={textColor}
			/>
			<path d='M150.853 16.4069H159.044V43.8523H150.853V16.4069Z' fill={textColor} />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M194.971 15.8899C187.556 15.8899 181.284 21.6904 181.284 30.1306V30.1326C181.284 38.5728 187.556 44.3732 194.971 44.3732C198.029 44.3732 201.036 43.3373 202.903 41.3692L202.954 43.8543H210.212V16.4089H202.954L202.903 18.8939C201.036 16.9259 198.029 15.8899 194.971 15.8899ZM202.538 30.1306C202.538 34.2743 199.792 36.9141 196.059 36.9141C192.275 36.9141 189.475 34.2724 189.475 30.1306C189.475 25.9888 192.326 23.3471 196.059 23.3471C199.792 23.3471 202.538 25.9869 202.538 30.1306Z'
				fill={textColor}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M246.605 15.7332C238.622 15.7332 232.401 21.5846 232.401 30.2324L232.399 30.2344C232.399 38.6217 238.257 44.526 247.019 44.526C252.463 44.526 256.817 42.2485 259.876 38.2085L254.249 34.2136C252.29 36.4754 249.916 37.5858 247.173 37.5858C243.545 37.5858 240.902 35.6177 240.123 32.3552H259.876C261.017 20.86 254.122 15.7332 246.605 15.7332ZM246.554 22.3621C249.612 22.3621 251.687 23.915 252.308 26.7114H240.229C241.112 24.0716 243.496 22.3621 246.554 22.3621Z'
				fill={textColor}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M105.245 15.7332C97.262 15.7332 91.0413 21.5846 91.0413 30.2324L91.0393 30.2344C91.0393 38.6217 96.8974 44.526 105.659 44.526C111.103 44.526 115.458 42.2485 118.516 38.2085L112.889 34.2136C110.931 36.4754 108.557 37.5858 105.814 37.5858C102.185 37.5858 99.5421 35.6177 98.7638 32.3552H118.516C119.657 20.86 112.762 15.7332 105.245 15.7332ZM105.194 22.3621C108.253 22.3621 110.327 23.915 110.948 26.7114H98.8697C99.7519 24.0716 102.136 22.3621 105.194 22.3621Z'
				fill={textColor}
			/>
			<path
				d='M229.548 23.7603V16.4069H226.231V7.15594L218.04 12.9877V16.4069H214.774V23.7603H218.04L218.003 37.9618C217.995 41.2125 220.63 43.8523 223.884 43.8523H229.548V36.4989H226.198L226.231 23.7603H229.548V23.7603Z'
				fill={textColor}
			/>
			<path
				d='M178.381 23.7603V16.4069H175.063V7.15594L166.872 12.9877V16.4069H163.606V23.7603H166.872L166.835 37.9618C166.827 41.2125 169.462 43.8523 172.717 43.8523H178.381V36.4989H175.03L175.063 23.7603H178.381V23.7603Z'
				fill={textColor}
			/>
			<path
				d='M154.092 3.18042L148.685 6.99655L156.795 12.7217L162.202 8.90558L154.092 3.18042V3.18042Z'
				fill='#00CCFF'
			/>
		</svg>
	);
};
