import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = 'automationTemplate/<status>';

export function useAutomationTemplatesByStatusQuery({
	status,
	onError,
	onSuccess,
}: {
	status: Api.TemplateStatus;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IAutomationTemplate[]) => void;
}) {
	const userSession = useUserSession();

	return useQuery({
		onError,
		onSuccess,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IAutomationTemplate[]>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `automationTemplate`,
					queryParams: { status },
				}),
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY, status],
	});
}
