import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const CONVERT_VIDEO_QUERY_KEY = 'socialPostConvertVideoQuery';

export function useGetSystemJob<TSystemJob extends Api.ISystemJob = Api.ISystemJob>({
	enabled,
	jobId,
	refetchOnWindowFocus = false,
	refetchInterval,
	onError,
	onSuccess,
}: {
	enabled: boolean;
	jobId: string;
	onError?(err: Api.IOperationResultNoValue): void;
	onSuccess?(data: TSystemJob): void;
	refetchInterval(data: TSystemJob): number | false;
	refetchOnWindowFocus: boolean;
}) {
	const userSession = useUserSession();

	return useQuery({
		enabled,
		onError,
		onSuccess,
		queryFn: async () => {
			return userSession.webServiceHelper.callAsync<TSystemJob>(
				Api.ImpersonationBroker.composeApiUrl({ urlPath: `SystemJob/${jobId}` }),
				'GET'
			);
		},
		queryKey: [CONVERT_VIDEO_QUERY_KEY, jobId],
		refetchInterval,
		refetchOnWindowFocus,
	});
}
