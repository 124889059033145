import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export function useExchangeMergePublicTokenForAccountTokenMutation({
	onError,
	onSuccess,
	userSession,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
	userSession: Api.IUserSessionContext;
}) {
	return useMutation({
		mutationFn: (publicToken: Api.IMergePublicToken) => {
			return userSession.webServiceHelper.callAsync<any>(
				Api.ImpersonationBroker.composeApiUrl({ urlPath: 'account/integrations/mergelink/accountToken' }),
				'POST',
				publicToken
			);
		},
		onError,
		onSuccess,
	});
}
