import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

interface IParams {
	templateId: string;
	statuses: Api.ITemplateStatus[];
}
export function useAdminBlogTemplateDeleteStatusesMutation({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (val: Api.IBlogTemplate) => void;
}) {
	const userSession = useUserSession();
	const categoriesType = 'blogCategories';
	return useMutation({
		mutationFn: async ({ templateId, statuses }: IParams) => {
			const value = await userSession.webServiceHelper.callAsync<Api.IBlogTemplate>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `admin/template/industry/${categoriesType}/${templateId}/statuses`,
				}),
				'DELETE',
				statuses
			);
			return value;
		},
		onError,
		onSuccess,
	});
}
