import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useStartAutomationForContactMutation({
	impersonationContext,
	onSuccess,
	onError,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onSuccess?: (data: { automation: Api.IAutomation; template: Api.IAutomationTemplateReference }) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();

	return useMutation({
		onError,
		mutationFn: async ({
			template,
			contact,
			start,
		}: {
			template: Api.IAutomationTemplateReference;
			contact: Api.ContactViewModel;
			start: boolean;
		}) => {
			const automation = await userSession.webServiceHelper.callAsync<Api.IAutomation>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `Automation/${template.id}/Contact/${contact.id}`,
					queryParams: { start },
				}),
				'POST',
				null
			);
			onSuccess({ automation, template });
		},
	});
}
