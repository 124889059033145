import { useQueries } from '@tanstack/react-query';
import { stringify as toQueryString } from 'query-string';
import { IPixabayImageSearchOptions, IPixabayImageSearchResult } from '../../models/Pixabay';
import { DefaultPixabayApiKey, PIXABAY_IMAGE_QUERY_KEY } from './constants';

const getQueryFn =
	({
		pageIndex = 1,
		searchTerm,
		options = { per_page: 25 },
	}: { pageIndex?: number; searchTerm: string; options?: IPixabayImageSearchOptions }) =>
	async () => {
		const pageSize = options?.per_page || 25;
		const query = toQueryString({
			safesearch: true,
			key: DefaultPixabayApiKey,
			q: searchTerm || '',
			type: 'photo',
			image_type: 'photo',
			...(options || {}),
			per_page: pageSize,
			page: pageIndex,
		});
		const result = await fetch(`https://pixabay.com/api/?${query}`, { method: 'GET' });
		const imageSearchResult: IPixabayImageSearchResult = await result.json();
		return imageSearchResult;
	};

export const usePixabayImageQueries = ({
	pageIndex = 1,
	querys,
	options = { per_page: 25 },
}: {
	pageIndex?: number;
	querys: string[];
	options?: IPixabayImageSearchOptions;
}) => {
	const pageSize = options?.per_page || 25;
	return useQueries({
		queries: querys.map(searchTerm => {
			return {
				queryFn: getQueryFn({
					pageIndex,
					searchTerm,
					options: {
						...(options || {}),
						per_page: pageSize,
					},
				}),
				queryKey: [PIXABAY_IMAGE_QUERY_KEY, searchTerm, pageSize, pageIndex, options],
			};
		}),
	});
};
