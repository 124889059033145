import * as Api from '@ViewModels';

/**
 * @returns Executor function that will reject the promise if it takes longer than the specified duration. Error
 *   returned in is of type Api.IOperationResultNoValue, with systemCode 408 and systemMessage 'Request timeout'
 */
export function useApiRequestTimeout() {
	/**
	 * @param promise Promise to wrap
	 * @param duration Time in milliseconds
	 */
	function exec<T = any>(promise: Promise<T>, duration: number) {
		return new Promise<T>((resolve, reject) => {
			let cancelled = false;
			const timeOutHandle = setTimeout(() => {
				cancelled = true;
				reject({
					success: false,
					systemCode: 408,
					systemMessage: 'Request timeout',
				} as Api.IOperationResultNoValue);
			}, duration);

			promise
				?.then(result => {
					if (!cancelled) {
						clearTimeout(timeOutHandle);
						resolve(result);
					}
				})
				.catch(error => {
					if (!cancelled) {
						clearTimeout(timeOutHandle);
						reject(error);
					}
				});
		});
	}
	return exec;
}
