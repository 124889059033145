import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useSyncGoogleBusiness = ({
	onError,
	onSuccess,
	impersonationContext,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (value: Api.IGoogleBusinessMetadata) => void;
	impersonationContext?: Api.IImpersonationContext;
}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IGoogleBusinessMetadata>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: 'googleBusiness/sync',
				}),
				'POST',
				null
			);
		},
		onError,
		onSuccess,
	});
};
