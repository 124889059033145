import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

export const REPORTING_STATS_QUERY_KEY = 'ReportingStatsKey';

export const useAutomationTemplateStats = ({
	enabled = true,
	templateId,
	onError,
}: {
	enabled?: boolean;
	templateId: string;
	onError?: (err: Api.IOperationResultNoValue) => void;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		onError,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IReportCommunicationStats>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `automationTemplate/${templateId}/communicationStats`,
				}),
				'GET'
			);
		},
		queryKey: [REPORTING_STATS_QUERY_KEY, templateId],
	});
};

export const invalidateAutomationStats = () => {
	reactQueryClient.invalidateQueries([REPORTING_STATS_QUERY_KEY]);
};
