import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useEmailCampaignApprovalRequestMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ campaignId, emailContent }: { campaignId: string; emailContent: Api.IRawRichTextContentState }) => {
			return userSession.webServiceHelper.callWebServiceAsync(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `email/campaign/${encodeURIComponent(campaignId)}/approvalrequest`,
				}),
				'POST',
				emailContent
			);
		},
		onError,
		onSuccess,
	});
}
