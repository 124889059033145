import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useArchiveInsurancePolicyMutation = ({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IInsurancePolicy) => void;
} = {}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({
			policyId,
			impersonationContext,
		}: {
			policyId: string;
			impersonationContext?: Api.IImpersonationContext;
		}) => {
			return userSession.webServiceHelper.callAsync<Api.IInsurancePolicy>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `insurancePolicy/${policyId}`,
				}),
				'DELETE'
			);
		},
		onError,
		onSuccess,
	});
};
