import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';

const BASE_QUERY_KEY = 'email/user/workload';

export function useEmailUserWorkloadQuery({ timeZone }: { timeZone?: string } = {}) {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IEmailWorkload>(
				Api.ImpersonationBroker.composeApiUrl({ queryParams: { timeZone }, urlPath: 'email/user/workload' }),
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY, timeZone],
	});
}
