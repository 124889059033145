import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_API_KEY = 'scheduler/config';

export function useSchedulerConfigsQuery({ userId }: { userId: string }) {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () =>
			userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IMeetingConfigResponse>>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: {
						forUserId: userId,
					},
					urlPath: 'scheduler/config',
				}),
				'GET'
			),
		queryKey: [BASE_API_KEY, userId],
	});
}

export const invalidateSchedulerConfigsQuery = ({ userId }: { userId: string }) => {
	reactQueryClient.invalidateQueries([BASE_API_KEY, userId]);
};
