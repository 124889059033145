import { IOperationResultNoValue, UserSessionContext } from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import * as Api from '../../extViewmodels/sdk';
import { reactQueryClient } from '../../web/ReactQueryProvider';

export const EMAILS_TO_SEND_QUERY_KEY = ['emailsToSend'];

export function useEmailsToSend({
	userSession,
	handleError,
}: {
	userSession: UserSessionContext;
	handleError?: (err: IOperationResultNoValue) => void;
}) {
	const resourcesQuery = [
		Api.ResourceSelectorId.AutomationsOnHold,
		Api.ResourceSelectorId.EmailsPendingApprovals,
		Api.ResourceSelectorId.NewClientAutomationsOnHold,
		Api.ResourceSelectorId.NewDonorAutomationsOnHold,
		Api.ResourceSelectorId.NewLeadAutomationsOnHold,
		Api.ResourceSelectorId.Turning65,
		Api.ResourceSelectorId.Turning72,
		Api.ResourceSelectorId.Turning73,
		Api.ResourceSelectorId.TurningXX,
		Api.ResourceSelectorId.HouseAnniversaries,
		Api.ResourceSelectorId.HappyBirthday,
		Api.ResourceSelectorId.PolicyRenew,
		Api.ResourceSelectorId.FinancialReview,
		Api.ResourceSelectorId.CommunicationStats,
		Api.ResourceSelectorId.SatisfactionSurvey,
		Api.ResourceSelectorId.SocialMedia,
		Api.ResourceSelectorId.Custom1,
		Api.ResourceSelectorId.Custom2,
		Api.ResourceSelectorId.Custom3,
		Api.ResourceSelectorId.Custom4,
		Api.ResourceSelectorId.Custom5,
		Api.ResourceSelectorId.Custom6,
		Api.ResourceSelectorId.Custom7,
		Api.ResourceSelectorId.Custom8,
		Api.ResourceSelectorId.Custom9,
		Api.ResourceSelectorId.Custom10,
	].reduce((curr, id) => {
		return `${curr}${curr ? '&' : ''}typeOf=${encodeURIComponent(id)}`;
	}, '');

	return useQuery({
		queryFn: () =>
			new Promise<Api.IDashboardEmails>((resolve, reject) =>
				userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IResourceSelectorDashboardCard[]>(
					`dashboard/ResourceSelector?${resourcesQuery}`,
					'GET',
					null,
					opResult => {
						// @ts-ignore
						const dashboardEmails = resourceSelectorDashboardCardsToDashboardEmails(opResult.value);
						resolve(dashboardEmails);
					},
					opResult => {
						handleError?.(opResult);
						reject(opResult);
					}
				)
			),
		queryKey: EMAILS_TO_SEND_QUERY_KEY,
	});
}

export function invalidateDashboardResourceSelector() {
	reactQueryClient.invalidateQueries(EMAILS_TO_SEND_QUERY_KEY);
}

function resourceSelectorDashboardCardsToDashboardEmails(dashCards: Api.IResourceSelectorDashboardCard[]) {
	const defaultModel: Api.IDashboardEmails = {
		automationsOnHold: [],
		campaignsOnHold: [],
		communicationStats: null,
		custom1: null,
		custom2: null,
		custom3: null,
		custom4: null,
		custom5: null,
		custom6: null,
		custom7: null,
		custom8: null,
		custom9: null,
		custom10: null,
		newClientAutomationsOnHold: [],
		newLeadAutomationsOnHold: [],
		upcoming65thBirthdays: [],
		upcoming72ndBirthdays: [],
		upcoming73rdBirthdays: [],
		upcomingAnniversaries: null,
		upcomingBirthdays: null,
		upcomingRenewals: [],
		upcomingReviews: [],
		upcomingTurningsXX: [],
	};
	return (dashCards || []).reduce<Api.IDashboardEmails>((result, card) => {
		switch (card.type) {
			case Api.ResourceSelectorId.HappyBirthday: {
				result.upcomingBirthdays = card;
				break;
			}
			case Api.ResourceSelectorId.AutomationsOnHold: {
				result.automationsOnHold = (card as Api.IOnHoldAutomationsDashboardCard).values;
				break;
			}
			case Api.ResourceSelectorId.EmailsPendingApprovals: {
				result.campaignsOnHold = (card as Api.IOnHoldCampaignsDashboardCard).values;
				break;
			}
			case Api.ResourceSelectorId.NewClientAutomationsOnHold: {
				result.newClientAutomationsOnHold = (card as Api.IOnHoldAutomationsDashboardCard).values;
				break;
			}
			case Api.ResourceSelectorId.NewDonorAutomationsOnHold: {
				result.newDonorAutomationsOnHold = (card as Api.IOnHoldAutomationsDashboardCard).values;
				break;
			}
			case Api.ResourceSelectorId.NewLeadAutomationsOnHold: {
				result.newLeadAutomationsOnHold = (card as Api.IOnHoldAutomationsDashboardCard).values;
				break;
			}
			case Api.ResourceSelectorId.Turning65: {
				// @ts-ignore
				result.upcoming65thBirthdays.push(card);
				break;
			}
			case Api.ResourceSelectorId.Turning72: {
				// @ts-ignore
				result.upcoming72ndBirthdays.push(card);
				break;
			}
			case Api.ResourceSelectorId.Turning73: {
				// @ts-ignore
				result.upcoming73rdBirthdays.push(card);
				break;
			}
			case Api.ResourceSelectorId.HouseAnniversaries: {
				result.upcomingAnniversaries = card;
				break;
			}
			case Api.ResourceSelectorId.PolicyRenew: {
				// @ts-ignore
				result.upcomingRenewals.push(card);
				break;
			}
			case Api.ResourceSelectorId.FinancialReview: {
				// @ts-ignore
				result.upcomingReviews.push(card);
				break;
			}
			case Api.ResourceSelectorId.CommunicationStats: {
				result.communicationStats = card as Api.ICommunicationStats;
				break;
			}
			case Api.ResourceSelectorId.SatisfactionSurvey: {
				result.satisfactionSurveyStats = card as Api.ISatisfactionSurveyCardData;
				break;
			}
			case Api.ResourceSelectorId.SocialMedia: {
				result.socialMedia = card as Api.ISocialMediaCard;
				break;
			}

			case Api.ResourceSelectorId.Custom1: {
				result.custom1 = card;
				break;
			}
			case Api.ResourceSelectorId.Custom2: {
				result.custom2 = card;
				break;
			}
			case Api.ResourceSelectorId.Custom3: {
				result.custom3 = card;
				break;
			}
			case Api.ResourceSelectorId.Custom4: {
				result.custom4 = card;
				break;
			}
			case Api.ResourceSelectorId.Custom5: {
				result.custom5 = card;
				break;
			}
			case Api.ResourceSelectorId.Custom6: {
				result.custom6 = card;
				break;
			}
			case Api.ResourceSelectorId.Custom7: {
				result.custom7 = card;
				break;
			}
			case Api.ResourceSelectorId.Custom8: {
				result.custom8 = card;
				break;
			}
			case Api.ResourceSelectorId.Custom9: {
				result.custom9 = card;
				break;
			}
			case Api.ResourceSelectorId.Custom10: {
				result.custom10 = card;
				break;
			}
			case Api.ResourceSelectorId.TurningXX: {
				// @ts-ignore
				result.upcomingTurningsXX.push(card);
				break;
			}
			default: {
				break;
			}
		}
		return result;
	}, defaultModel);
}
