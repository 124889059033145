import { destructive } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const TrashIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`trash-icon ${className || ''}`} height={17} width={16}>
			<g fill={fillColor || destructive} fillRule='evenodd'>
				<path d='M3.55555556,6.22222222 L12.4444444,6.22222222 L12.4453333,15.1111111 L3.55555556,15.1111111 L3.55555556,6.22222222 Z M1.77777778,15.1111111 C1.77777778,16.0915556 2.57511111,16.8888889 3.55555556,16.8888889 L12.4444444,16.8888889 C13.4248889,16.8888889 14.2222222,16.0915556 14.2222222,15.1111111 L14.2222222,4.44444444 L1.77777778,4.44444444 L1.77777778,15.1111111 Z' />
				<polygon points='10.667 1.778 10.667 0 5.333 0 5.333 1.778 0 1.778 0 3.556 16 3.556 16 1.778' />
				<polygon points='5.333 13.333 7.111 13.333 7.111 8 5.333 8' />
				<polygon points='8.889 13.333 10.667 13.333 10.667 8 8.889 8' />
			</g>
		</SvgIcon>
	);
};
