import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const ACCOUNT_INTEGRATION_QUERY_KEY = 'account-integration';

export function useAccountIntegrationQuery<T extends Api.IConfigurableIntegration = Api.IConfigurableIntegration>({
	impersonationContext,
	type,
	onError,
}: {
	impersonationContext?: Api.IImpersonationContext;
	type: Api.ConfigurableIntegrationType;
	onError?: (error: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();
	return useQuery({
		onError,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<T>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `account/integrations/${type}`,
				}),
				'GET'
			);
		},
		queryKey: [ACCOUNT_INTEGRATION_QUERY_KEY, type, impersonationContext],
	});
}

export const invalidateAccountIntegrationQuery = ({
	impersonationContext,
	type,
}: {
	impersonationContext: Api.IImpersonationContext;
	type: Api.ConfigurableIntegrationType;
}) => reactQueryClient.invalidateQueries([ACCOUNT_INTEGRATION_QUERY_KEY, type, impersonationContext]);
