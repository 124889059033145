import { ImpersonationBroker } from '../../extViewmodels';
import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export function useBackorderAreacodes({
	impersonationContext,
	onSuccess,
	onError,
}: {
	impersonationContext: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ areacodes, scope }: { areacodes: string[]; scope: string }) => {
			return userSession.webServiceHelper.callAsync(
				ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `PhoneNumber/requestBackorder/${encodeURIComponent(scope)}`,
				}),
				'POST',
				areacodes
			);
		},
		onError,
		onSuccess,
	});
}
