import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { grayIconFill } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

interface IProps {
	className?: string;
	fillColor?: string;
	filled?: boolean;
	gradient?: boolean;
	height?: number;
	onMouseEnter?(e: React.MouseEvent<SVGSVGElement>): void;
	onMouseLeave?(e: React.MouseEvent<SVGSVGElement>): void;
	strokeColor?: string;
	styles?: StyleDeclarationValue[];
	width?: number;
}

export const StarIcon: React.FC<IProps> = props => {
	const {
		className,
		fillColor,
		gradient = false,
		strokeColor,
		styles = [],
		filled = false,
		onMouseEnter,
		onMouseLeave,
		width = 23,
		height = 24,
	} = props;
	return (
		<SvgIcon
			className={`star-icon ${className || ''} ${css(...(styles || []))}`}
			height={height}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			width={width}
			viewBox='0 0 23 24'
		>
			<defs>
				<linearGradient x1='75%' y1='25%' x2='25%' y2='75%' id='gradientColor'>
					<stop offset={0} stopColor='#32C5FF' />
					<stop offset={100} stopColor='#B620E0' />
				</linearGradient>
			</defs>
			<g
				fill={gradient ? 'url(#gradientColor)' : fillColor || grayIconFill}
				fillRule='evenodd'
				transform='translate(0 .5)'
			>
				{filled && (
					<polygon points='11.962 1.767 14.881 7.603 21.711 8.405 16.836 13.328 17.904 20.372 11.962 17.389 5.473 21.2 5.473 17.389 7.048 14.642 1.382 8.405 8.08 7.603' />
				)}

				<path
					fill={gradient ? 'url(#gradientColor)' : strokeColor || fillColor || grayIconFill}
					d='M11.5,16.0841921 C11.7219458,16.0841921 11.9450416,16.1487375 12.1382379,16.2778283 L16.4345566,19.1489455 L14.9947839,14.0959637 C14.8786361,13.6937076 14.9913339,13.2603314 15.2868783,12.9641142 L19.0737567,9.1686148 L14.9499347,9.1686148 C14.514093,9.1686148 14.1162005,8.92195921 13.9207042,8.53122909 L11.5,3.6776464 L9.07929581,8.53122909 C8.88379951,8.92195921 8.48590704,9.1686148 8.05006529,9.1686148 L3.92624334,9.1686148 L7.71312167,12.9641142 C8.00866608,13.2603314 8.12136394,13.6937076 8.00521614,14.0959637 L6.56544339,19.1489455 L10.8617621,16.2778283 C11.0549584,16.1487375 11.2780542,16.0841921 11.5,16.0841921 L11.5,16.0841921 Z M18.3998694,22.9997695 C18.1779236,22.9997695 17.9559778,22.9352241 17.7616315,22.8061333 L11.5,18.622209 L5.23836851,22.8061333 C4.83817608,23.0735356 4.31263603,23.0643149 3.9227934,22.778471 C3.53295078,22.4937797 3.36160403,21.9947056 3.4950015,21.5302093 L5.6086615,14.1144052 L0.337161265,8.83090411 C0.00826748974,8.50241419 -0.0906306385,8.00564522 0.087615988,7.57572682 C0.265862615,7.14465584 0.685604671,6.86342236 1.15019588,6.86342236 L7.33937874,6.86342236 L10.4707695,0.585230732 C10.8606121,-0.195076911 12.1393879,-0.195076911 12.5292305,0.585230732 L15.6606213,6.86342236 L21.8498041,6.86342236 C22.3143953,6.86342236 22.7341374,7.14465584 22.912384,7.57572682 C23.0906306,8.00564522 22.9917325,8.50241419 22.6628387,8.83090411 L17.3913385,14.1144052 L19.5049985,21.5302093 C19.638396,21.9947056 19.4670492,22.4937797 19.0772066,22.778471 C18.8759604,22.9260033 18.6379149,22.9997695 18.3998694,22.9997695 L18.3998694,22.9997695 Z'
				/>
			</g>
		</SvgIcon>
	);
};
