import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useDeleteBoardMutation = ({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.ISystemJob) => void;
} = {}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: (id: string) => {
			return userSession.webServiceHelper.callAsync<Api.ISystemJob>(`board/${id}`, 'DELETE', null);
		},
		onError,
		onSuccess,
	});
};
