import { Industry } from '../../../extViewmodels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export function useDeleteHwcCategoryMutation({
	onError,
	onSuccess,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (value: Api.ITemplateCategory) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		// @ts-ignore
		mutationFn: ({ categoryName, industry }: { categoryName: string; industry: Industry }) => {
			return userSession.webServiceHelper.callAsync(
				`admin/template/${encodeURIComponent(industry)}/cardCategories/${encodeURIComponent(categoryName)}`,
				'DELETE'
			);
		},
		onError,
		onSuccess,
	});
}
