import { useApiRequestTimeout } from '../../models/hooks/useApiRequestTimeout';
import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export function useSocialContentGeneration({
	onError,
	onSuccess,
	timeout = 59000,
	userSession,
}: {
	onError?(err: Api.IOperationResultNoValue): void;
	onSuccess?(
		postContent: Api.IAIGeneratedContent<Api.IRawRichTextContentState>,
		request: Api.IAIContentGenerationRequest
	): void;
	/** Timeout in milliseconds. Default = 59000 */
	timeout?: number;
	userSession: Api.UserSessionContext;
}) {
	const executeWithTimeout = useApiRequestTimeout();
	return useMutation({
		// @ts-ignore
		mutationFn: (request: Api.IAIContentGenerationRequest) => {
			return executeWithTimeout(
				userSession.webServiceHelper.callAsync<Api.IAIGeneratedContent<Api.IRawRichTextContentState>>(
					'social/post/generate',
					'POST',
					request
				),
				timeout
			);
		},
		onError,
		onSuccess,
	});
}
