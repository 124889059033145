import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { IBlogTemplateCard } from '../../viewmodels/AppViewModels';

const QUERY_BASE_KEY = 'template/industry/category/blogs';

export const useBlogCardsCategoryTemplates = ({
	enabled = true,
	industry,
	impersonationContext,
	category,
	retry,
}: {
	enabled?: boolean;
	industry: Api.Industry;
	impersonationContext?: Api.IImpersonationContext;
	category: string;
	retry?: (failureCount: number, error: Api.IOperationResultNoValue) => boolean;
}) => {
	const userSession = useUserSession();
	return useQuery({
		retry,
		enabled,
		queryFn: () =>
			userSession.webServiceHelper.callAsync<IBlogTemplateCard[]>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `template/${encodeURIComponent(industry)}/${encodeURIComponent(category)}/blogs`,
					impersonationContext,
				}),
				'GET'
			),
		queryKey: [
			QUERY_BASE_KEY,
			{
				category,
				industry,
			},
			impersonationContext,
		],
	});
};
