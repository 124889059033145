import { useMutation } from '@tanstack/react-query';
import * as Api from '@ViewModels';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useTelephonyConfigBrandOTPMutation(
	queryParams: {
		onSuccess?: () => void;
		onError?: (error: Api.IOperationResultNoValue) => void;
	} = {}
) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: (params: { impersonationContext?: Api.IImpersonationContext }) => {
			return userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `telephony/config/brand/otp`,
					impersonationContext: params.impersonationContext,
				}),
				'POST'
			);
		},
		onSuccess: queryParams.onSuccess,
		onError: queryParams.onError,
	});
}
