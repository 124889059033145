import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = 'contact/classifiedPolicies';

export function useClassifiedContactPolicies({
	contactsRequest,
	enabled,
}: {
	contactsRequest: Api.IResourceSelectorContactsRequest;
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IClassifiedPolicies>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: 'contact/classifiedPolicies',
				}),
				'POST',
				contactsRequest
			);
		},
		queryKey: [BASE_QUERY_KEY, contactsRequest],
		refetchOnWindowFocus: false,
	});
}
