import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const PUBLIC_AUTH_KEYS_QUERY_KEY = 'publicAuthKey';

export function useGetPublicAuthKey({
	impersonationContext,
	onError,
	onSuccess,
	enabled = true,
	keyId,
	refetchOnWindowFocus = true,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (key: string) => void;
	enabled?: boolean;
	keyId?: string;
	refetchOnWindowFocus?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		onError,
		onSuccess,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<string>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `public/auth/key/${keyId}`,
				}),
				'GET'
			);
		},
		enabled,
		refetchOnWindowFocus,
		queryKey: [PUBLIC_AUTH_KEYS_QUERY_KEY, impersonationContext, keyId],
	});
}
