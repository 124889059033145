import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export const useHandwrittenCardAccountPreferencesMutation = ({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: any) => void;
}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({
			handwritingStyle,
			returnContact,
		}: {
			handwritingStyle: string;
			returnContact: Api.IHandwrittenCardReturnContact;
		}) => {
			return userSession.webServiceHelper.callAsync<any>(`account/preferences/handwrittenCardPreferences`, 'PUT', {
				handwritingStyle,
				returnContact,
			});
		},
		onError,
		onSuccess,
	});
};
