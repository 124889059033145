import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'history/byContact';

export function useHistoryByContactInfiniteQuery({
	pageSize = 25,
	contactId,
	sort = 'asc',
	enabled = true,
}: {
	pageSize?: number;
	contactId: string;
	sort?: 'asc' | 'desc';
	onError?: (err: Api.IOperationResultNoValue) => void;
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	// @ts-ignore
	return useInfiniteQuery<Api.IPagedCollection<Api.IContactHistoryItem>>({
		enabled,
		getNextPageParam: lastPage => lastPage.pageToken,
		queryFn: async ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IContactHistoryItem>>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { pageSize, pageToken: pageParam, sort },
					urlPath: `history/byContact/${encodeURIComponent(contactId)}`,
				}),
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY, contactId, pageSize, sort],
	});
}

export const invalidateHistoryByContact = ({ contactId }: { contactId: string }) => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY, contactId]);
};
