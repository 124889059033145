import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { IBlogTemplateCard } from '../../viewmodels/AppViewModels';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const QUERY_BASE_KEY = 'template/industry/browse/blogs';

export const useBlogCardsAllCategoryTemplates = ({
	enabled = true,
	industry,
	impersonationContext,
}: {
	enabled?: boolean;
	industry: Api.Industry;
	impersonationContext?: Api.IImpersonationContext;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () =>
			userSession.webServiceHelper.callAsync<Record<string, IBlogTemplateCard[]>>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `template/${encodeURIComponent(industry)}/browse/blogs`,
					impersonationContext,
				}),
				'GET'
			),
		queryKey: [
			QUERY_BASE_KEY,
			{
				industry,
			},
			impersonationContext,
		],
	});
};

export const invalidateBlogCardsAllCategoryTemplates = () => reactQueryClient.invalidateQueries([QUERY_BASE_KEY]);
