import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const QUERY_KEY = 'note/filter';

export type NotesSearchSortByType = 'dueDate' | 'creationDate' | 'lastModifiedDate';

export function useInfiniteNotesSearch({
	currentUserOnly,
	pageSize = 25,
	query,
	filterRequest,
	sort = 'asc',
	sortBy = 'lastModifiedDate',
	onError,
	enabled = true,
}: {
	currentUserOnly: boolean;
	pageSize?: number;
	query: string;
	sort?: 'asc' | 'desc';
	sortBy?: NotesSearchSortByType;
	filterRequest: Api.IRichContentRequest;
	onError?: (err: Api.IOperationResultNoValue) => void;
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	return useInfiniteQuery<Api.IPagedCollection<Api.INote>>({
		// @ts-ignore
		enabled,
		getNextPageParam: lastPage => lastPage.pageToken,
		onError,
		queryFn: async ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.INote>>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: {
						currentUserOnly,
						pageSize,
						pageToken: pageParam,
						sort,
						sortBy,
					},
					urlPath: 'note/filter',
				}),
				'POST',
				filterRequest
			);
		},
		queryKey: [QUERY_KEY, filterRequest, currentUserOnly, pageSize, query, sort, sortBy],
	});
}

export const invalidateNotesSearch = () => {
	reactQueryClient.invalidateQueries([QUERY_KEY]);
};
