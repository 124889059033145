import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_KEY = '/user/userPreferences/handwrittenCardPreferences';

export function useUserHwcPreferences({ forUserId, enabled = true }: { forUserId: string; enabled?: boolean }) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: async () => {
			const pref = await userSession.webServiceHelper.callAsync<Api.IHandwrittenCardPreferences>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: {
						forUserId,
					},
					urlPath: `user/userPreferences/handwrittenCardPreferences`,
				}),
				'GET'
			);
			return pref ?? null;
		},
		queryKey: [BASE_KEY, forUserId],
	});
}
