import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useAutomationTemplateIdTextingCampaignMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: (data: Api.IAutomationTemplate) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ id, request }: { id: string; request: Api.CreateAutomationsRequest }) => {
			return userSession.webServiceHelper.callAsync<Api.IAutomationTemplate>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `automationTemplate/${encodeURIComponent(id)}/textingCampaign`,
				}),
				'POST',
				request
			);
		},
		onSuccess,
		onError,
	});
}
