import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const SOCIAL_CAMPAIGN_CATEGORIES_QUERY_KEY = 'socialCampaignCategories';

export function useSocialCategories(params: {
	industry: Api.Industry;
	enabled?: boolean;
	impersonationContext?: Api.IImpersonationContext;
	onSuccess?: (categories: string[]) => void;
	select?: (categories: string[]) => string[];
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled: params.enabled,
		onSuccess: params.onSuccess,
		queryFn: async () => {
			return userSession.webServiceHelper.callAsync<string[]>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `template/${encodeURIComponent(params.industry)}/socialCategories`,
					impersonationContext: params.impersonationContext,
				}),
				'GET'
			);
		},
		queryKey: [SOCIAL_CAMPAIGN_CATEGORIES_QUERY_KEY, params.industry, params.impersonationContext],
		select: params.select,
		refetchOnWindowFocus: false,
	});
}
