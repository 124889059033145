import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useCreateSocialPostMutation({
	onSuccess,
	onError,
	onSettled,
}: {
	onSuccess?: (data: Api.ISocialMediaPost) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSettled?: () => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn({
			impersonationContext,
			contentCalendarSuggestionId,
			forUserId,
			request,
		}: {
			request: Api.ICreateSocialMediaPostRequest;
			forUserId?: string;
			impersonationContext?: Api.IImpersonationContext;
			contentCalendarSuggestionId?: string;
		}) {
			return userSession.webServiceHelper.callAsync<Api.ISocialMediaPost>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `social/post`,
					queryParams: {
						contentCalendarSuggestionId,
						forUserId,
					},
					impersonationContext,
				}),
				'POST',
				request
			);
		},
		onError,
		onSuccess,
		onSettled,
	});
}
