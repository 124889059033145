import { useQuery } from '@tanstack/react-query';
import * as Api from '@ViewModels';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'CustomPageStyle/requiredClasses/{type}';

export const useGetAllowedCustomPageStyleClasses = ({
	type,
	impersonationContext,
	enabled,
	refetchOnWindowFocus,
}: {
	type: Api.CustomPageType;
	impersonationContext?: Api.IImpersonationContext;
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		refetchOnWindowFocus,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<string[]>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `CustomPageStyle/requiredClasses/${encodeURIComponent(type)}`,
					impersonationContext,
				}),
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY, type, impersonationContext],
	});
};

export const invalidateAllRequiredCustomPageStyleClassesQueries = () => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
};

export const invalidateRequiredCustomPageStyleClassesQueries = (type: Api.CustomPageType) => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY, type]);
};
