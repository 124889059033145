import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useUploadVideoAttachmentMutation() {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: async ({ request, forUserId }: { request: Api.IPresignedUrlRequest; forUserId: string }) => {
			return userSession.webServiceHelper.callAsync<Api.IMediaConvertPresignedResult>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { forUserId },
					urlPath: `attachment/videoUploadUrl`,
				}),
				'POST',
				request
			);
		},
	});
}
