import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import * as AdminModels from '../../../models/AdminModels';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export function useBulkEmailCampaignHistoricalStatsQuery({
	campaignId,
	userId,
	enabled,
}: {
	campaignId: string;
	userId: string;
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () =>
			userSession.webServiceHelper.callAsync<AdminModels.IBulkEmailHistoricalReport>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `admin/bulkEmailHistorical/stats/${AdminModels.AggregationIdType.Campaign}/${encodeURIComponent(
						campaignId
					)}`,
					queryParams: {
						userId,
					},
				}),
				'GET'
			),
		queryKey: [`admin/bulkEmailHistorical/stats/2/<id>`, campaignId, userId],
	});
}
