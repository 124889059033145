import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { IKeywordImageRequest } from '../../models/Pixabay';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useGenerateKeywords({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({
			content,
			prevKeywords,
			impersonationContext,
		}: { content: string; prevKeywords?: string[]; impersonationContext?: Api.IImpersonationContext }) => {
			const request: IKeywordImageRequest = {
				content,
				previousKeywords: prevKeywords?.join(','),
			};
			const result = await userSession.webServiceHelper.callAsync<
				Api.IAIGeneratedContent<{ generatedKeywords: string[] }>
			>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `social/post/generateKeywords`,
					impersonationContext,
				}),
				'POST',
				request
			);
			// @ts-ignore
			return result.content.generatedKeywords;
		},
		onError,
		onSuccess,
	});
}
