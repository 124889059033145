import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useEventRegistrationResponseTokenMutation = ({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data?: string) => void;
}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({ id }: { id: string }) => {
			return userSession.webServiceHelper.callAsync<string>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `survey/response/${id}/surveyLink`,
				}),
				'GET'
			);
		},
		onError,
		onSuccess,
	});
};
