import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useUploadAccountLogoMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (updatedAccount: Api.IAccount) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({
			logo,
			impersonationContext,
		}: { logo: File; impersonationContext?: Api.IImpersonationContext }) => {
			const formData = new FormData();
			formData.append('file', logo);
			const result = await userSession.webServiceHelper.callAsync<Api.IAccount>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: 'account/preferences/uploadLogo',
					impersonationContext,
				}),
				'PUT',
				formData
			);
			return result;
		},
		onError,
		onSuccess,
	});
}
