import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const ARCHIVE_OPPORTUNITIES_QUERY_KEY = 'archiveOpportunitiesKey';

export function useArchivedOpportunities({
	boardId,
	enabled = true,
	pageSize = 25,
	onError,
}: {
	boardId: string;
	enabled?: boolean;
	pageSize?: number;
	onError?: (err: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();
	return useInfiniteQuery<Api.IPagedCollection<Api.IOpportunity>>({
		// @ts-ignore
		enabled,
		getNextPageParam: lastPage => lastPage.pageToken,
		onError,
		queryFn: async ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IOpportunity>>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { pageSize, pageToken: pageParam },
					urlPath: `board/${boardId}/items/archived`,
				}),
				'GET'
			);
		},
		queryKey: [ARCHIVE_OPPORTUNITIES_QUERY_KEY, pageSize, boardId],
	});
}

export const invalidateArchivedOpportunities = () => {
	reactQueryClient.invalidateQueries([ARCHIVE_OPPORTUNITIES_QUERY_KEY]);
};
