import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'getIntegrationStatus';

export function useIntegrationStatus({
	onError,
	onSuccess,
	provider,
	refetchOnWindowFocus = true,
	userSession,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IIntegrationStatus) => void;
	provider: string;
	refetchOnWindowFocus?: boolean;
	userSession: Api.IUserSessionContext;
}) {
	return useQuery({
		onError,
		onSuccess,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IIntegrationStatus>(
				`user/integrations/${encodeURIComponent(provider)}/status`,
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY, provider],
		refetchOnWindowFocus,
	});
}

export const invalidateIntegrationStatus = (provider: string) => {
	return reactQueryClient.invalidateQueries([BASE_QUERY_KEY, provider]);
};
