import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useDisableIntegrationMutation({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: (type: Api.ConfigurableIntegrationType | Api.IntegrationSources) => {
			return userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `user/integrations/${encodeURIComponent(type)}`,
				}),
				'DELETE'
			);
		},
		onError,
		onSuccess,
	});
}
