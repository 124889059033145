import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const CONSUMER = Api.PublicAPIConsumer.Zapier; // Only supported consumer currently

export function usePostPublicAuthKeyMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: (newApiKey: string) => void;
	onError?: (err: Api.IOperationResultNoValue) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ description }: { description: string }) =>
			userSession.webServiceHelper.callAsync<any>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: 'public/auth/key',
				}),
				'POST',
				{
					description,
					consumer: CONSUMER,
				}
			),
		onError,
		onSuccess,
	});
}
