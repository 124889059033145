import { useMutation } from '@tanstack/react-query';
import { useApiClientContext } from '../../surveys/context';
import { IOperationResultNoValue, ISatisfactionSurveyResponse } from '../../surveys/models/Api';

export const useClientCreateSatisfactionResponseMutation = ({
	onError,
	onSuccess,
}: {
	onError?: (error: IOperationResultNoValue) => void;
	onSuccess?: (data?: ISatisfactionSurveyResponse) => void;
}) => {
	const { apiClient } = useApiClientContext();

	return useMutation({
		mutationFn: ({ response, accessToken }: { response: ISatisfactionSurveyResponse; accessToken: string }) => {
			return apiClient.post<ISatisfactionSurveyResponse>(`survey/satisfaction/response?token=${accessToken}`, response);
		},
		onError,
		onSuccess,
	});
};
