import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useInitiateSentMessageImportMutation({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext: Api.IImpersonationContext;
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: () =>
			userSession.webServiceHelper.callWebServiceAsync(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `user/initiateSentMessageImport`,
				}),
				'POST'
			),
		onError,
		onSuccess,
	});
}
