import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = 'reports/contact/byEntity';

export function useReportsContactByEntityQuery({ endDate, entityId, startDate }: Api.IEntityReportByIdRequest) {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () =>
			userSession.webServiceHelper.callAsync<Api.IContactAggregateActivity>(`reports/contact/byEntity`, 'POST', {
				endDate,
				entityId,
				startDate,
			}),
		queryKey: [BASE_QUERY_KEY, entityId, endDate, startDate],
	});
}
