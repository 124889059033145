import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useCancelAutomationMutation({
	impersonationContext,
	onSuccess,
	onError,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onSuccess?: (data: Api.IOperationResultNoValue) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();

	return useMutation({
		onSuccess,
		onError,
		mutationFn: ({ automationId }: { automationId: string }) => {
			return userSession.webServiceHelper.callAsync<Api.IOperationResultNoValue>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `Automation/${automationId}`,
				}),
				'DELETE'
			);
		},
	});
}
