import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

interface Params {
	templateId: string;
	stepType: Api.AutomationStepType;
	stepId: string;
	impersonationContext?: Api.IImpersonationContext;
}

export function useAutomationTemplateStepDeleteMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: (data: Api.IAutomationTemplate, variables: Params) => void;
	onError?: (error: Api.IOperationResultNoValue, variables: Params) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ templateId, stepType, stepId, impersonationContext }: Params) => {
			return userSession.webServiceHelper.callAsync<Api.IAutomationTemplate>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `AutomationTemplate/${encodeURIComponent(templateId)}/Step/${encodeURIComponent(
						stepType
					)}/${encodeURIComponent(stepId)}`,
					impersonationContext,
				}),
				'DELETE',
				null
			);
		},
		onError,
		onSuccess,
	});
}
