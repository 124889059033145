import { translateKeyframes } from '../CompactLogoIcon2023/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	hovering: {
		animationDuration: '2000ms',
		animationFillMode: 'alternate',
		animationIterationCount: 'infinite',
		animationName: [translateKeyframes],
		animationTimingFunction: 'ease-in-out',
	},
});
