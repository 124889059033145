import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { ITemplateCard, ImpersonationBroker } from '../../viewmodels/AppViewModels';
import { reactQueryClient } from '../../web/ReactQueryProvider';

export const SOCIAL_CATEGORY_TEMPLATES_QUERY_KEY = 'template/<industry>/<category>/social';

export const useTemplateCategorySocialQuery = ({
	industry,
	categoryName,
	enabled = true,
	excludeExpired = true,
	sortBy = 'lastModifiedDate',
	onError,
	retry,
	impersonationContext,
	pageSize = 25,
}: {
	industry: Api.Industry;
	categoryName: string;
	excludeExpired?: boolean;
	sortBy?: 'lastModifiedDate' | 'name';
	enabled?: boolean;
	onError?: (err: Api.IOperationResultNoValue) => void;
	retry?: (failureCount: number, error: Api.IOperationResultNoValue) => boolean;
	impersonationContext?: Api.IImpersonationContext;
	pageSize?: number;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		onError,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<ITemplateCard[]>(
				ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: {
						excludeExpired,
						sortBy,
						pageSize,
					},
					urlPath: `template/${encodeURIComponent(industry)}/${encodeURIComponent(categoryName)}/social`,
				}),
				'GET'
			);
		},
		queryKey: [
			SOCIAL_CATEGORY_TEMPLATES_QUERY_KEY,
			industry,
			categoryName,
			excludeExpired,
			sortBy,
			impersonationContext,
		],
		retry,
	});
};

export const invalidateAllTemplateCategorySocialQueries = () => {
	reactQueryClient.invalidateQueries([SOCIAL_CATEGORY_TEMPLATES_QUERY_KEY]);
};
