import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = ['contact/filter/containsDuplicates'];

export const useContactFilterContainsDuplicatesQuery = ({
	filterRequest,
}: {
	filterRequest: Api.IBulkContactsRequest;
}) => {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<boolean>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: 'contact/filter/containsDuplicates',
				}),
				'POST',
				filterRequest
			);
		},
		queryKey: [BASE_QUERY_KEY, filterRequest],
	});
};
