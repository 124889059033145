import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { ImpersonationBroker } from '../../viewmodels/AppViewModels';
import { reactQueryClient } from '../../web/ReactQueryProvider';

export const MY_TEMPLATES_QUERY_KEY = '/templates/<industry>/me';

export function useMyTemplates<T extends object>({
	impersonationContext,
	enabled = true,
	excludeExpired = true,
	industry,
	onError,
	sort = 'desc',
	sortBy = 'lastModifiedDate',
	templateType = Api.TemplateType.Email,
}: {
	impersonationContext?: Api.IImpersonationContext;
	enabled?: boolean;
	excludeExpired?: boolean;
	industry: Api.Industry;
	onError?: (err: Api.IOperationResultNoValue) => void;
	sort?: 'asc' | 'desc';
	sortBy?: string;
	templateType?: Api.TemplateType;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		onError,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<T[]>(
				ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: {
						excludeExpired,
						sort,
						sortBy,
						templateType,
					},
					urlPath: `template/${encodeURIComponent(industry)}/me`,
				}),
				'GET'
			);
		},
		queryKey: [MY_TEMPLATES_QUERY_KEY, templateType, excludeExpired, industry, sort, sortBy],
	});
}

export const invalidateMyTemplates = ({ templateType }: { templateType: Api.TemplateType }) => {
	reactQueryClient.invalidateQueries([MY_TEMPLATES_QUERY_KEY, templateType]);
};
