import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export const ADMIN_ACCOUNT_QUERY_KEY = 'admin/account/<accountId>';

export function useAdminAccountQuery({
	accountId,
	onError,
	onSuccess,
}: {
	accountId: string;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (account: Api.IAccount) => void;
}) {
	const userSession = useUserSession();
	return useQuery({
		onError,
		onSuccess,
		queryFn: () =>
			userSession.webServiceHelper.callAsync<Api.IAccount>(`admin/account/${encodeURIComponent(accountId)}`, 'GET'),
		queryKey: [ADMIN_ACCOUNT_QUERY_KEY, accountId],
	});
}
