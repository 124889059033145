import { useUserSession } from '../../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

interface IMutationVariables {
	industry: Api.Industry;
	names: string[];
}

export function useHwcCategoryOrderMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (value: Api.ITemplateCategory[], variables: IMutationVariables) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		// @ts-ignore
		mutationFn: ({ industry, names }: IMutationVariables) => {
			return userSession.webServiceHelper.callAsync<Api.ITemplateCategory[]>(
				`admin/template/${encodeURIComponent(industry)}/cardCategories/order`,
				'PUT',
				names
			);
		},
		onError,
		onSuccess,
	});
}
