import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'reports/campaign/upcomingautomationemails';

export function useInfiniteUpcomingAutomationEmailsQuery({
	pageSize = 25,
	userId,
	impersonationContext,
	enabled,
	refetchOnWindowFocus = true,
}: {
	pageSize?: number;
	userId?: string;
	impersonationContext?: Api.IImpersonationContext;
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
}) {
	const userSession = useUserSession();
	// @ts-ignore
	return useInfiniteQuery<Api.IPagedCollection<Api.IAutomationReport>>({
		enabled,
		getNextPageParam: lastPage => lastPage.pageToken,
		queryFn: async ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IAutomationReport>>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: { pageSize, pageToken: pageParam, userId },
					urlPath: `reports/campaign/upcomingautomationemails`,
				}),
				'GET'
			);
		},
		refetchOnWindowFocus,
		queryKey: [BASE_QUERY_KEY, pageSize, userId, impersonationContext],
	});
}

export const invalidateUpcomingAutomationEmailsQuery = ({
	userId,
	pageSize = 25,
	impersonationContext,
}: {
	userId?: string;
	pageSize?: number;
	impersonationContext?: Api.IImpersonationContext;
}) => {
	return reactQueryClient.invalidateQueries([BASE_QUERY_KEY, pageSize, userId, impersonationContext]);
};
