import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { reactQueryClient } from '../../web/ReactQueryProvider';

export const DAILY_UPDATES_QUERY_KEY = ['dailyUpdates'];

export function useDailyUpdates({
	userSession,
	handleError,
}: {
	userSession: Api.UserSessionContext;
	handleError?: (err: Api.IOperationResultNoValue) => void;
}) {
	return useQuery({
		onError: handleError,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IDashboardUpdates>('dashboard/dailyUpdates', 'GET');
		},
		queryKey: DAILY_UPDATES_QUERY_KEY,
	});
}

export const invalidateDailyUpdates = () => reactQueryClient.invalidateQueries(DAILY_UPDATES_QUERY_KEY);
