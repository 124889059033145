import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useExportPostsMutation = ({
	onError,
	onSuccess,
	impersonationContext,
}: {
	onSuccess?: (data: Api.ISystemJob) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
	impersonationContext?: Api.IImpersonationContext;
} = {}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: () => {
			return userSession.webServiceHelper.callAsync<Api.ISystemJob>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: 'social/post/exportAll',
					impersonationContext,
				}),
				'POST',
				{}
			);
		},
		onError,
		onSuccess,
	});
};
