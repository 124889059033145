import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useStartAutomationContactMutation({
	impersonationContext,
	onSuccess,
	onError,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onSuccess?: (data: {
		createAutomationRequest: Api.ICreateAutomationRequest;
		template: Api.IAutomationTemplateReference;
	}) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();

	return useMutation({
		onSuccess,
		onError,
		mutationFn: async ({
			template,
			createAutomationRequest,
		}: {
			template: Api.IAutomationTemplateReference;
			createAutomationRequest: Api.ICreateAutomationRequest;
		}) => {
			await userSession.webServiceHelper.callAsync<Api.IOperationResultNoValue>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `Automation/${template.id}/Contact`,
				}),
				'POST',
				createAutomationRequest
			);
			return { createAutomationRequest, template };
		},
	});
}
