import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useContactKeyDateCreateMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (result: Api.IContact) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({
			contactId,
			keyFactMetaData,
			ignoreYear = false,
		}: {
			contactId: string;
			keyFactMetaData: Api.IKeyFactMetaData;
			ignoreYear?: boolean;
		}) => {
			return userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `contact/${encodeURIComponent(contactId)}/keyDate`,
					queryParams: {
						ignoreYear,
					},
				}),
				'POST',
				keyFactMetaData
			);
		},
		onError,
		onSuccess,
	});
}
