import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_API_KEY = '/contact/resourceSelector/card/count';

export const useContactResourceSelectorCardCountQuery = ({
	filterRequest,
	resourceSelectorId,
	expand,
}: {
	filterRequest: Api.IResourceSelectorContactsByOwnerRequest;
	resourceSelectorId: Api.ResourceSelectorId;
	expand?: string;
}) => {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () =>
			userSession.webServiceHelper.callAsync<number>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: {
						expand,
					},
					urlPath: `contact/resourceSelector/${resourceSelectorId}/card/count`,
				}),
				'POST',
				filterRequest
			),
		queryKey: [BASE_API_KEY, resourceSelectorId, filterRequest].concat(expand ? expand : []),
	});
};
