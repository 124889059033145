import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const usePutCustomFieldsMutation = ({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IFormResponse) => void;
} = {}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({ fields }: { fields: Api.IFormField<string>[] }) => {
			return userSession.webServiceHelper.callAsync<Api.IFormResponse>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: 'account/contactForm',
				}),
				'PUT',
				{
					fields,
				}
			);
		},
		onError,
		onSuccess,
	});
};
