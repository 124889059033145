import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_API_KEY = 'board/:id/advancedReportingUrl';

export function useBoardAdvancedReportingUrlQuery({
	boardId,
	cacheTime,
	enabled = true,
}: {
	boardId: string;
	cacheTime?: number;
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		cacheTime,
		enabled,
		queryFn: () =>
			userSession.webServiceHelper.callAsync<string>(
				`board/${encodeURIComponent(boardId)}/advancedReportingUrl`,
				'GET'
			),
		queryKey: [BASE_API_KEY, boardId],
	});
}
