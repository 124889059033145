import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';

const BASE_QUERY_KEY = 'createMergeLinkToken';

export function useCreateMergeLinkToken({
	userSession,
	integrationSource,
	integrationName,
	refetchOnWindowFocus = true,
}: {
	userSession: Api.IUserSessionContext;
	integrationSource: Api.IntegrationSources;
	integrationName: string;
	refetchOnWindowFocus?: boolean;
}) {
	return useQuery({
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<string>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: {
						integration: integrationName,
						integrationProvider: integrationSource,
					},
					urlPath: 'account/integrations/mergelink/linkToken',
				}),
				'POST'
			);
		},
		queryKey: [BASE_QUERY_KEY, integrationSource, integrationName],
		refetchOnWindowFocus,
	});
}
