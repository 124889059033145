import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export const useCreateContactCustomPropertyMutation = ({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({ contactId, fieldName, value }: { contactId: string; fieldName: string; value: string }) => {
			return userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `contact/${contactId}/customProperty/add`,
				}),
				'POST',
				{
					key: fieldName,
					value,
				}
			);
		},
		onError,
		onSuccess,
	});
};
