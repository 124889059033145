import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'reports/blogs/campaign/filter';

type BaseParams = {
	endDate?: string | Date;
	impersonationContext?: Api.IImpersonationContext;
	pageSize?: number;
	status?: Api.BlogStatus[];
	sendFromUserId?: string;
	startDate?: string | Date;
};

export function getBlogReportsAsync({
	endDate,
	impersonationContext,
	pageSize = 25,
	pageToken,
	sendFromUserId,
	startDate,
	status,
	userSession,
}: BaseParams & {
	pageToken?: string;
	userSession: Api.UserSessionContext;
}) {
	return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IBlogReportView>>(
		Api.ImpersonationBroker.composeApiUrl({
			impersonationContext,
			queryParams: { pageSize, pageToken },
			urlPath: `reports/blog`,
		}),
		'POST',
		{
			endDate,
			startDate,
			status,
			userId: sendFromUserId,
		}
	);
}

export function useInfiniteReportsBlogsQuery({
	enabled = true,
	endDate,
	impersonationContext,
	pageSize = 25,
	sendFromUserId,
	startDate,
	status,
}: BaseParams & {
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	return useInfiniteQuery({
		enabled,
		queryFn: ({ pageParam }) => {
			return getBlogReportsAsync({
				endDate,
				impersonationContext,
				pageSize,
				pageToken: pageParam,
				sendFromUserId,
				startDate,
				status,
				userSession,
			});
		},
		queryKey: [BASE_QUERY_KEY, startDate, endDate, sendFromUserId, pageSize, impersonationContext],
		getNextPageParam: lastPage => lastPage.pageToken,
	});
}

export function invalidateInfiniteReportsBlogsQuery() {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
}
