import { useUserSession } from '../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';

const BASE_QUERY_KEY = 'donation/campaign/autocomplete';

export function useCampaignAutocomplete({
	enabled,
	fragment,
	pageSize,
	impersonationContext,
}: {
	enabled?: boolean;
	fragment: string;
	pageSize: number;
	impersonationContext?: Api.IImpersonationContext;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IDonationCampaign>>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: {
						enforcePageSize: true,
						fragment,
						pageSize,
					},
					urlPath: BASE_QUERY_KEY,
				}),
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY, fragment, pageSize],
	});
}
