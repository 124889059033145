import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useInsurancePolicyContactsMutation = ({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IProjectedContact[]) => void;
} = {}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({
			filterRequest,
			impersonationContext,
		}: {
			filterRequest: Api.IInsurancePolicyFilterRequest;
			impersonationContext?: Api.IImpersonationContext;
		}) => {
			return userSession.webServiceHelper.callAsync<Api.IProjectedContact[]>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `insurancePolicy/contacts`,
				}),
				'POST',
				filterRequest
			);
		},
		onError,
		onSuccess,
	});
};
