import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'giftingBalance';

export function useGifting() {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IGiftingBalance>(
				Api.ImpersonationBroker.composeApiUrl({ urlPath: 'gifting' }),
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY],
	});
}

export const invalidateGiftingBalance = () => {
	return reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
};
