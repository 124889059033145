import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = 'email/preview';

export function useEmailPreviewQuery({
	content,
	enabled = true,
	signatureTemplateId,
}: {
	content: Api.IRawRichTextContentState;
	enabled?: boolean;
	signatureTemplateId?: string;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<string>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: 'email/preview',
				}),
				'POST',
				{
					content,
					signatureTemplateId,
				}
			);
		},
		queryKey: [BASE_QUERY_KEY, content, signatureTemplateId],
	});
}
