import * as AdminModels from '../../../models/AdminModels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';

export function useHwcCreateTemplateMutation({
	onError,
	onSuccess,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (value: AdminModels.ISystemEmailTemplateWithStatuses) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		// @ts-ignore
		mutationFn: ({ payload }: { payload: AdminModels.ISystemEmailTemplateWithStatuses }) => {
			return userSession.webServiceHelper.callAsync<AdminModels.ISystemEmailTemplateWithStatuses>(
				`admin/template/industry/cardCategories/templates`,
				'POST',
				payload
			);
		},
		onError,
		onSuccess,
	});
}
