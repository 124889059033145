import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../../web/ReactQueryProvider';

const scope = 'hwcIndustryCategories';

const cardCategoryKeys = {
	all: [{ scope }] as const,
	list: (industry: Api.Industry) => [{ industry, scope }] as const,
};

export function useHwcIndustryCategories({ industry }: { industry: Api.Industry }) {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.ITemplateCategory[]>(
				`admin/template/${encodeURIComponent(industry)}/cardCategories`,
				'GET'
			);
		},
		queryKey: cardCategoryKeys.list(industry),
	});
}

export function invalidateHwcIndustryCategories({ industry }: { industry: Api.Industry }) {
	return reactQueryClient.invalidateQueries(cardCategoryKeys.list(industry));
}

export function invalidateAllHwcIndustryCategories() {
	return reactQueryClient.invalidateQueries({
		predicate: query => (query.queryKey as typeof cardCategoryKeys.all)[0]?.scope === scope,
	});
}

export function setHwcIndustryCategoriesCache({
	data,
	industry,
}: {
	data: Api.ITemplateCategory[];
	industry: Api.Industry;
}) {
	return reactQueryClient.setQueryData(cardCategoryKeys.list(industry), () => data);
}
