import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = 'PhoneNumber/user';

export function usePhoneNumberUserQuery() {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () => userSession.webServiceHelper.callAsync<Api.ITelephonyConfiguration[]>('PhoneNumber/User', 'GET'),
		queryKey: [BASE_QUERY_KEY],
	});
}
