import { useQuery } from '@tanstack/react-query';
import { IBlogTemplate, ITemplateStatus } from '../../../extViewmodels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'industry/blogCategories/templates/{id}';

export function useAdminBlogTemplateQuery({ enabled, id }: { enabled: boolean; id: string }) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<{ template: IBlogTemplate; statuses: ITemplateStatus[] }>(
				`admin/template/industry/blogCategories/templates/${id}`,
				'GET'
			);
		},
		queryKey: [BASE_QUERY_KEY, id],
	});
}

export const invalidateBlogTemplateQuery = (id: string) => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY, id]);
};
