import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

interface IBody {
	comment: string;
	sessionId?: string;
}
interface IParams extends IBody {
	reviewId: string;
}

export const usePostGoogleBusinessReply = ({
	onError,
	onSuccess,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({ comment, sessionId, reviewId }: IParams) => {
			const body: IBody = {
				comment,
			};
			if (sessionId) {
				body.sessionId = sessionId;
			}

			return userSession.webServiceHelper.callAsync(
				`googleBusiness/reply/${encodeURIComponent(reviewId)}`,
				'PUT',
				body
			);
		},
		onError,
		onSuccess,
	});
};
