import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { ITemplateCard, ImpersonationBroker } from '../../viewmodels/AppViewModels';

export const SOCIAL_SEARCH_TEMPLATES_QUERY_KEY = 'socialSearchTemplates';

export const useSocialSearchTemplates = ({
	impersonationContext,
	industry,
	query,
	excludeExpired = true,
	sortBy = 'lastModifiedDate',
	enabled = true,
	onError,
}: {
	impersonationContext?: Api.IImpersonationContext;
	query: string;
	industry: Api.Industry;
	enabled?: boolean;
	excludeExpired?: boolean;
	sortBy?: string;
	onError?: (err: Api.IOperationResultNoValue) => void;
}) => {
	const userSession = useUserSession();
	const search = query.trim();
	return useQuery({
		enabled,
		onError,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<ITemplateCard[]>(
				ImpersonationBroker.composeApiUrl({
					queryParams: {
						excludeExpired,
						search,
						sortBy,
					},
					urlPath: `template/${encodeURIComponent(industry)}/filter/social`,
					impersonationContext,
				}),
				'GET'
			);
		},
		queryKey: [SOCIAL_SEARCH_TEMPLATES_QUERY_KEY, industry, excludeExpired, search, sortBy, impersonationContext],
	});
};
