import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = 'telephony/configuration/clientProfile/exchanges';

export function useTelephonyConfigExchanges() {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () =>
			userSession.webServiceHelper.callAsync<{ value: string; description: string }[]>(
				'telephony/configuration/clientProfile/exchanges',
				'GET'
			),
		queryKey: [BASE_QUERY_KEY],
	});
}
