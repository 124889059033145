import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = '/user/integrations/<provider>/status';

export const useIntegrationStatusQuery = ({
	provider,
	enabled,
}: {
	provider: Api.ConfigurableIntegrationType;
	enabled?: boolean;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () => {
			const data = userSession.webServiceHelper.callAsync(
				`user/integrations/${encodeURIComponent(provider)}/status`,
				'GET'
			);
			return data as Api.IIntegrationStatus;
		},
		queryKey: [BASE_QUERY_KEY, provider],
	});
};

export const invalidateIntegrationStatusQuery = (provider: Api.ConfigurableIntegrationType) => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY, provider]);
};
