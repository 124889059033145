import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

interface IParams {
	template: Api.ITemplate;
	forUserId?: string;
	impersonationContext?: Api.IImpersonationContext;
}

export function useTemplateCreateMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: (data: Api.ITemplate, variables: IParams) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ template, forUserId, impersonationContext }: IParams) =>
			userSession.webServiceHelper.callAsync<Api.ITemplate>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `template`,
					queryParams: {
						forUserId,
					},
					impersonationContext,
				}),
				`POST`,
				template
			),
		onError,
		onSuccess,
	});
}
