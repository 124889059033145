import { useMutation } from '@tanstack/react-query';
import * as Api from '@ViewModels';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useBlogContentCalApproveMutation = (
	params: {
		onSuccess?: () => void;
		onError?: (error: Api.IOperationResultNoValue) => void;
	} = {}
) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: (
			p: {
				impersonationContext?: Api.IImpersonationContext;
			} = {}
		) => {
			return userSession.webServiceHelper.callAsync<void>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `blog/contentCalendar/approve`,
					queryParams: { includeAllAdminOptions: true },
					impersonationContext: p.impersonationContext,
				}),
				'POST'
			);
		},
		onError: params.onError,
		onSuccess: params.onSuccess,
	});
};
