import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

interface IParams {
	accountActionId: string;
	status: Api.AccountActionStatus;
	impersonationContext?: Api.IImpersonationContext;
}

export function useUpdateActionStatusMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (action: Api.IAccountAction, variables: IParams) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ accountActionId, status, impersonationContext }: IParams) => {
			const result = await userSession.webServiceHelper.callAsync<Api.IAccountAction>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `account/action/${encodeURIComponent(accountActionId)}/status`,
					impersonationContext,
				}),
				'PUT',
				status
			);
			return result as Api.IAccountAction;
		},
		onError,
		onSuccess,
	});
}
