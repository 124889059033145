import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useTemplateDeleteMutation({
	onSuccess,
	onError,
}: { onSuccess?: () => void; onError?: (error: Api.IOperationResultNoValue) => void } = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: (params: { id: string }) =>
			userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({ urlPath: `template/${encodeURIComponent(params.id)}` }),
				`DELETE`
			),
		onError,
		onSuccess,
	});
}
