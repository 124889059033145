import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { ITelephonyConfiguration } from '../../viewmodels/AppViewModels';

export const RESOLVE_PHONE_NUMBER_QUERY_KEY = 'resolvePhoneNumberQueryKey';

export const useResolvePhoneNumber = ({
	enabled = true,
	onError,
	phoneNumberId,
}: {
	enabled?: boolean;
	onError?: (err: Api.IOperationResultNoValue) => void;
	phoneNumberId?: string;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		onError,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<ITelephonyConfiguration>(`phoneNumber/${phoneNumberId}`, 'GET');
		},
		queryKey: [RESOLVE_PHONE_NUMBER_QUERY_KEY, phoneNumberId],
	});
};
