import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import * as AdminModels from '../../../../models/AdminModels';
import { useUserSession } from '../../../../models/hooks/appStateHooks';

export function useAdminBlogTemplateAddStatusesMutation({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (value: AdminModels.ISystemEmailTemplateWithStatuses) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ templateId, statuses }: { templateId: string; statuses: Api.ITemplateStatus[] }) => {
			const value = await userSession.webServiceHelper.callAsync<AdminModels.ISystemEmailTemplateWithStatuses>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `admin/template/industry/blogCategories/${templateId}/statuses`,
				}),
				'POST',
				statuses
			);
			return value;
		},
		onError,
		onSuccess,
	});
}
