import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const GET_SURVEY_BY_ID_QUERY_KEY = 'satisfactionSurveyResponsesFilter';

export function useGetSurveyById<T extends Api.ISurvey = Api.ISurvey>({
	impersonationContext,
	enabled = true,
	refetchOnWindowFocus = false,
	surveyId,
}: {
	impersonationContext?: Api.IImpersonationContext;
	enabled?: boolean;
	surveyId: string;
	refetchOnWindowFocus?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery<T>({
		// @ts-ignore
		enabled,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<T>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `survey/${surveyId}`,
				}),
				'GET'
			);
		},
		queryKey: [GET_SURVEY_BY_ID_QUERY_KEY, surveyId],
		refetchOnWindowFocus,
	});
}

export const invalidateSurveyById = (surveyId: string) => {
	reactQueryClient.invalidateQueries([GET_SURVEY_BY_ID_QUERY_KEY, surveyId]);
};
