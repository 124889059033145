import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { ContactFilterApproximation } from '../../extViewmodels/sdk/models/Contact';
import { ContactFilterRequest } from '../../extViewmodels/sdk/models/requests/ContactFilterRequest';
import { useUserSession } from '../../models/hooks/appStateHooks';
const BASE_API_KEY = '/contact/approximate';

export const useContactApproximateQuery = ({
	filterRequest,
	capabilities,
	enabled,
}: {
	filterRequest: ContactFilterRequest;
	capabilities: Api.ContactCapability[];
	enabled?: boolean;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: async () => {
			const data = await userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: 'contact/approximate',
				}),
				'POST',
				{
					totalCountFilter: filterRequest,
					capabilities,
				}
			);
			return data as ContactFilterApproximation;
		},
		queryKey: [BASE_API_KEY, filterRequest, capabilities],
	});
};
