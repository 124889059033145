import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const ACCOUNT_USER_COUNT_QUERY_KEY = 'account-user-count-key';

export function useAccountUserCount({ accountId, onError }: { accountId: string; onError: () => void }) {
	const userSession = useUserSession();
	return useQuery({
		onError,
		queryFn: () =>
			new Promise<number>((resolve, reject) =>
				userSession.webServiceHelper.callWebServiceWithOperationResults<number>(
					`account/${accountId}/userCount`,
					'GET',
					null,
					opResult => {
						// @ts-ignore
						resolve(opResult.value);
					},
					opResult => {
						onError();
						reject(opResult);
					}
				)
			),
		queryKey: [ACCOUNT_USER_COUNT_QUERY_KEY, accountId],
	});
}

export const invalidateAccountUserCount = (accountId: string) => {
	reactQueryClient.invalidateQueries([ACCOUNT_USER_COUNT_QUERY_KEY, accountId]);
};
